import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getInPlayerFolders } from "../../actions/videoInput";
import {  Table } from "semantic-ui-react";
import  LoadingIndicator from "../LoadingIndicator";
import dateParser from "../../dateParser";
import { selectPlayerFolders } from "../../actions/selectors";
import { inThePlayerFolder } from "../../types/serverInfo";


export default function CurrentlyOnServer() {
  const members = useAppSelector(selectPlayerFolders);
  const [sortBy, set_sortBy] = useState("name");
  const [direction, set_direction] = useState<"ascending" | "descending">("descending");
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getInPlayerFolders());
  }, [dispatch]);

  const changeSortDirection = (type: "name" | "week"| "active"| "date" | "filename") => {
    if (type === sortBy) {
      set_direction(direction === "ascending" ? "descending" : "ascending");
    } else {
      set_sortBy(type);
    }
  };
  const sort = (a:inThePlayerFolder, b:inThePlayerFolder) => {
    if(sortBy === "week"){
      if (parseInt(a.week) < parseInt(b.week)) {
        return direction === "descending" ? -1 : 1;
      }
      if (parseInt(a.week) > parseInt(b.week)) {
        return direction === "descending" ? 1 : -1;
      }
      return 0
    }
    if(sortBy === "name"){
      if(a?.member?.company < b?.member?.company) { 
        return (direction === 'descending' ? -1 : 1); 
      }
      if(a?.member?.company>  b?.member?.company) { 
        return (direction === 'descending' ? 1 : -1); 
      }
      return 0;
    }
    if(sortBy === "active"){
      if(a?.member?.subscription < b?.member?.subscription) { 
        return (direction === 'descending' ? -1 : 1); 
      }
      if(a?.member?.subscription>  b?.member?.subscription) { 
        return (direction === 'descending' ? 1 : -1); 
      }
      return 0;
    }
    if(sortBy === "date"){
      if(a?.date < b?.date) { 
        return (direction === 'descending' ? -1 : 1); 
      }
      if(a?.date>  b?.date) { 
        return (direction === 'descending' ? 1 : -1); 
      }
      return 0;
    }
    if(sortBy === "filename"){
      if(a?.filename < b?.filename) { 
        return (direction === 'descending' ? -1 : 1); 
      }
      if(a?.filename>  b?.filename) { 
        return (direction === 'descending' ? 1 : -1); 
      }
      return 0;
    }
    
    return 0;
  };

  
  if(!members) return <LoadingIndicator loadingText="Files laden"/>
  const sorted = [...members]?.sort(sort);
  return (
    <div>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={"name" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("name")}
              style={{cursor: 'pointer'}}
            >
               Company
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={"filename" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("filename")}
              style={{cursor: 'pointer'}}
            >
              Filename</Table.HeaderCell>
            <Table.HeaderCell sorted={"week" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("week")} 
              style={{cursor: 'pointer'}} >Week</Table.HeaderCell>
            <Table.HeaderCell
             sorted={"date" === sortBy ? direction : undefined}
             onClick={() => changeSortDirection("date")}
             style={{cursor: 'pointer'}}
            >
              Geplaatst op</Table.HeaderCell>
            <Table.HeaderCell
              sorted={"active" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("active")}
              style={{cursor: 'pointer'}}
            >Klant actief</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sorted.map((file, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell error={file?.customerid === 0}> {file?.customerid !== 0 ? `${file?.member?.company} ${file?.member?.basenetshop ? `(${file?.member?.basenetshop?.Woonplaats_vestigingsadres})` : "" }` : null}</Table.Cell>
                <Table.Cell>{file?.filename.replace("/player", "")}</Table.Cell>
                <Table.Cell>{file?.week}</Table.Cell>
                <Table.Cell>{dateParser(file?.date)}</Table.Cell>
                <Table.Cell error={file?.member?.subscription !== "yes"} >{file?.member?.subscription}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
