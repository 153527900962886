import React, { useState } from "react";
import CustomerTypesList from "../../components/admin/CustomerTypesList";
import { LibaryMediaType } from "../../types/constructionMedia";
import DropzoneWrapperAdmin from "../../components/admin/DropzoneWrapperAdmin";

export default function Upload() {
  const [type, set_type] = useState<LibaryMediaType>("vlees");
  const dummyFn = () => console.log("Dummy")  //TODO
  // const [lock , setLock] = useState(false)
  // const [warning , setWarning] = useState(false) 
  // const [wait , setWait] = useState(false)  
  return (
    <div>
      AdminPanel -- {type}
      <CustomerTypesList type={type} set_type={set_type} />
      <br />
      <br />
      <hr />
      <DropzoneWrapperAdmin setLock={dummyFn} setWarning={dummyFn} setWait={dummyFn} type={type}/>
    </div>
  );
}
