import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import Overlay from '../components/Overlay'
import { SelectAWeek } from "../actions/videoInput";
import InputNavigationFeed from "../components/InputNavigationFeed";
import { Fragment } from "react";
import { selectVideoInput } from "../actions/selectors";

export default function InputSummary(){
    const [showOverlay, set_showOverlay] = useState(false)
    const videoInput = useAppSelector(selectVideoInput)
    const dispatch = useAppDispatch()

    const markVideoInfoAsComplete = () =>{
      //@ts-ignore
      dispatch(SelectAWeek(videoInput.weekNumber,videoInput.year ,null, null,  null, null, null , null, null, 2)) 
      set_showOverlay(true)
    }
     let stepNumber = "stap 3 van 3"
    
    return (
      <div className="productFocus">
      <div className="steps">
        <div>
          <h3 className="stepName">Input versturen</h3>
          <p className="stepExplanation">
          Communiceer hier uw sterke punten. Waarom dient de klant nou juist bij
      
           u te komen? Een winkel waar het prettig winkelen is, ga je als klant
           graag naar toe.{" "}
          </p>
        </div>
        <div className="stepNumber">
         <h3 className="stepNumber">{stepNumber}</h3>
          <h4>voor week {videoInput?.weekNumber || 0}</h4>
        </div>
      </div>

        <div className="inputSummary">
          <p className="inputSummaryStep">Stap 1</p>
          <p className="inputSummaryLabel">Focus Product</p>
          <p className="summaryInput">{videoInput?.focusProduct}</p>
          <p className="inputSummaryStep">Stap 2</p>
          <p className="inputSummaryLabel">Seizoen en streek</p>
          <p className="summaryInput">{videoInput?.season}</p>
          <p className="inputSummaryStep">Stap 3</p>
          <p className="inputSummaryLabel">Humor en Sfeer</p>
          <p className="summaryInput">{videoInput?.ambiance}</p>
          <p className="inputSummaryStep">Stap 4</p>
          <p className="inputSummaryLabel">Extra Info</p>
          <p className="summaryInput">{videoInput?.extraInfo}</p>
          <p className="inputSummaryStep">Stap 5</p>
          <p className="inputSummaryLabel">Geüploade bestanden</p>
          <p className="summaryInput">
            {videoInput?.uploadedFiles && videoInput?.uploadedFiles?.length >= 1 ? 
              <ul className="summaryInputList">
                  {videoInput?.uploadedFiles.map(file => <li> {file.originalName} </li>)}
                  </ul>
                  : "Niets geupload"
              }
          </p>
          {videoInput?.facebook ? <Fragment> <p>Stap 6 Facebook</p><p className="summaryInput">{videoInput.facebook}</p></Fragment> : null}
        </div>
        <InputNavigationFeed
          back="/upload"
          to="/send"
          setter={markVideoInfoAsComplete}
          done={showOverlay}
          prevStep={3}
        />

        
        <Overlay show={showOverlay} to="/input/next" /> 
      </div>
    );
  }

