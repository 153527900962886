import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {
  countBannerVideo,
  countReboot,
  countShopVideo,
  countWebVideo,
  dayResult,
  countSummary,
  countMonthShopVideo,
} from "../../actions/selectors";
import { getDaySummary } from "../../actions/videoInput";

export default function DayResult() {
  const dispatch = useAppDispatch();
  const data = useAppSelector(dayResult);
  const shopVideoCount = useAppSelector(countShopVideo);
  const webVideoCount = useAppSelector(countWebVideo);
  const rebootCount = useAppSelector(countReboot);
  const bannerVideoCount = useAppSelector(countBannerVideo);
  const monthShopVideoCount = useAppSelector(countMonthShopVideo);
  const summaryCount = useAppSelector(countSummary);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [today] = useState(new Date());

  useEffect(() => {
    dispatch(
      getDaySummary(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        selectedDate.getDate()
      )
    );
  }, [dispatch, selectedDate]);


  const changeDateHandler = (event:ChangeEvent) => {
    //@ts-ignore
    if (!event.target.value) return;
    //@ts-ignore
    const split = event.target.value.split("-");
    dispatch(getDaySummary(split[0], split[1], split[2]));
    let month = parseInt(split[1]);
    let correctedMonth = month -1
    let day = parseInt(split[2]);
    
    setSelectedDate(new Date(split[0], correctedMonth, day));
  };
  
  const dateManipulator = ()  => {
    const year = selectedDate.getFullYear()
    const month = selectedDate.getMonth()
    const day =  selectedDate.getDate()
    let correctedMonth = month + 1
    let correctedMonthParsed = `correctedMonthParsed`
    if (correctedMonth < 10) {
        correctedMonthParsed = `0${correctedMonth}`;
    }
    let correctedDay = day;
    let correctedDayParsed = `${day}`;
    if (correctedDay < 10) {
      correctedDayParsed = `0${day}`;
    }

    return `${year}-${ correctedMonthParsed }-${correctedDayParsed}`
  }

  return (
    <div>
      <h2>Day result:</h2> <br />
      <input
        type="date"
        value={dateManipulator()}
        min="2020-09-15"
        max={`${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`}
        onChange={changeDateHandler}
      ></input>
      <h3>Verwacht wat er zou moeten gebeuren:</h3>
      <p style={{ margin: "0 0 0 0" }}>
        Aantallen gelden voor de huidge klantpopulatie, op het moment van
        uitvoeren kon deze anders zijn!
      </p>
      <p style={{ margin: "0 0 0 0" }}>
        Winkel video: <strong>{shopVideoCount}</strong>
      </p>
      <p style={{ margin: "0 0 0 0" }}>
        Website video: <strong>{webVideoCount}</strong>
      </p>
      <p style={{ margin: "0 0 0 0" }}>
        Reboot(dvplayer): <strong>{rebootCount}</strong>
      </p>
      <p style={{ margin: "0 0 0 0" }}>
        Banner video: <strong>{bannerVideoCount}</strong>
      </p>
      <p style={{ margin: "0 0 0 0" }}>
        Maand shop video: <strong>{monthShopVideoCount}</strong>
      </p>
      <br />
      <h3>Gedaan:</h3>
      {summaryCount
        ? summaryCount.map((item, i) => {
            return (
              <p key={i} style={{ margin: "0 0 0 0" }}>
                {item.action}: <strong>{item.count}</strong> {item.result}
              </p>
            );
          })
        : null}
      <br />
      {data?.map((result) => {
        return (
          <li key={result.id}>
            {` ${result.member ? result.member.company : "klant onbekent"}  ${
              result.result
            } ${result.action} ${result.memberId} `}
          </li>
        );
      })}
    </div>
  );
}
