import React, { KeyboardEvent, useState } from 'react'

interface InputCbOnEnterProps{
    value: any
    cbProperty: string
    lock: boolean
    cb: Function
}

export default function InputCbOnEnter(props:InputCbOnEnterProps) {
    const [state, set_state] = useState(props.value[props.cbProperty])
    
    const keyHandler = (e:KeyboardEvent) => {
        if(e.key === "Enter"){
            props.cb({...props.value, [props.cbProperty]: state})
        }
    }
    return <input value={state} 
                  onChange={e => set_state(e.target.value)}
                  onKeyDown={ e => keyHandler(e)} 
                  readOnly={props.lock}
                  />
    
}



  