
import React from 'react'
import { getLivedaysWeb,getGeneralLiveDayWeb, addIgnoreDayWeb, addGeneralLiveDayWeb } from "../../actions/videoInput";
import LivedaysScheduler from "../../components/admin/LivedaysScheduler";

export default function LivedaysWeb() {
  return (
    <LivedaysScheduler 
                  getLivedays={getLivedaysWeb}
                  getGeneralLiveDay={getGeneralLiveDayWeb}
                  addIgnoreDay={addIgnoreDayWeb}
                  addGeneralLiveDay={addGeneralLiveDayWeb} 
                  scheduleType={"WebPlayerCopyDays"}
                  ignoreType={"WebPlayerCopyDayIgnores"}
                  extraType={"WebPlayerCopyDayExtras"}
                   />
  )
}
