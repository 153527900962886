import React from "react";
import { useNavigate } from "react-router-dom"
import { Table } from "semantic-ui-react";
import { fullBasenetshop} from "../../types/member";

interface PlayerBasnetAdressProps{
  basenetshop: fullBasenetshop | undefined
}

export default function PlayerBasnetAdress(props?:PlayerBasnetAdressProps) {
  const navigate = useNavigate()
  if (!props?.basenetshop) return null;

  const redirect = () => {
    navigate(`/basenetshop/${props?.basenetshop?.Relatie_code}`)
  }

  const vestigingsadres = () => {
    return(
    <React.Fragment>
      <h2 style={{ marginBottom: 0}}>{`${props?.basenetshop?.Bedrijfsnaam}`}</h2>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Straatnaam_vestigingsadres} ${props?.basenetshop?.Huisnummer_vestigingsadres} ${props?.basenetshop?.Toevoeging_vestigingsadres}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Postcode_vestigingsadres} ${props?.basenetshop?.Woonplaats_vestigingsadres}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Land_vestigingsadres === "NL" ? "Nederland" : props?.basenetshop?.Land_vestigingsadres} `}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Telefoonnummer_vestiging}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Mobiel}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Homepage}`}</p>
    </React.Fragment>)
  }
  const postadres = () => {
    return(
      <React.Fragment>
      <h2 style={{ marginBottom: 0}}>{`${props?.basenetshop?.Bedrijfsnaam}`}</h2>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Straatnaam_postadres} ${props?.basenetshop?.Huisnummer_postadres} ${props?.basenetshop?.Toevoeging_postadres}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Postcode_postadres} ${props?.basenetshop?.Woonplaats_postadres}`}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Land_postadres === "NL" ? "Nederland" : props?.basenetshop?.Land_postadres} `}</p>
      <p style={{ marginBottom: 0}}>{`${props?.basenetshop?.Telefoonnummer_bedrijf}`}</p>
    </React.Fragment>)
  }

  return (
    <div>
      <Table celled>
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Vestigingsadres</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Postadres</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Cell style={{ padding: ".28571429em" }} onClick={redirect} >{vestigingsadres()}</Table.Cell>
          <Table.Cell style={{ padding: ".28571429em" }} onClick={redirect}> {postadres()}</Table.Cell>
        </Table.Body>
      </Table>
    </div>
  );
}
