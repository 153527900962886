import React, { useState } from "react";
import CustomerSelect from "./CustomerSelect";
import { Icon } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import AdminCellHealth from "./AdminCellHealth";
import AdminCellLastSeen from "./AdminCellLastSeen";
import {deletePlayer, linkPlayer} from '../../actions/videoInput'
import { player } from "../../types/player";
import { useAppDispatch } from "../../hooks/redux";


interface AdminCellConnectProps{
  player: player
}

export default function AdminCellConnect({player}:AdminCellConnectProps):JSX.Element {
    const [customerId, set_CustomerId] = useState("Selecteer een klant");
    const dispatch = useAppDispatch()

const saveHandler = () => {
    if(typeof(customerId) !== "number") return null
    dispatch(linkPlayer(player["MAC adres"] , customerId))
}
const deleteHandler = () => {
    dispatch(deletePlayer(player["MAC adres"]))
}

  return (
    <Table.Row>
      <Table.Cell>{player["MAC adres"]}</Table.Cell>
      <Table.Cell>
        <CustomerSelect set_CustomerId={set_CustomerId} />
      </Table.Cell>
      <Table.Cell> <div onClick={saveHandler} style={{cursor: 'pointer'}}>
        <Icon name="save" />
      </div>
      </Table.Cell>
      <Table.Cell> <div onClick={deleteHandler} style={{cursor: 'pointer'}}>
        <Icon name="delete" />
      </div>
      </Table.Cell>
      <Table.Cell style={{ textAlign: "center" }}>
        <AdminCellHealth player={player} />{" "}
      </Table.Cell>
      <Table.Cell style={{ textAlign: "center" }}>
        <AdminCellLastSeen player={player} />
      </Table.Cell>
    </Table.Row>
  );
}
