import React, { ChangeEvent, FocusEvent, KeyboardEvent, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import VideoTitle from "./VideoTitle";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { SelectAWeek,SelectAWeekQuickSave } from "../actions/videoInput";
import { Navigate } from "react-router-dom";

import {
  ambianceVideoSelection
} from "../constants";
import { selectAmbiancevideo, selectVideoInput } from "../actions/selectors";
 
export default function AmbianceFeed() {
  const ambianceVideos = useAppSelector(selectAmbiancevideo);
  const videoInput = useAppSelector(selectVideoInput);
  const [active, set_active] = useState(0);
  const [currentSrc, set_currentSrc] = useState<undefined|string>(undefined);
  const dispatch = useAppDispatch();

  const clickHandlerTitle = (videoId:number) => {
    set_active(videoId);
    let filter = ambianceVideos.filter(video => video.constructionVideo.id === videoId);
    if(filter.length ===  0) return null
    if(!videoInput) return null
    set_currentSrc(filter[0].constructionVideo.link);
    dispatch(
      //@ts-ignore
      SelectAWeek(
        videoInput.weekNumber,
        videoInput.year,
        null,
        null,
        null,
        null,
        null,
        null,
        videoId
      )
    );
  };

  const onChangeHandler = (e:ChangeEvent<HTMLTextAreaElement>) =>{
    dispatch({type: "AMBIANCE", payload: e.target.value})
  }
 const  onKeyDownHandler = (e:KeyboardEvent<HTMLTextAreaElement>) => {
      if(e.key === "Enter"){
        dispatch(
          //@ts-ignore
          SelectAWeekQuickSave(videoInput.weekNumber, videoInput.year, null, null, e.target.value)
        )
      }
    }

  const onBlurHandler = (e:FocusEvent) => {
    dispatch(
      //@ts-ignore 
      SelectAWeek(videoInput.weekNumber, videoInput.year, null, null, e.target.value)
    )
  }  

  if(!videoInput) return null
  if(videoInput.weekNumber === 0 ) return <Navigate to="/input"/>
  return (
    <div className="row">
                    <div className="col-12">
                        <div className="border-title sea-text">
                            <h4>Authenticiteit en Sfeer </h4>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card-box card-box-3 mb-4 mb-lg-0">
                            <h4>Sfeervideo</h4>
                            <p>Een bijpasssende sfeervideo ondersteunt daarbij uw boodschap. Kies één van de video`s, 
                                video 1 is geen video, bij die keuze wordt er geen video gebruikt.</p>
                            <div className="video-box">
                            <VideoPlayer
                              styleName="videoPlayer videoPlayer-blue"
                              toPlay={currentSrc}
                            />
                                <div className="video-list">
                                {ambianceVideos.map((videoinfo, i) => {
                                    return (
                                      <VideoTitle
                                        video={videoinfo.constructionVideo}
                                        click={clickHandlerTitle}
                                        key={i}
                                        active={videoInput.cm02}
                                        className="border-0 video-list-item video-list-item-blue"
                                      />
                                    );
                                  })}
                                    <p>{ambianceVideoSelection} {active} geselecteerd.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card-box card-color-3">
                            <h4>Authenticiteit en Sfeer</h4>
                            <p>Communiceer hier uw sterke punten waarom dient de klant nou juist bij u te komen?
                                Een winkel waar het prettig winkelen is, ga je als klant graag toe.</p>
                            <p>Dit kan men zoeken in de puurheid van de producten(geen onnodige toevoegingen of 
                                herkomst van de producten), uw kwaliteit of creatiefiteit. Kortom: beelden die bijdragen aan
                                 de <em className="extra-wrapper-em">beleving</em> die u de klant wilt meegeven in de winkel.</p>
                            <div className="textarea-box">
                                <div className="textarea-box-img">
                                    <img src="img/season-img-1.png" alt=""/>
                                </div>
                                <div className="text-area">
                                    <textarea className="bg-lightgold" 
                                              cols={30} 
                                              rows={10} 
                                              placeholder="Vul hier het authenticiteit en sfeer artikel in:"
                                              name="comment"
                                              onChange={onChangeHandler}
                                              value={videoInput.ambiance}
                                              onKeyDown={onKeyDownHandler} 
                                              onBlur={onBlurHandler}
                                              ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  );
}
