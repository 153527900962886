import {
  PASSWORD_RESET,
  SET_APP_ERROR,
  SET_APP_ERROR_RESET
} from "../actions/actionCreators";
import { resetResponse } from "../types/user";

export interface appState {
  errorMessage: string|null,
  passwordReset: resetResponse|null
}

export type appStateActionTypes = 
  | { type: typeof SET_APP_ERROR, payload: string}
  | { type: typeof SET_APP_ERROR_RESET}
  | { type: typeof PASSWORD_RESET; payload: resetResponse} 



const initalstate = {errorMessage: null, passwordReset: null};
const reducer = (state:appState = initalstate, action:appStateActionTypes) => {
  switch (action.type) {
    case SET_APP_ERROR:
      return { ...state, errorMessage: action.payload};
    case SET_APP_ERROR_RESET:
      return { ...state, errorMessage: null };
    case PASSWORD_RESET:
      return {...state, passwordReset: action.payload}
    default:
      return state;
  }
};

export default reducer
