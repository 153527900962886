import {SET_PRODUCTIONVIDEO} from '../actions/actionCreators'
import { ambiancePhoto, ambiancevideo } from '../types/ambiance'


export interface constructionVideoOrPhoto{
    constructionPhoto?: ambiancePhoto[]
    constructionVideo?: ambiancevideo[]
}

type constructionPhotoActionTypes = 
| {type: typeof SET_PRODUCTIONVIDEO, payload: constructionVideoOrPhoto}

const reducer  = (state:ambiancePhoto[] = [],  action:constructionPhotoActionTypes) => {
    switch (action.type) {
    case SET_PRODUCTIONVIDEO:
        return action.payload.constructionPhoto
    default:
        return state
    }
}

export default reducer