import { SET_TASK_LIST} from '../actions/actionCreators'
import { taskType } from '../types/tasks';

export interface webshopState {
    tasks: taskType[],
}

export type taskListActionTypes = 
  | {type: typeof SET_TASK_LIST; payload: taskType[] }

const reducer = (state:webshopState = {tasks: []},  action:taskListActionTypes) => {
    switch (action.type) {
    case SET_TASK_LIST:
        return {
            ...state,
            tasks: [...action.payload]
        }
    default:
        return state
    }
}
export default reducer
