import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getConstructionMedia } from "../../actions/videoInput";
import { selectMediaLibaryPhoto, selectMediaLibaryVideo } from "../../actions/selectors";
import { LibaryMediaType } from "../../types/constructionMedia";
import OverlayImage from "../../components/OverlayImage";
import HeibaLibaryItem from "../../components/admin/HeibaLibaryItem";
import { Tab } from "semantic-ui-react";

export default function HeibaLibary() {
  const constructionPhoto = useAppSelector(selectMediaLibaryPhoto);
  const constructionVideo = useAppSelector(selectMediaLibaryVideo );
  const dispatch = useAppDispatch();
  const [type] = useState<LibaryMediaType>("vlees"); 
  // const [type, set_type] = useState<LibaryMediaType>("vlees"); 
  const [showLarge, set_showLarge] = useState(false);
  const [imgUrl, set_imgUrl] = useState("");
  useEffect(() => {
    dispatch(getConstructionMedia(type, undefined));
  }, [type, dispatch]);


  // const semanticColors:SemanticCOLORS[]= ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']

  const panes = [
    { menuItem: 'Images', render: () => <Tab.Pane> 
      <div style={{  display: 'flex', flexFlow: 'wrap'}}>

      {constructionPhoto.map((image, i) => {
        if (!image) return null;
        if (!image.link) return null;
        return ( <HeibaLibaryItem
          key={i}
          mediaItem={image}
          set_showLarge={set_showLarge}
          set_imgUrl={set_imgUrl}
          showLarge={showLarge}
          mediaType="image"
          />)
        })}
        </div>
        </Tab.Pane> },
    { menuItem: 'Videos', render: () => <Tab.Pane>
      <div style={{  display: 'flex', flexFlow: 'wrap'}}>
      {
      constructionVideo.map((video, i) => {
        if (!video) return null;
        if (!video.link) return null;
        return (
           <HeibaLibaryItem
            key={i}
            mediaItem={video}
            set_showLarge={set_showLarge}
            set_imgUrl={set_imgUrl}
            showLarge={showLarge}
            mediaType="video"
            />
        );
      })}
      </div>
    </Tab.Pane> },
  ]
  

  return (
    <div>
      {/* <CustomerTypesList type={type} set_type={set_type} /> */}
      {/* <div style={{  display: 'flex', flexFlow: 'wrap'}}> */}
      
      {/* TODO prep for libary sorting */}
      {/* {semanticColors.map( (color, i) => { 
        return (
          <AdminMediaTag mediaTagType={{name: color, description: "", contentRightsLockTag: false, color}} cb={(e,d)=>console.log("active",e ,d )} />
        );
      })} */}
      {showLarge ?   
      <OverlayImage
          src={imgUrl}
          showLarge={set_showLarge}
        /> : null }
      <Tab panes={panes} />
     
      
    </div>
  // </div>
  );
}
