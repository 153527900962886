import React from "react";

export default function BannerShop() {
  return (
    <div className="banner-img flex-wrap">
      <div className="banner-left position-relative">
        <div className="white-red-shap position-relative">
          <img src="img/banner/tag.png" className="banner-tag" alt="" />
          <img className="white-red" src="img/banner/white-red.png" alt="" />
        </div>
        <div className="banner-icons">
          <img className="shoping-trolli" src="img/banner/shoping.png" alt="" />
          <img className="box-icon" src="img/banner/fb.png" alt="" />
          <img className="box-icon" src="img/banner/ins.png" alt="" />
          <img className="box-icon" src="img/banner/foo-1.png" alt="" />
          <img className="box-icon" src="img/banner/food-2.png" alt="" />
          <img className="box-icon" src="img/banner/food-3.png" alt="" />
          <img className="box-icon" src="img/banner/food-4.png" alt="" />
        </div>
      </div>

      <div className="banner-right position-relative">
        <div className="border-dased">
          <span>
            <img src="img/banner/speciale-actie.png" alt="" />
          </span>
        </div>
        <div className="screen-img">
          <img src="img/banner/hand-monitor-screen.png " alt="" />
        </div>
      </div>

      <div className="banner-border w-100">
        <img src="img/banner/borderline.png" alt="" />
      </div>
    </div>
  );
}
