import React, { useState } from "react";
import { KeyboardEventHandler } from "react";
import {updateConstructionItem} from "../../actions/videoInput"
import { LibaryMediaType } from "../../types/constructionMedia";
import { useAppDispatch } from "../../hooks/redux";

interface LibaryCellDiscriptionProps{
  image: {
    description: string
    id: number,
    category: LibaryMediaType
  }
}

export default function LibaryCellDiscription(props:LibaryCellDiscriptionProps) {
    const dispatch = useAppDispatch()
    const [name, set_name] = useState(props.image.description)
    
    const keyHandler:KeyboardEventHandler = ( e ) => {
        if(e.key === "Enter"){
            dispatch(updateConstructionItem(props.image.id, name, props.image.category))
        }
    }
  return (
    <div className="ui input">
      <input
        type="text"
        value={name}
        onKeyDown={keyHandler}
        onChange={e => set_name(e.target.value)}
      />
    </div>
  );
}
