const dateParser = (dateString:Date|string, options?: string) => {
  const localDate = new Date(dateString);
  if(!options || options !== "date"){
    return `${appendLeadingZeroes(localDate.getDate())}-${appendLeadingZeroes(
      localDate.getMonth() + 1
      )}-${localDate.getFullYear()} ${appendLeadingZeroes(localDate.getHours())}:${appendLeadingZeroes(localDate.getMinutes())}`;
    }
    return `${appendLeadingZeroes(localDate.getDate())}-${appendLeadingZeroes(
      localDate.getMonth()
      )}-${localDate.getFullYear()}`;

  };
  function appendLeadingZeroes(n:number):string {
    if (n <= 9) {
      return "0" + n;
    }
    return `${n}`;
  }
  
  export default dateParser