import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import {
  backendBaseUrl,
  minUploadFileHeight,
  minUploadFileSize,
  minUploadFileWidth,
} from "../constants";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import React  from "react";
import PreviewComponent from "./UploadPreview";
import { reAuth } from "../actions/videoInput";
import * as Sentry from "@sentry/browser"
import { selectUser, selectVideoInput } from "../actions/selectors";

interface DropzoneWrapperProps{
  setLock: any
  setWarning: any
  setWait: any
}


const DropzoneWrapper = ({ setLock, setWarning, setWait }:DropzoneWrapperProps) => {
  const dispatch = useAppDispatch()
  
  const user = useAppSelector(selectUser);
  const videoInput = useAppSelector(selectVideoInput);
  

  const getUploadParams = ({ file, meta }:any) => {
    return {
      url: `${backendBaseUrl}/upload`,
      headers: {
        Authorization: "Bearer " + user.jwt,
        year: videoInput?.year,
        week: videoInput?.weekNumber,
        contentUpload: file.name,
      },
    };
  };

  //this function might create a infinite loop
  const handleChangeStatus = async (file:any,status:any) => {
    if(status === 'error_upload'){
      if( file.xhr.getResponseHeader("Content-Length") ===  "23" ){ 
        dispatch(reAuth( file.restart)) 
      }else{
        Sentry.captureMessage("Other error in customer upload process")
      }
    }
    
  }

  const validate = (files:any) => {
    const status = files.map((f:any) => f.meta.status);
    const size = files
      .map((f:any) => f.meta.size)
      .filter((size:any) => size <= minUploadFileSize)
      .map(() => "small_file");

    const resolution = files
      .map((f:any) => ({ height: f.meta.height, width: f.meta.width }))
      .filter(
        (f:any) => f.height <= minUploadFileHeight || f.width <= minUploadFileWidth
      )
      .map(() => "low_resolution");

      //@ts-ignore
    const uploadStatus = [...new Set([...status, ...size, ...resolution])];
    setLock(  uploadStatus.includes("error_upload") || 
              uploadStatus.includes("exception_upload") ||
              uploadStatus.includes("rejected_file_type") ||
              uploadStatus.includes("rejected_max_files") ||
              uploadStatus.includes("error_file_size") ||
              uploadStatus.includes("error_validation") ||
              uploadStatus.includes("error_upload_params") ||
              uploadStatus.includes("aborted"))
    setWait(  uploadStatus.includes("uploading") || 
              uploadStatus.includes("getting_upload_params") ||
              uploadStatus.includes("preparing") 
               )
    if (
      uploadStatus.includes("low_resolution")  ||
      uploadStatus.includes("small_file")
    ) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  return (
    <Dropzone 
    //@ts-ignore
      getUploadParams={getUploadParams}
      //@ts-ignore
      onChangeStatus={handleChangeStatus}
      onSubmit={validate}
      addClassNames={{  dropzone: 'dropzone',
                        previewImage: "dropzonePreviewImage",
                        preview: "dropzonePreview",
                        inputLabel: "dropzoneInputLabel",
                        inputLabelWithFiles: "dropzoneInputLabelWithFiles",
                        input: "dropzoneInput" }}
      accept="image/*,video/*,image/heif"
      //@ts-ignore
      PreviewComponent={PreviewComponent}
      canCancel={false}
      SubmitButtonComponent={({ files }) => {
        validate(files);
        return null;
      }}
      inputContent={"Sleep bestanden in dit vlak of klik om up te loaden"}
      inputWithFilesContent="Bestanden toevoegen"
    />
  );
};

export default DropzoneWrapper;
