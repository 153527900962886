import React, {useEffect,useState} from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { selectNightPlayers, selectPlayers } from '../../actions/selectors';
import { getNightlyPlayers , getMalfunctions } from "../../actions/videoInput";
import Graph from './Graph'

export default function NigthlyPlayersGraph() {
    const dispatch = useAppDispatch();
    const players = useAppSelector(selectPlayers);
    const nightPlayers = useAppSelector(selectNightPlayers);
    const [graphData, set_graphData ]= useState([{}]) 
    
    useEffect(()=> {
      if(players && nightPlayers){
        const awakePlayers = nightPlayers.length || 0
        const allPlayers =  players.length - awakePlayers || 0
        set_graphData([{name: "Slapers", value: allPlayers} ,{name: "Nachtbrakers", value: awakePlayers, color: "#FF4263"}])
      }
      } , [players,nightPlayers])
    

  useEffect(() => {
    dispatch(getNightlyPlayers());
    const date =  new Date()
    dispatch(getMalfunctions(date.getFullYear()));
  }, [dispatch]);

    return (
        <div>
            <Graph title="Nachtbrakers" data={graphData}/>
        </div>
    )
}
