import React from "react";
import { weekInput } from "../../types/weekinput";

interface PreviewCellCompanyProps{
  input: weekInput
  set_CustomerId(n: number): void
}


export default function PreviewCellCompany(props:PreviewCellCompanyProps) {
  if (!props.input || !props.input.member) return null
    return (
      <div onClick={e => props.set_CustomerId(props.input.CustomerId)} style={{cursor: 'pointer'}}>
        {props.input.member.company}
      </div>
    );
}
