import { SET_WEBSHOP_PRODUCTS} from '../actions/actionCreators'
import { webshopProductType } from '../types/webshop';

export interface webshopState {
    products: webshopProductType[],
}

export type mediaLibaryActionTypes = 
  | {type: typeof SET_WEBSHOP_PRODUCTS; payload: webshopProductType[] }

const reducer = (state:webshopState = {products: []},  action:mediaLibaryActionTypes) => {
    switch (action.type) {
    case SET_WEBSHOP_PRODUCTS:
        return {
            ...state,
            products: [...action.payload]
        }
    default:
        return state
    }
}
export default reducer
