import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler } from "react";
import { FocusEventHandler } from "react";
import { selectVideoInput } from "../actions/selectors";
import { SelectAWeek,SelectAWeekQuickSave } from "../actions/videoInput";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export default function ProductExtraInfoFeed():JSX.Element {

  const videoInput = useAppSelector( selectVideoInput)
  const dispatch = useAppDispatch()


const onChangeHandler:ChangeEventHandler = (e: ChangeEvent<HTMLTextAreaElement>) =>{
  dispatch({type: "EXTRAINFO", payload: e.target.value})
}
const  onKeyDownHandler: KeyboardEventHandler = (e:React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(e.key === "Enter"){
      //@ts-ignore //TS-TODO 
      dispatch(SelectAWeekQuickSave(videoInput.weekNumber,videoInput.year ,null, null,  null, e.target.value)) 
    }
  }

const onBlurHandler:FocusEventHandler = (e: React.FocusEvent) => {
  //@ts-ignore //TS-TODO 
  dispatch(SelectAWeek(videoInput.weekNumber,videoInput.year ,null, null,  null, e.target.value)) 
}  

    return (
      <section className="extra-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="extra-info-title text-start mt-5 mb-3"/>
                            <h4 className="ps-0">Extra info</h4>
                        </div>
                        <div className="extra-wrapper mb-4">
                            <p>Vermeld hier uw (aangepaste) openingstijden, een voorstelronde van uw team, verjaardagen, social media en website.Mochte u een leuke foto hebben dan kunt u deze uploaden bij stam5.</p>
                            <p>Hier kunt u ook contact plaatsen die in de <em className="extra-wrapper-em">RTL_Z</em> balk getoond zal woorden.</p>
                        </div>
                    </div>
                </div>
                <div className="extra-textbox textbox-two pb-4">
                    <form action="">
                        <textarea className="bg-lightgold border-0"
                                  cols={30} 
                                  rows={10} 
                                  name="comment" 
                                  onChange={onChangeHandler} 
                                  onKeyDown={onKeyDownHandler} 
                                  value={videoInput?.extraInfo} 
                                  onBlur={onBlurHandler}
                                  placeholder="Vul hier extra informatie in:" ></textarea>
                        {/* <div className="extra-texarea-btns extra-textarea-btns-top">
                            <button type="submit" className="extra-btn">Terug naar <br/> weekSelecteren</button>
                            <button type="submit" className="extra-btn extra-btn-theme">Naar uploaden</button>    
                        </div> */}
                    </form>
                </div>
        </section>
    );
  }





