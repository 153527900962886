import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getMembers, loginLike } from "../../actions/videoInput";
import { Icon, Table } from "semantic-ui-react";
import  LoadingIndicator from "../LoadingIndicator";
import { selectrMembers, selectUser } from "../../actions/selectors";
import { member } from "../../types/member";


export default function LoginLike() {
  const members = useAppSelector(selectrMembers);
  const user = useAppSelector(selectUser)
  const [sortBy, set_sortBy] = useState("name");
  const [search, setSearch] = useState('')
  const [direction, set_direction] = useState<"descending" | "ascending" | undefined>("descending");
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getMembers());
    if(user.jwt){
      localStorage.setItem('JWT', user.jwt)
    }
  }, [dispatch, user.jwt]);

  const changeSortDirection = (type:string) => {
    if (type === sortBy) {
      set_direction(direction === "ascending" ? "descending" : "ascending");
    } else {
      set_sortBy(type);
    }
  };

  const sortByName = (a:member, b:member) => {
    if (a.company < b.company) {
      return direction === "descending" ? -1 : 1;
    }
    if (a.company > b.company) {
      return direction === "descending" ? 1 : -1;
    }
    return 0;
  };
  
  
  const sorted = members ? [...members].sort(sortByName) : []
  
  if(sorted.length === 0 ) return <LoadingIndicator loadingText="Members laden"/>
  
  const filtered =  sorted.filter(e =>{
    if(search){
      var re = new RegExp(search,"i");
      if(e?.company?.match(re)){
        return true
      }
      if(e?.contact?.match(re)){
        return true
      }
      return false
    }else{
      return true
    }
  })

  return (
    <div>
      <div className="playerSearchHolder">
    <input className="playerSearch" value={search} onChange={(e)=> setSearch(e.target.value)} placeholder="Zoek op bedrijf, id of player id"></input>
  </div>
  <hr/>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={"id" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("id")}
              style={{cursor: 'pointer'}}
            >
              id
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={"name" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("name")}
              style={{cursor: 'pointer'}}
            >
               Company
            </Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>HQ</Table.HeaderCell>
            <Table.HeaderCell>Login</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filtered.map((member, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{member.id}</Table.Cell>
                <Table.Cell>{member.company} { member?.basenetshop ? `(${member?.basenetshop?.Woonplaats_postadres})` : null} </Table.Cell>
                <Table.Cell>{member.contact}</Table.Cell>
                <Table.Cell>{member.HQ}</Table.Cell>
                <Table.Cell style={{ textAlign: "center", }}>
                 {member.HQ === "yes" ?  <div
                    onClick={(e) => {
                      dispatch(loginLike(member.id));
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <Icon name="rocket" />
                </div> : null}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
