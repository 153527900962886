import React from 'react'
import PropTypes from 'prop-types'

import { formatBytes, formatDuration } from 'react-dropzone-uploader'

import checkmark from './orangeCheckmark.svg'
import bin from './orangeBin.svg'

const cancelImg = "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOCAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzMzMzIj48cGF0aCBkPSJNMSwxNCBDMC40LDE0IDAsMTMuNiAwLDEzIEwwLDEgQzAsMC40IDAuNCwwIDEsMCBDMS42LDAgMiwwLjQgMiwxIEwyLDEzIEMyLDEzLjYgMS42LDE0IDEsMTQgWiIgaWQ9IlBhdGgiPjwvcGF0aD48cGF0aCBkPSJNNywxNCBDNi40LDE0IDYsMTMuNiA2LDEzIEw2LDEgQzYsMC40IDYuNCwwIDcsMCBDNy42LDAgOCwwLjQgOCwxIEw4LDEzIEM4LDEzLjYgNy42LDE0IDcsMTQgWiIgaWQ9IlBhdGgiPjwvcGF0aD48L2c+PC9zdmc+Cg=="
const removeImg = "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUuMCwgMC4wKSIgZmlsbD0iIzMzMzMzMyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC4wLCAwLjApIj48cG9seWdvbiBwb2ludHM9IjcuNzE5IDQuOTY0IDEyLjY5MiAwLjAxNyAxNC4zODkgMS43MTUgOS40MTIgNi42NjYgMTQuMzU0IDExLjYzNCAxMi42NTcgMTMuMzMxIDYuMDE3IDYuNjU3IDcuNzE1IDQuOTYwIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSI3LjYxMiA0Ljk2NCA3LjYxNiA0Ljk2MCA5LjMxMyA2LjY1NyAyLjY3NCAxMy4zMzEgMC45NzcgMTEuNjM0IDUuOTE5IDYuNjY2IDAuOTQyIDEuNzE1IDIuNjM5IDAuMDE3Ij48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4K"
const restartImg = "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTEgMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PHBhdGggZD0iTTAuNSwxNC45IEMwLjIsMTQuNyAwLDE0LjQgMCwxNCBMMCwyIEMwLDEuNiAwLjIsMS4zIDAuNSwxLjEgQzAuOCwwLjkgMS4yLDAuOSAxLjUsMS4xIEwxMC41LDcuMSBDMTAuOCw3LjMgMTAuOSw3LjYgMTAuOSw3LjkgQzEwLjksOC4yIDEwLjcsOC41IDEwLjUsOC43IEwxLjUsMTQuNyBDMS40LDE0LjkgMC44LDE1LjEgMC41LDE0LjkgWiBNMiwzLjkgTDIsMTIuMiBMOC4yLDguMSBMMiwzLjkgWiI+PC9wYXRoPjwvZz48L3N2Zz4K"
//TODO REFACTOR.. Clean up old stuf.. this is just a copy of another compnent... 

const iconByFn = {
  cancel: { backgroundImage: `url(${cancelImg})` },
  remove: { backgroundImage: `url(${removeImg})` },
  restart: { backgroundImage: `url(${restartImg})` },
}

// class UploadPreviewComponent extends React.PureComponent<IPreviewProps> {
class UploadPreviewComponent extends React.PureComponent {
  render() {
    const {
      className,
      style,
      imageStyle,
      fileWithMeta: { cancel, remove, restart },
      meta: { name = '', size = 0, previewUrl, status, duration, validationError },
      canCancel,
      canRemove,
      canRestart,
      extra: { minSizeBytes },
    } = this.props
    let statusText = null
    let title = `${name || '?'}`
    let fileSize = `${formatBytes(size)}`
    
    if (duration) title = `${title}, ${formatDuration(duration)}`
    if (status === 'error_file_size' || status === 'error_validation') {
      return (
        <div className={className} style={style}>
          <span className="dzu-previewFileNameError">{title}</span>
          {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
          {status === 'error_validation' && <span>{String(validationError)}</span>}
          {canRemove && <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove} />}
        </div>
      )
    }
   
    if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
      title = `${title}`
      statusText = `(uploaden mislukt)`
    }
    if (status === 'aborted') {
        title = `${title}`
        statusText = '(Gestopt)'
    }

    return (
      <div className={className} style={style}>
        {previewUrl && <img className="dropzonePreviewImage" style={imageStyle} src={previewUrl} alt={title} title={title} />}
        {!previewUrl && <span className="dzu-previewFileName">{`${title}`}</span>}
        {/* <div> */}
        <div className='dropzoneTitle'>
        <p className="dropzonePreviewTitle" >{title} <em>{statusText}</em></p>
        <p className="dropzonePreviewSubTitle" >{fileSize}</p>
        </div>
           
        <div className='dropzoneProgresAndQuality'>
          <div className="dzu-previewStatusContainer">
          
            <div className='imgQualityCheck'>
              <img src={checkmark} id="uploadDone" alt="upload done" style={{maxWidth: "25px"}} />
              <label className="imgQualtyText" for="uploadDone">Upload geslaagd</label>
            </div>
            
          
            {status === 'uploading' && canCancel && (
              <span className="dzu-previewButton" style={iconByFn.cancel} onClick={cancel} />
            )}
            {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
              <div className='imgQualityCheck' onClick={remove}>
                <img src={bin} id="bin" alt="remove img" style={{maxWidth: "25px"}} /> 
                <label className="imgQualtyText" for="bin">Verwijderen</label>
              </div>
            )}
            {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
              canRestart && <span className="dzu-previewButton" style={iconByFn.restart} onClick={restart} />}
          </div>
          </div>
      </div>
    )
  }
}

// @ts-ignore
UploadPreviewComponent.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  fileWithMeta: PropTypes.shape({
    file: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    xhr: PropTypes.any,
  }).isRequired,
  // copy of fileWithMeta.meta, won't be mutated
  meta: PropTypes.shape({
    status: PropTypes.oneOf([
      'preparing',
      'error_file_size',
      'error_validation',
      'ready',
      'getting_upload_params',
      'error_upload_params',
      'uploading',
      'exception_upload',
      'aborted',
      'error_upload',
      'headers_received',
      'done',
    ]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    uploadedDate: PropTypes.string.isRequired,
    percent: PropTypes.number,
    size: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    previewUrl: PropTypes.string,
    duration: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    validationError: PropTypes.any,
  }).isRequired,
  isUpload: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  canRestart: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line react/no-unused-prop-types
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
}

export default UploadPreviewComponent

