import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";
import { addManagingMember } from "../../actions/videoInput";
import { useAppDispatch } from "../../hooks/redux";
import { managingMember } from "../../types/member";
import CustomerSelect from "./CustomerSelect";

interface  PlayerDetailsManagingMemberProps{
  managingMembers: managingMember[] | undefined
  memberId: number
}

export default function PlayerDetailsManagingMember(props:PlayerDetailsManagingMemberProps) {
  const [customerId, set_CustomerId] = useState("Selecteer een klant");
  const dispatch = useAppDispatch()
  if (!props.managingMembers) return null;
  if (!props.memberId) return null;
  
  
  const generalCell = (header:string, info:string) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" ,minWidth: "120px"}}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em", minWidth: "150px" }}>{info}</Table.Cell>
      </Table.Row>
    );
  };
  const addAccountToMember = () => {
    if(typeof customerId != 'string'){
      dispatch(addManagingMember(props?.memberId, Number(customerId)));
    }
  }

  const addButton = () => {
    return generalCell(
      //@ts-ignore
      dropdown(),
      <div style={{ width: "60px", textAlign: "center" }}>
        <Icon name="plus circle" onClick={addAccountToMember} style={{cursor: 'pointer'}} />
      </div>
    );
  };
  const dropdown = () => {
    return (
      <div>
        <CustomerSelect set_CustomerId={set_CustomerId}/>
      </div>
    );
  };

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Company name</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>id</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props?.managingMembers?.map(mm =>  generalCell(`${mm?.member?.company}`, `${mm?.member?.id}`))}
          {addButton()}
        </Table.Body>
      </Table>
    </div>
  );
}
