import React, { Fragment,useEffect } from "react";
import "./App.scss";
import Login from "./pages/Login";
import { Route, Routes } from "react-router-dom";
import Preview from "./pages/Preview";
import Support from "./pages/Support";
import Forgot from "./pages/Forgot";
import Input from "./pages/Input";
import Top from "./components/Top";
import Oops from "./pages/Oops";
import Footer from "./components/Footer";
import InputUpload from "./pages/InputUpload";
import InputSummary from "./pages/InputSummary";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./pages/Logout";
import NoMatch from "./pages/NoMatch";
import Admin from "./pages/admin/Admin";
import Office from "./pages/admin/Office";
import OfficeHome from "./pages/admin/OfficeHome";
import CustomerInput from "./pages/admin/CustomerInput";
import Players from "./pages/admin/Players";
import PlayerDetails from "./pages/admin/PlayerDetails";
import AccountManagers from "./pages/admin/AccountManagers";
import Livedays from "./pages/admin/Livedays";
import FestiveAdd from "./pages/admin/FestiveAdd";
import Malfunctions from "./pages/admin/Malfunctions";
import Installation from "./pages/admin/Installation";
import InputFeed from "./pages/InputFeed";
import HeibaLibary from "./pages/admin/HeibaLibary";
import Upload from "./pages/admin/Upload";
import DayResult from "./pages/admin/DayResult";
import LivedaysWeb from "./pages/admin/LivedaysWeb";
import LoginLike from "./components/admin/LoginLike";
import FtpMembers from "./pages/admin/FtpMembers";
import Stats from "./pages/admin/Stats";
import CurrentlyOnServer from "./components/admin/CurrentlyOnServer";
import { me } from "./actions/videoInput";
import { selectUser } from "./actions/selectors";
// import CustomerMap from "./components/admin/CustomerMap";
import DisplayError from "./components/DisplayError";
import ProfilePage from "./pages/ProfilePage";
import Basenetshop from "./pages/admin/Basnetshop";
import PlayerStorage from "./pages/admin/PlayerStorage";
import HeibaLibaryInfo from "./pages/admin/HeibaLibaryInfo";
import HeibaLibaryTags from "./pages/admin/HeibaLibaryTags";
import SubMenu from "./components/SubMenu";
import GeneralMessage from "./components/GeneralMessage";
import UploadWebshop from "./pages/admin/UploadWebshop";
import UploadWebshopExplanation from "./pages/admin/UploadWebshopExplanation";
import Webshop from "./pages/admin/Webshop";
import TaskList from "./pages/admin/TaskList";
import { useAppDispatch,useAppSelector } from "./hooks/redux";
import BasecontentPage from "./pages/admin/BasecontentPage";

const message = ""
const displayGeneralMessage = message ? "generalMessage" : "";

function App() {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser);
  useEffect(() => {
    dispatch(me())
  }, [dispatch])
  return (
      <Fragment>
        {user?.jwt ? <Top /> : null }
        {user?.jwt ? <SubMenu/> : null}
        {user?.jwt ? <GeneralMessage text={message} /> : null}
        <div id="content-wrap" className={`${displayGeneralMessage}`}>

      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/forgot" element={<Forgot/>} />
        <Route path="/forgot/:token/:email" element={<Forgot/>} />
        <Route path="/oops" element={<Oops/>} />
        <Route element={<PrivateRoute user={user} />}>
          <Route path="/preview" element={<Preview/>}/>
          <Route path="/input/:next" element={<Input/>}/>
          <Route path="/inputfeed" element={<InputFeed/>}/>
          <Route path="/input" element={<Input/>}/>
          <Route path="/social" element={<Input/>}/>
          <Route path="/upload" element={<InputUpload/>}/>
          <Route path="/summary" element={<InputSummary/>}/>
          <Route path="/support" element={<Support/>}/>

          <Route path="/webshop" element={<Webshop/>}/>
          <Route path="/webshopUpload" element={<UploadWebshop />}/>
          <Route path="/webshopErrorExplanation" element={<UploadWebshopExplanation/>}/>

          <Route path="/logout" element={<Logout/>}/>
        </Route>
        <Route element={<PrivateRoute admin={true} user={user}/>}>
            <Route path="/admin" element={<Admin />}/>
            <Route path="/profile" element={<ProfilePage/>}  /> 
            <Route path="/officehome" element={<OfficeHome />}/>
            <Route path="/office/:id" element={<CustomerInput />}/>
            <Route path="/office" element={<Office />}/>
            <Route path="/players/:mac/:id" element={<PlayerDetails />}/>
            <Route path="/players" element={<Players />}/>
            <Route path="/accoutmanagers" element={<AccountManagers />}/>
            <Route path="/livedays" element={<Livedays />}/>
            <Route path="/livedaysweb" element={<LivedaysWeb />}/>
            <Route path="/festiveagenda" element={<FestiveAdd />}/>
            <Route path="/malfunctions" element={<Malfunctions />}/>
            <Route path="/tasklist" element={<TaskList />}/>
            <Route path="/libary" element={<HeibaLibary />}/>
            <Route path="/libarytags" element={<HeibaLibaryTags />}/>
            <Route path="/libaryInfo/:id/:mediaType" element={<HeibaLibaryInfo/>}/>
            <Route path="/libaryUpload" element={<Upload />}/>
            <Route path="/dayresult" element={<DayResult />}/>
            <Route path="/loginLike" element={<LoginLike />}/>
            <Route path="/ftpMembers" element={<FtpMembers />}/>
            <Route path="/stats" element={<Stats />}/>
            <Route path="/currentlyonftp" element={<CurrentlyOnServer />}/>
            {/* <Route path="/kaart" element={<CustomerMap />}/> */}
            <Route path="/basenetshop/:id" element={ <Basenetshop/>}/>
            <Route path="/playerStorage/:id" element={ <PlayerStorage/>}/>
            <Route path="/installation" element={<Installation />}/>
            <Route path="/baseContent" element={<BasecontentPage />}/>
        </Route>
        <Route path="*" element={<NoMatch/>} />
      </Routes>
      </div>
      <DisplayError/>
      <Footer />
    </Fragment>
  );
}

export default App;
