import { appStateActionTypes } from "../reducers/appState";
import { constructionVideoOrPhoto } from "../reducers/constructionPhoto";
import { mediaLibaryState } from "../reducers/mediaLibary";
import { userActionTypes } from "../reducers/user";
import { ambiancevideo } from "../types/ambiance";
import { APImembercopydays } from "../types/API";
import { previewVideo } from "../types/previewVideo";
import { taskType } from "../types/tasks";
import { resetResponse, user } from "../types/user";
import { webshopProductType } from "../types/webshop";
import { weekinputWithUploads } from "../types/weekinput";

export const SET_WEEKSELECT = "SET_WEEKSELECT";
export const SET_PRODUCTIONVIDEO = "SET_PRODUCTIONVIDEO";
export const SET_AMBANCEVIDEO = "SET_AMBANCEVIDEO";
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const ME = "ME"
export const RENEW_JWT = "RENEW_JWT";
export const REPLACE_JWT = "REPLACE_JWT";
export const RENEW_JWT_RT_INVALID = "RENEW_JWT_RT_INVALID";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBER_LOCATIONS = "SET_MEMBER_LOCATIONS";
export const SET_VIDEOINPUT = "SET_VIDEOINPUT";
export const SET_VIDEOINPUT_STATS = "SET_VIDEOINPUT_STATS";
export const SET_VIDEOINPUT_BY_ID = "SET_VIDEOINPUT_BY_ID";
export const SET_HEIBAWEEKS = "SET_HEIBAWEEKS";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_NIGHT_PLAYERS = "SET_NIGHT_PLAYERS";
export const SET_NEW_PLAYERS = "SET_NEW_PLAYERS";
export const SET_OLD_PLAYERS = "SET_OLD_PLAYERS";
export const SET_PREVIEW = "SET_PREVIEW";
export const SET_PLAYER_DETAILS = "SET_PLAYER_DETAILS";
export const SET_PLAYER_SSM_DETAILS = "SET_PLAYER_SSM_DETAILS";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_PLAYER_HISTORY = "SET_PLAYER_HISTORY";
export const SET_PLAYER_LOCATIONS = "SET_PLAYER_LOCATIONS";
export const SET_PLAYER_SERVER = "SET_PLAYER_SERVER";
export const SET_YEAR_INPUT = "SET_YEAR_INPUT";
export const SET_ACCOUNTMANAGER = "SET_ACCOUNTMANAGER";
export const SET_LIVEDAYS = "SET_LIVEDAYS";
export const SET_GENERAL_LIVEDAYS = "SET_GENERAl_LIVEDAYS";
export const SET_FESTIVE_DAYS = "SET_FESTIVE_DAYS";
export const SET_PLAYERLESSBUTTONS = "SET_PLAYERLESSBUTTONS";
export const RESET_WEEKINPUT = "RESET_WEEKINPUT";
export const SET_MALFUNCTIONS = "SET_MALFUNCTIONS";
export const SET_FTP_MEMBERS = "SET_FTP_MEMBERS";
export const ACTIVATE_VIDEOTYPE = "ACTIVATE_VIDEOTYPE"
export const SET_MEDIA_LIBARY_PHOTO = "SET_MEDIA_LIBARY_PHOTO"
export const SET_MEDIA_LIBARY_MEDIA = "SET_MEDIA_LIBARY_MEDIA"
export const UPLOADEDFILES = "UPLOADEDFILES"
export const DASHBOARD_COUNT = "DASHBOARD_COUNT"
export const DAY_SUMMARY = "DAY_SUMMARY"
export const TODOLIST = "TODOLIST"
export const SET_PLAYER_SWITCH = "SET_PLAYER_SWITCH"
export const INSTALLATION_MEDIA = "INSTALLATION_MEDIA"
export const VIDEO_ON_SERVER = "VIDEO_ON_SERVER"
export const LOGIN_LIKE = "LOGIN_LIKE"
export const PASSWORD_RESET = "PASSWORD_RESET"
export const SET_PLAYER_DOWNLOAD = "SET_PLAYER_DOWNLOAD"
export const SET_SERVER_DOWNLOAD_LOGS = "SET_SERVER_DOWNLOAD_LOGS"
export const SET_SUMMARY_GRAPH = "SET_SUMMARY_GRAPH"
export const SET_IN_PLAYERFOLDER = "SET_IN_PLAYERFOLDER"
export const WEEKSELECT = "WEEKSELECT"
export const FOCUSPRODUCT = "FOCUSPRODUCT"
export const AMBIANCE = "AMBIANCE"
export const SEASON = "SEASON"
export const EXTRAINFO = "EXTRAINFO"
export const FACEBOOK = "FACEBOOK"
export const SET_APP_ERROR = "SET_APP_ERROR"
export const SET_APP_ERROR_RESET = "SET_APP_ERROR_RESET"
export const SET_BASENETSHOP = "SET_BASENETSHOP"
export const SET_PLAYER_STORAGE_INFO = "SET_PLAYER_STORAGE_INFO"
export const SET_MEMBER_COPYDAYS = "SET_MEMBER_COPYDAYS"
export const SET_CONSTRUCTION_ITEM = "SET_CONSTRUCTION_ITEM"
export const SET_MEDIA_TAGS = "SET_MEDIA_TAGS"
export const SET_WEBSHOP_PRODUCTS = "SET_WEBSHOP_PRODUCTS"
export const SET_TASK_LIST = "SET_TASK_LIST"
export const SET_BASE_CONTENT = "SET_BASE_CONTENT"
export const SET_MANAGING_MEMBER = "SET_MANAGING_MEMBER"
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION"
export const SET_SUBSCRIPTION_OPTIONS = "SET_SUBSCRIPTION_OPTIONS"


export type Action = {type: string, payload: object | string}

export const setWeekSelect = (data:weekinputWithUploads):Action => {
  return {
    type: SET_WEEKSELECT,
    payload: { ...data.weekInput, uploads: data.uploads }
  };
};
export const setProductVideo = (data:constructionVideoOrPhoto) => {
  return {
    type: SET_PRODUCTIONVIDEO,
    payload: data
  };
};
export const setHeibaWeeks = (data:any):Action => {
  return {
    type: SET_HEIBAWEEKS,
    payload: data
  };
};
export const setAmbianceVideo = (data:{constructionVideo: ambiancevideo[]}) => {
  return {
    type: SET_AMBANCEVIDEO,
    payload: data.constructionVideo
  };
};
export const setConstructionItem = (data:{constructionItem: ambiancevideo}) => {
  return {
    type: SET_CONSTRUCTION_ITEM,
    payload: data.constructionItem
  };
};
export const setMe = (data:user):userActionTypes => {
  return {
    type: ME,
    payload: data
  };
};
export const setLogin = (data:user):userActionTypes => {
  return {
    type: LOGIN,
    payload: data
  };
};
export const setPasswordReset = (data:resetResponse):appStateActionTypes => {
  return {
    type: PASSWORD_RESET,
    payload: data
  };
};
export const setReplaceJwt = (data:string, heibaToken:string):userActionTypes => {
  return {
    type: REPLACE_JWT,
    payload: {jwt: data, refreshToken: heibaToken}
  };
};
export const setMembers = (data:any):Action => {
  return {
    type: SET_MEMBERS,
    payload: data.members
  };
};
export const setMemberLocations = (data:any):Action => {
  return {
    type: SET_MEMBER_LOCATIONS,
    payload: data.members
  };
};
export const setInPlayerFolders = (data:any):Action => {
  return {
    type: SET_IN_PLAYERFOLDER,
    payload: data.files
  };
};
export const setPreview = (data:{ preview: Array<previewVideo>}) => {
  return {
    type: SET_PREVIEW,
    payload: data.preview
  };
};
export const setVideoInput = (data:any):Action => {
  return {
    type: SET_VIDEOINPUT,
    payload: data.inputWeeks
  };
};
export const setVideoStats = (data:any):Action => {
  return {
    type: SET_VIDEOINPUT_STATS,
    payload: data.inputWeeksStats.count
  };
};
export const setVideoInputById = (data:any):Action => {
  return {
    type: SET_VIDEOINPUT_BY_ID,
    payload: data.inputWeeks
  };
};
export const setPlayers = (data:any):Action => {
  return {
    type: SET_PLAYERS,
    payload: data.players
  };
};
export const setNightlyPlayers = (data:any):Action => {
  return {
    type: SET_NIGHT_PLAYERS,
    payload: data.players
  };
};
export const setBasenetshop = (data:any):Action => {
  return {
    type: SET_BASENETSHOP,
    payload: data.basenetshop
  };
};
export const setConnectPlayers = (data:any):Action => {
  return {
    type: SET_NEW_PLAYERS,
    payload: data.players
  };
};
export const setOldPlayers = (data:any):Action => {
  return {
    type: SET_OLD_PLAYERS,
    payload: data.players
  };
};
export const setPlayerDetails = (data:any):Action => {
  return {
    type: SET_PLAYER_DETAILS,
    payload: data.player
  };
};
export const setCustomerDetails = (data:any):Action => {
  return {
    type: SET_CUSTOMER_DETAILS,
    payload: data.member
  };
};
export const setNewJwt = (data:string):userActionTypes => {
  return {
    type: RENEW_JWT,
    payload: data
  };
};
export const setInvalidJWT = ():userActionTypes => {
  return {
    type: RENEW_JWT_RT_INVALID,
  };
};
export const setPlayerHistory = (data:any):Action => {
  return {
    type: SET_PLAYER_HISTORY,
    payload: data.history
  };
};
export const setPlayerLocations = (data:any):Action => {
  return {
    type: SET_PLAYER_LOCATIONS,
    payload: data.locations
  };
};
export const setPlayerServer = (data:any):Action => {
  return {
    type: SET_PLAYER_SERVER,
    payload: data.status
  };
};
export const setPlayerSsmInfo = (data:any):Action => {
  return {
    type: SET_PLAYER_SSM_DETAILS,
    payload: data
  };
};
export const setYearInput = (data:any):Action => {
  return {
    type: SET_YEAR_INPUT,
    payload: data.input
  };
};
export const setAccountmanagers = (data:any):Action => {
  return {
    type: SET_ACCOUNTMANAGER,
    payload: data.accountmanagers
  };
};
export const setMemberPiPlayerCopydays = (data:APImembercopydays):Action => {
  return {
    type: SET_MEMBER_COPYDAYS,
    payload: data
  };
};
export const setLivedays = (data:any):Action => {
  return {
    type: SET_LIVEDAYS,
    payload: data.live
  };
};
export const setFestiveDays = (data:any):Action => {
  return {
    type: SET_FESTIVE_DAYS,
    payload: data.festiveDays
  };
};
export const setPlayerLessButtons = (data:any):Action => {
  return {
    type: SET_PLAYERLESSBUTTONS,
    payload: data.buttons
  };
};
export const setGeneralLivedays = (data:any):Action => {
  return {
    type: SET_GENERAL_LIVEDAYS,
    payload: data.livedays
  };
};
export const setMalfunctions = (data:any):Action => {
  return {
    type: SET_MALFUNCTIONS,
    payload: data.malfuntions
  };
};
export const activateInput = (data:any):Action => {
  return {
    type: ACTIVATE_VIDEOTYPE,
    payload: data
  };
};
export const setMediaTags = (data:any):Action => {
  return {
    type: SET_MEDIA_TAGS,
    payload: data.mediaTags
  };
};
export const setMediaLibaryPhoto = (data:any):Action => {
  return {
    type: SET_MEDIA_LIBARY_PHOTO,
    payload: data
  };
};
export const setMediaLibaryMedia = (data:mediaLibaryState):Action => {
  return {
    type: SET_MEDIA_LIBARY_MEDIA,
    payload: data
  };
};
export const setUploadedFiles = (data:any):Action => {
  return {
    type: UPLOADEDFILES,
    payload: {originalName: data.fileName}
  };
};
export const setDashboardCount = (data:any):Action => {
  return {
    type: DASHBOARD_COUNT,
    payload: data
  };
};
export const setDaySummary = (data:any):Action => {
  return {
    type: DAY_SUMMARY,
    payload:  data
  };
};
export const setTodolist = (data:any):Action => {
  return {
    type: TODOLIST,
    payload:  data
  };
};
export const setInstallationMedia = (data:any):Action => {
  return {
    type: INSTALLATION_MEDIA,
    payload:  data.media
  };
};
export const setPlayerSwitch = (data:any):Action => {
  return {
    type: SET_PLAYER_SWITCH,
    payload:  data
  };
};
export const  setCurrentlyOnServer= (data:any):Action => {
  return {
    type: VIDEO_ON_SERVER,
    payload:  data.files
  };
};
export const  setLoginLike = (data:user):userActionTypes => {
  return {
    type: LOGIN_LIKE,
    payload:  data
  };
};
export const  setFtpMembers = (data:any):Action => {
  return {
    type: SET_FTP_MEMBERS,
    payload:  data.members
  };
};
export const  setPlayerDownloads = (data:any):Action => {
  return {
    type: SET_PLAYER_DOWNLOAD,
    payload:  data.downloads
  };
};
export const  setServerLogRecords = (data:any):Action => {
  return {
    type: SET_SERVER_DOWNLOAD_LOGS,
    payload:  data.ftpLogRecords
  };
};
export const  setManagingMembers = (data:any):Action => {
  return {
    type: SET_MANAGING_MEMBER,
    payload:  data
  };
};
export const  setSubscriptions = (data:any):Action => {
  return {
    type: SET_SUBSCRIPTION,
    payload:  data
  };
};
export const  setSubscriptionOptions = (data:any):Action => {
  return {
    type: SET_SUBSCRIPTION_OPTIONS,
    payload:  data
  };
};
export const  setBaseContent = (data:any):Action => {
  return {
    type: SET_BASE_CONTENT,
    payload:  data
  };
};
export const  setSummaryGraph = (data:any):Action => {
  return {
    type: SET_SUMMARY_GRAPH,
    payload:  data.data
  };
};
export const  setWebshopProducts = (data:webshopProductType[]):Action => {
  return {
    type: SET_WEBSHOP_PRODUCTS,
    payload:  data
  };
};
export const  setTaskList = (data:taskType[]):Action => {
  return {
    type: SET_TASK_LIST,
    payload:  data
  };
};
export const  setPlayerStorageInfo = (data:any):Action => {
  return {
    type: SET_PLAYER_STORAGE_INFO,
    payload: data.storageInfo
  };
};
export const  setAppError = (text:string):appStateActionTypes => {
  return {
    type: SET_APP_ERROR,
    payload: text
  };
};
export const  setAppErrorReset = ():appStateActionTypes => {
  return {
    type: SET_APP_ERROR_RESET
  };
};



