import React from "react";
import { Table } from "semantic-ui-react";
import AdminCellHealth from "./AdminCellHealth";
import AdminCellLastSeen from "./AdminCellLastSeen";
import AdminCellMac from "./AdminCellMac";
import AdminCellDetach from "./AdminCellDetach";
import { player } from "../../types/player";


interface PlayerAdminPlayersProps{
  players: player[] | undefined
  customer: number
  unlink?: boolean
  showTitle?: boolean
  currentMac? : string
  navigate: Function
}

export default function PlayerAdminPlayers(props:PlayerAdminPlayersProps) {
  return (
    <div >
      { props.showTitle ? <h1>Alle players van deze klant:</h1> : null}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>mac</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Seen</Table.HeaderCell>
            { props.unlink ? <Table.HeaderCell>Unlink</Table.HeaderCell> : null}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props?.players?.map((player, i) => {
            if (!player.piplayerHealth) return null;
            if (player?.PiplayerExtraInterface?.id) return null

            return (
              <Table.Row key={i}>
                <Table.Cell>
                  <AdminCellMac player={player} owner={props.customer} bold={props?.currentMac === player["MAC adres"]} navigate={props?.navigate}/>
                </Table.Cell>
                <Table.Cell>
                  <AdminCellHealth player={player} />{" "}
                </Table.Cell>
                <Table.Cell>
                  <AdminCellLastSeen player={player} />
                </Table.Cell>
                { props.unlink ? <Table.Cell>
                  <AdminCellDetach player={player} customer={props.customer} style={{cursor: 'pointer'}}/>
                </Table.Cell> : null}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
