import React, { useEffect,useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getVideoInputById } from "../../actions/videoInput";
import { Form, TextArea, Label } from "semantic-ui-react";
import { generalApiCallSetter } from "../../actions/apiCalls";
import dateParser from "../../dateParser";

export default function CustomerInput(props) {
  const params = useParams();
  const dispatch = useAppDispatch();
  const weekDetails = useAppSelector(reduxState => reduxState.admin.weekDetails);
  const week = useRef();
  const pf = useRef();
  const ss = useRef();
  const hs = useRef();
  const ei = useRef();
  const [doneCustomerInput, set_doneCustomerInput] = useState(false)
  const [doneOfficeInput, set_doneOfficeInput] = useState(false)
  const [donePreviewCustomerAccepted, set_donePreviewCustomerAccepted] = useState(false)

  useEffect(() => {
    dispatch(getVideoInputById(params.id));
  }, [params.id, dispatch]);

  const copyToClipboard = (pf) => {
      pf.current.ref.current.select()
      document.execCommand('copy');
  };
  
  


  if (!weekDetails || !weekDetails.id) return <p>Geen input gevonden</p>
  return (
    <div>
      <h2>{weekDetails?.member?.company} ({weekDetails?.member?.MemberAccountmanager?.memberId})</h2>
      <h3>Accountmanager: <em className="name">{weekDetails?.member?.MemberAccountmanager?.Accountmanager?.name}</em></h3>
      <p>Aangeleverd op: {dateParser(weekDetails?.date)}</p>
      <Form>
        <Label onClick={e => copyToClipboard(week)} style={{cursor: 'pointer'}}>Week</Label>
        <TextArea className="officeContentInput" ref={week} value={weekDetails.week + "(" + weekDetails.year + ")"}/>

        <Label onClick={e => copyToClipboard(pf)} style={{cursor: 'pointer'}}> Product info </Label>
        <TextArea className='officeContentInput'  ref={pf} value={weekDetails.pf}/>

        <Label onClick={e => copyToClipboard(ss)} style={{cursor: 'pointer'}}>Seizoen en Streek</Label>
        <TextArea ref={ss} value={weekDetails.ss}/>

        <Label onClick={e => copyToClipboard(pf) } style={{cursor: 'pointer'}}>Humor en sfeer</Label>
        <TextArea ref={hs} value={weekDetails.ss}/>

        <Label onClick={e => copyToClipboard(ei)} style={{cursor: 'pointer'}} >Extra info</Label>
        <TextArea ref={ei} value={weekDetails.ei}/>

        <Label>Producie video</Label><br/>
        <div className='officeContentInput'>
          <p className='officeContentInput'>{weekDetails.cm01}</p>
        </div>
        <br/>
        
        <Label>SfeerVideo</Label><br/>
        <div className="officeContentInput">
          <p className="officeContentInput">{weekDetails.cm02}</p>
        </div>
        <br/>

        
      </Form>
      <h2>Pas op met deze knoppen! ze versturen emails echt naar klanten!</h2>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_doneCustomerInput, 'isSend', {inputId: params.id, customerInput: true} ))}>Stuur customer input mail (opnieuw) </button>
      {JSON.stringify(doneCustomerInput)}
      <br/>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_doneOfficeInput, 'isSend', {inputId: params.id, officeInput: true} ))}>Stuur customer office mail (opnieuw) </button>
      {JSON.stringify(doneOfficeInput)}
      <br/>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_donePreviewCustomerAccepted, 'isSend', {previewId: 66, previewAccepted: true} ))}>Stuur customer preview accepted </button>
      {JSON.stringify(donePreviewCustomerAccepted)}
      <br/>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_donePreviewCustomerAccepted, 'isSend', {previewId: 66, officePreviewAccepted: true} ))}>Stuur Office preview accepted </button>
      {JSON.stringify(donePreviewCustomerAccepted)}
      <br/>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_donePreviewCustomerAccepted, 'isSend', {previewId: 66, previewRework: true} ))}>Stuur customer preview Rework </button>
      {JSON.stringify(donePreviewCustomerAccepted)}
      <br/>
      <button onClick={ e => dispatch(generalApiCallSetter("post", `/email`,set_donePreviewCustomerAccepted, 'isSend', {previewId: 66, officePreviewRework: true} ))}>Stuur Office preview Rework </button>
      {JSON.stringify(donePreviewCustomerAccepted)}

    </div>
  );
}