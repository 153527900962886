import {combineReducers} from 'redux'
import videoInput from './videoInput'
import preview from './preview'
import user from './user'
import constructionphoto from './constructionPhoto'
import constructionvideo from './constructionVideo'
import ambiancevideo from './ambiancevideo'
import mediaLibary from './mediaLibary'
import admin from './admin'
import appState from './appState'
import webshop from './webshop'
import taskQueue from './taskQueue'
export default combineReducers({
   videoInput,
   preview,
   constructionphoto,
   constructionvideo,
   mediaLibary,
   ambiancevideo,
   user,
   admin,
   appState,
   webshop,
   taskQueue
})