import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";
import { selectUserLogout } from "../actions/selectors";

const ProtectedRoute = (props:any) => {
  const location = useLocation()
  const { children, admin, user} = props
  const logout = useAppSelector(selectUserLogout);
  
  if(!user?.jwt) return <Navigate to="/login" state={{ from: location}}/>

  if(admin){
    if(user?.role !== "admin"){
      return <Navigate to="/oops"/>
    }
  }
  if(logout){
    return <Navigate to="/" />
  }
  return children ? children : <Outlet />;
};
export default ProtectedRoute
