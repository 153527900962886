import React from "react";
import { previewLocation } from "../constants";
import { previewVideo } from "../types/previewVideo";

interface DownloadProps {
  video: previewVideo;
}

export default function Download(props: DownloadProps): JSX.Element {
  return (
    <div className="fileDownload">
      <div className="download">
        <div className="downloadButtonGradient">
          <a
            className="downloadButtonGradientText"
            href={`${previewLocation}${props.video.filename}`}
          >
            Downloaden
          </a>
        </div>
        <p>Download hier uw video</p>
      </div>
    </div>
  );
}
