import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { useParams } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { selectBasenetshop } from "../../actions/selectors";
import { getBasenetshop } from "../../actions/videoInput";
import PlayerBasnetAdress from "../../components/admin/PlayerBasnetAdress";

export default function Basenetshop() {
  const dispatch = useAppDispatch()
  const basenetshop =  useAppSelector(selectBasenetshop)
  const params = useParams<{id: string}>();

  useEffect(() => {
    if(params?.id){
      dispatch(getBasenetshop(params.id))
    }
  }, [dispatch, params.id])

  const generalCell = (header:string, info:string|JSX.Element|number, key?:number) => {
    return (
      <Table.Row key={key||header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info}</Table.Cell>
      </Table.Row>
    );
  };

  const all = () => {
    let arr = []
    for (const key in basenetshop) {
      //@ts-ignore
      arr.push(generalCell( `${key}`, basenetshop[`${key}`] ))
    } 
    return arr
  }

  return (
    <React.Fragment> 
      <PlayerBasnetAdress basenetshop={basenetshop}/>
      <br/>

    <div>
      <Table celled>
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Vestigingsadres</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Postadres</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
          {all()}
        </Table.Body>
      </Table>
    </div>
    </React.Fragment>
  );
}
