import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getAllAccountmanagers } from "../../actions/videoInput";
import { Table } from "semantic-ui-react";
import { Dimmer, Loader } from "semantic-ui-react";
import AccountmanagerRating from "../../components/admin/AccountmanagerRating";
import AccountManagerGraph from "../../components/admin/AccountManagerGraph";
import { selectAccountmanagers } from "../../actions/selectors";

export default function AccountManagers() {
  const dispatch = useAppDispatch();
  const accountManagers = useAppSelector(selectAccountmanagers);
  
  useEffect(() => {
    dispatch(getAllAccountmanagers());
  }, [dispatch]);

  const loading = (loadingText:string) => {
    return (
      <Dimmer active inverted>
        <Loader inverted content={loadingText} />
      </Dimmer>
    );
  };

  if (accountManagers?.length <= 1) return loading("Accountmanagers laden");
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>name</Table.HeaderCell>
            <Table.HeaderCell>Aantal klanten</Table.HeaderCell>
            <Table.HeaderCell>Aantal reviews</Table.HeaderCell>
            <Table.HeaderCell>Stars</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {accountManagers?.map((ac, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{ac.id}</Table.Cell>
                <Table.Cell>{ac.name}</Table.Cell>
                <Table.Cell>{ac.MemberAccountmanagers.length}</Table.Cell>
                <Table.Cell>{ac.Star_rating_news.length}</Table.Cell>
                <Table.Cell><AccountmanagerRating ratings={ac.Star_rating_news}/></Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
          <b className="accountmanagersExplanation">Ratings worden enkel berekend op basis van actieve klanten.</b>
      <AccountManagerGraph/>
    </div>
  );
}
