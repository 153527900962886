import React from "react";
export const officePhoneNumber = "020 - 422 98 98";
export const officePhoneNumberLink = "tel:123-456-7890";
export const firstPageAfterLogin = "/preview";
export const firstAdminPageAfterLogin = "/officeHome"

//run in browser console: localStorage.setItem("backend", "http://localhost:4000")
const backend = localStorage.getItem("backend")
export const backendBaseUrl = backend ? backend : "https://backend.heibaservice.eu"
if(backend){
  console.log("_____________USE LOCALHOST BACKEND______________")
}

export const frontEndUrl = "https://heibaservice.eu"
export const previewLocation = "https://www.heibaservice.tv/users"

export const customerLogo = (dir: string | undefined) => 
{
  if(dir){
    return "https://heibaservice.tv/users/" + dir + "/logo.png"  
  }else 
    return "/heibaLogo.png"

}

export const ambianceStepName = "Authenticiteit en Sfeer";
export const ambianceStepNumber = "stap 3 van 6";
export const ambianceText = "Seizoen en streek producten";
export const ambianceIntro =
  "Communiceer hier uw sterke punten. Waarom dient de klant nou juist bij u te komen? Een winkel waar het prettig winkelen is, ga je alsklant graag naar toe.";
//@ts-ignore  
export const ambianceIntroExplanation = [
  <p key="0">
    Dit kan men zoeken in de puurheid van de producten(geen onnodige
    toevoegingen of herkomst van de producten), uw kwaliteit of creatiefiteit.
    Kortom: beelden die bijdragen aan de <strong>beleving</strong> die u de
    klant wilt meegeven in de winkel{" "}
  </p>
];
export const ambianceExample = "Voorbeelden van authenticiteit en sfeer";
export const ambianceTask = "Vul hier uw authentieke en/of sfeerpunten in:";
export const ambianceVideoType = "Sfeervideo";
export const ambianceVideoDescription = "Een bijpassende sfeervideo ondersteunt daarin uw boodschap. Kies één van de video's, video 1 is geen video,bij die keuze wordt er geen video gebruikt.";
export const ambianceVideoSelection = "U heeft Sfeer video";


export const  MONTHS_LONG = ["Januari" ,"Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober",  "November", "December" ];
export const  WEEKDAYS_SHORT = ["Zo" ,"Ma", "Di", "Wo", "Do", "vrij", "Za" ];
export const WEEKDAYS_LONG = [
  "Zondag",
  "Maandag",
  "Dinsdag",
  "Woensdag",
  "Donderdag",
  "Vrijdag",
  "Zaterdag",
];

export const lowQualityUpload = "De bestanden die je geupload hebt zijn wat klein! Zou je volgende keer afbeeldingen van een betere kwaliteit mee kunnen sturen?"
export const noUpload = "Ik zie helemaal geen uploads, stuur wat content mee zodat we een video kunnen maken met jouw producten. "

export const minUploadFileSize = 500000 //500kb 
export const minUploadFileHeight = 500 //px
export const minUploadFileWidth = 500  //px
export const heibaOfficeLocation = { lat: 52.390860, lng: 4.904560 }