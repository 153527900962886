import React from 'react'

export default function UploadWebshopExplanation() {
    const wth   = ["PLU","Naam","Prijs","PrijsEenheid","VerkoopVolume","VerkoopEenheid","Hoofdproduct","Categorie" ]
    // const wthEn = ["plu", "name", "price", "priceUnit", "salesVolume", "salesUnit", "masterProduct", "categories"]
  return (
    <div>
        <h1>UploadWebshopExplanation</h1>
        <p>Indien je een van de onderstaande foutmeldingen ziet, zorg dat je dan voldoet aan onderstaande kenmerken</p>
        
        <h2>validatePlu </h2>
            <ul><li>Het {wth[0]} veld is gevuld en de {wth[6]} colom is leeg zijn. </li></ul>
            <ul><li style={{color: "#fa4609"}}>OFs</li></ul>
            <ul><li>Het {wth[0]} veld is leeg en de {wth[6]} colom is gevuld </li></ul>
        
        <h2>validateName </h2>
            <ul><li>Het {wth[1]} veld is gevuld </li></ul>
        
        <h2>validatePrice </h2>
            <ul><li>Het {wth[2]} veld is gevuld </li></ul>
            <ul><li style={{color: "#fa4609"}}>OF</li></ul>
            <ul><li>Het {wth[2]} veld is gevuld EN {wth[3]} is leeg EN  {wth[4]} is niet leeg EN {wth[5]} is leeg</li></ul>
        
        <h2>validatePriceUnit </h2>
            <ul><li>De column {wth[3]} is kg OF st of PP </li></ul>
            <ul><li style={{color: "#fa4609"}}>OF</li></ul>
            <ul><li>De column {wth[2]} is leeg EN {wth[3]} is leeg EN {wth[4]} is niet leeg en {wth[5]} is leeg </li></ul>
        
        <h2>validateSalesVolume </h2>
            <ul><li>De column {wth[4]} is niet leeg</li></ul>

        <h2>validateSalesUnit</h2>
            <ul><li>De column {wth[5]} is gram of st of pp</li></ul>
            <ul><li style={{color: "#fa4609"}}>OF</li></ul>
            <ul><li>De column {wth[5]} is leeg EN {wth[4]} is niet leeg en {wth[3]} is leeg EN {wth[2]} is leeg</li></ul>
        

    </div>
  )
}
