import React, { useEffect, useState } from "react";
import { Table, Icon, Dropdown } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {
  getPlayerLessButtons,
  AddButtonToCustomer,
} from "../../actions/videoInput";
import { player, playerLessbuttons } from "../../types/player";
import { fullMember } from "../../types/member";
import { selectPlayerLessButtons } from "../../actions/selectors";

interface PlayerDetailsCustomerInfoProps{
  player: player
  member: fullMember
}

export default function PlayerDetailsCustomerInfo(props:PlayerDetailsCustomerInfoProps) {
  const dispatch = useAppDispatch();
  const [buttonId, set_buttonId] = useState(undefined);
  const playerLessButtons = useAppSelector(selectPlayerLessButtons);

  useEffect(() => {
    dispatch(getPlayerLessButtons());
  }, [dispatch]);

  const connectButtonToCustomer = () => {
    if(buttonId !== undefined) {
      //@ts-ignore
      dispatch(AddButtonToCustomer(buttonId, props.member.id));
    }
  };

  const generalCell = (header:string|JSX.Element, info:string|JSX.Element, id = 0) => {
    return (
      <Table.Row key={id}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info}</Table.Cell>
      </Table.Row>
    );
  };


  const PlayerButtons = (header:string, info:playerLessbuttons[]) => {
    return info.map((b, i) => generalCell(header, b.button_id, i));
  };
  const addButton = () => {
    return generalCell(
      dropdown(),
      <div style={{ width: "60px", textAlign: "center" }}>
        <Icon name="plus circle" onClick={connectButtonToCustomer} style={{cursor: 'pointer'}} />
      </div>
    );
  };
  if (!playerLessButtons) return null;
  const options = playerLessButtons.map((button, i) => {
    return { key: i, value: button.button_id, text: `${button.button_id}` };
  });
  const dropdown = () => {
    return (
      <div>
        <Dropdown
          placeholder="Selecteer button id"
          fluid
          search
          selection
          //@ts-ignore
          onChange={(e, f) => set_buttonId(f.value)}
          options={options}
        />
      </div>
    );
  };

  if (!props.player || !props.member) return null;

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell style={{ padding: ".28571429em" }}>Nummerautomaat</Table.HeaderCell>
          {props.player.WaitingnumberHardware &&
          props.player.WaitingnumberHardware.id
            ? <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Yes</Table.HeaderCell>
            : <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>No</Table.HeaderCell>
            }
          
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props?.member?.PlayerButtons && props?.member?.PlayerButtons.length >= 1
            ? PlayerButtons("Button id", props.member.PlayerButtons)
            : null}
          {addButton()}
        </Table.Body>
      </Table>
    </div>
  );
}
