import React, { useState } from "react";
import { Dropdown, Icon, Table } from "semantic-ui-react";
import { setAppError } from "../../actions/actionCreators";
import { addPlayerSwitch, removePlayerSwitch } from "../../actions/videoInput";
import { PIPlayerCopyDays } from "../../types/livedays";
import { playerSwitch } from "../../types/player";
import { liveday } from "../../types/user";
import CustomerSelect from "./CustomerSelect";
import { useAppDispatch } from "../../hooks/redux";

interface PlayerDetailsSwitchProps{
  PlayerSwitches: playerSwitch[] | undefined
  mac: string | undefined
}

export default function PlayerDetailsSwitch(props:PlayerDetailsSwitchProps) {
  const [day, setDay] = useState<liveday>("Monday")
  const [customerId, setCustomerId] = useState<string|null>(null)
  const dispatch = useAppDispatch()
  
  const newSwitchDay = () => {
    if(day !== null && customerId !== null && props?.mac !== undefined){
      dispatch(addPlayerSwitch(props?.mac, customerId, day))
    }else{
      dispatch(setAppError("Vul een member en livedag in"))
    }
  }
  
  const removeSwitchDay = (id:number) => {
    dispatch(removePlayerSwitch(id))
  }
  const options = [
                    {text: "Monday", value: "Monday"}, 
                    {text: "Tuesday", value: "Tuesday"}, 
                    {text: "Wednesday", value: "Wednesday"}, 
                    {text: "Thursday", value: "Thursday"}, 
                    {text: "Friday", value: "Friday"}, 
                    {text: "Saturday", value: "Saturday"}, 
                    {text: "Sunday", value: "Sunday"}, 
                  ]
  const PiPlayerCopyCell = (header:string, info:string, id:number, copydays:PIPlayerCopyDays[]) => {
    let good = false
    if(copydays.filter(e => `${e.schedule}_0400` === info).length >= 1){
      good = true
    }
    return (
      <Table.Row key={id}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>
        
        {good ? <Icon name="check circle" color="green"/> : <Icon name="circle" color="red" />}

        </Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>
        <Icon name="minus circle" style={{cursor: 'pointer'}} onClick={()=>removeSwitchDay(id) } />
        </Table.Cell>
      </Table.Row>
    );
  };
  const addCell = (header:string, info:string) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" }}>    
           <CustomerSelect set_CustomerId={setCustomerId} />
        </Table.Cell>
  
        <Table.Cell style={{ padding: ".28571429em", paddingRight: "2em"  }}>
           <Dropdown
              placeholder="Selecteer een switch dag"
              fluid
              search
              selection
              //@ts-ignore
              onChange={(e, f) => setDay(f.value)}
              //@ts-ignore
              value={day}
              options={options}
          />
        </Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}></Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>
          <Icon name="plus circle" style={{cursor: 'pointer'}} onClick={newSwitchDay} />
        </Table.Cell>
      </Table.Row>
    );
  };

  const playerSwitch = (info:playerSwitch[]| undefined) => {
    if(!info) return ""
    return info.map((p) => PiPlayerCopyCell(`${p.memberId} (${p?.member?.company})`, p.SwitchSchedule, p.id, p?.member?.PIPlayerCopyDays));
  };

  return (
    <div style={{ width: "5 s0%" }}>
      <p>Let op: er zit weinig validatie achter deze knoppen, bij twijfel neem contact op met Jeroen </p>
      <p>Let op: kopieerdagen worden <b>niet</b> verwijderd bij het verwijderen van een switchdag  </p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>
              {"Naar id"}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ padding: ".28571429em" }}>
              {"Wanneer"}
             </Table.HeaderCell>
              <Table.HeaderCell style={{ padding: ".28571429em" }}>
              {"Copyday"}
             </Table.HeaderCell>
              <Table.HeaderCell style={{ padding: ".28571429em" }}>
              {""}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {playerSwitch(props.PlayerSwitches)}
          {addCell("","")}
        </Table.Body>
      </Table>
    </div>
  );
}
