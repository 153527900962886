import React from "react";
import { Table } from "semantic-ui-react";
import statusGreen from "./images/statusGreen.png";
import statusRed from "./images/statusRed.png";
import {player} from "../../types/player"
import { member, serverStatus } from "../../types/member";

interface PlayerDetailsCustomerInfoProps{
  server: serverStatus
  player:player
  member: member
}

export default function PlayerDetailsCustomerInfo(props:PlayerDetailsCustomerInfoProps) {
  if (!props.server) return null;

  const generalCell = (header:string, info:Boolean) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>
          {info ? (
            <img src={statusGreen} alt="greenDot" />
          ) : (
            <img src={statusRed} alt="redDot" />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
  return (
    <div>
      <Table celled>
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Info</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}></Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
          {generalCell(
            "Folder being played from exists in members",
            props.server.playerCorrectLink
          )}
          {generalCell(
            "Folder exits in Preview",
            props.server.previewFolderExists
          )}
          {generalCell(
            "Folder exits on FTP (surfeo)",
            props.server.playFolderExists
          )}
          {generalCell("Folder exits on X:", props.server.XFolderExists)}
          {props.player.WaitingnumberHardware?.id ? generalCell("big 1.png exits on Quenumbers:", props.server.quenumbersFolderExists) : null}
          {generalCell(
            "Logo in user dir: /logo.png",
            props.server.logoInUserDirExists
          )}
          {generalCell("Livedays are the same", props.server.livedaysSame)}
          {/* 
          // @ts-ignore */} 
          { props.member.WebPlayerCopyDays.schedule ? generalCell(
            "Web Livedays are the same",
            props.server.websiteLivedaySame
          ) : null}
          {generalCell("Category is correct:", props.server.categoryCorrect)}
        </Table.Body>
      </Table>
    </div>
  );
}
