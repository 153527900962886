import React from 'react'
import { useAppSelector } from '../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../actions/selectors';
import { firstAdminPageAfterLogin, firstPageAfterLogin } from '../constants';
import { user } from '../types/user';
import Menu from './Menu'

export default  function Top ():JSX.Element {
    const navigate = useNavigate()
    const user = useAppSelector(selectUser)
    const redirect = (a:user) => {
        if(a.role === "admin"){
          navigate(firstAdminPageAfterLogin, { replace: true });
        }else{
          navigate(firstPageAfterLogin, { replace: true });
        }
      }
    const goHome = () => {
        redirect(user)
    }


    return (
        <div className="logoBar">
            <img src="/heibaLogoShade.png" alt="logo" onClick={goHome} style={{cursor: 'pointer'}}/>
            <p>lever input aan voor je reclame</p>
            <Menu/>
        </div>
    )
}
