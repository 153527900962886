import React, {useState, useEffect} from 'react'
import CustomerSelect from '../../components/admin/CustomerSelect'
import { useAppSelector } from '../../hooks/redux';
import CompanyInfoAdmin from '../../components/admin/CompanyInfoAdmin';
import OnPreviewAdmin from '../../components/admin/OnPreviewAdmin';
import { selectrMembers } from '../../actions/selectors';
import { basicAccountmanager } from '../../types/acountmanager';
import { member } from '../../types/member';

export default function Office() {
    const [customerId, set_CustomerId] = useState(0);
    const [selectedAccountmanager, set_Accountmanager] = useState<basicAccountmanager | undefined>(undefined);
    const [selectedMember, set_selectedMember] = useState<undefined | member>(undefined);
    const members = useAppSelector(selectrMembers);
    useEffect(()=> {

        let filter = members?.reduce( (acc:member, curr) =>{
            if(curr?.id === customerId ){
                return curr
            }
            return acc
            //@ts-ignore
        }, []) 
        set_selectedMember(filter)
    },[customerId, members])
    
    return (
        <div>
            
            <CompanyInfoAdmin member={selectedMember} accountmanager={selectedAccountmanager} />
            <CustomerSelect set_CustomerId={set_CustomerId}/>
            <hr/>
            <OnPreviewAdmin member={selectedMember} accountmanager={selectedAccountmanager} set_Accountmanager={set_Accountmanager} set_CustomerId={set_CustomerId}/>
        </div>
    )
}
