import React, { useState, useEffect } from "react";
import superagent from "superagent";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { useNavigate, useParams } from "react-router-dom";
import { firstPageAfterLogin, backendBaseUrl } from "../constants";
import AlertMessage from "../components/AlertMessage";
import { setLogin } from "../actions/actionCreators";
import logo from '../components/heibalogo2019.svg'
import { selectUser } from "../actions/selectors";
import { Box, Button, CardMedia, Container, CssBaseline, TextField, Typography } from "@mui/material";


export const UserRole = () => {
  const user = useAppSelector(selectUser);
  return user.role;
};

type forgetParams = {
  token: string
  email: string
}
export default function Forgot() {
  const [email, emailHandler] = useState("");
  const [password, passwordHandler] = useState("");
  const [passwordConfirm, passwordConfirmHandler] = useState("");
  const [token, set_token] = useState("");
  const [messageId, setMessageId] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const params = useParams<forgetParams>()
  
  useEffect(() => {
    if(params.email) emailHandler(params.email)
    if(params.token) set_token(params.token)
  }, [params.email, params.token]);


  const submitHandler = (event: any) => {
    if(email.length >= 5 ){
      setMessageId(2)
    }else{
      setMessageId(11)
      return null
    }
    event.preventDefault();
    if(token.length > 2){

      if(password !== passwordConfirm){
        setMessageId(12)
        return
      }
      if(password.length < 8){
        setMessageId(13)
        return 
      }
      //this is the call to rest the passwords
      superagent
      .post(`${backendBaseUrl}/resetPassword`)
      .send({ username: email, password, passwordConfirm, token  })
      .then(result => {
        dispatch(setLogin(result.body));
        if(result.body && result.body.jwt){
          navigate(firstPageAfterLogin, { replace: true });
        }
        return 
      })
      .catch(err => {
        if (err.response && err.response.body && err.response.body.id) {
          setMessageId(err.response.body.id);
        } else {
          setMessageId(10);
        }
      });
      return
    }
    //this is the call to send email..
    superagent
      .post(`${backendBaseUrl}/sendPassword`)
      .send({ username: email  })
      .catch(err => {
        if (err.response && err.response.body && err.response.body.id) {
          setMessageId(err.response.body.id);
        } else {
          setMessageId(10);
        }
      });
  };
  return (
    <Container component="main" maxWidth="xs" style={{backgroundColor: "#F5E1CF",
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px"}}
    >
      <CssBaseline />
      <div style={{ display: "flex",
                    flexDirection: "column",
                    alignItems: "center"}}
      >
        <CardMedia
        component="img"
        style={{  marginTop: "20px"}}
        image={logo}
        title="Heibaservice logo"
        />
         
        <Typography component="h1" variant="h5">
          Wachtwoord reset
        </Typography>
        <form style={{width: "100%"}}
        noValidate onSubmit={submitHandler}>
          <TextField
            variant="outlined"
            onChange={(event:any) => {
              emailHandler(event.target.value);
              setMessageId(0);
            }}
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mailadres"
            name="email"
            autoComplete="email"
            autoFocus
            style ={{backgroundColor: "#f5fafe"}}
          />
          
            {token ? <TextField
            variant="outlined"
            onChange={ (event : any)  => {
              passwordHandler(event.target.value);
              setMessageId(0);
            }}
            value={password}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Wachtwoord"
            type="password"
            id="password"
            style ={{backgroundColor: "#f5fafe"}}
          /> : null}
            {token ? <TextField
            variant="outlined"
            onChange={ (event : any) => {
              passwordConfirmHandler(event.target.value);
              setMessageId(0);
            }}
            value={passwordConfirm}
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Herhaal wachtwoord"
            type="password"
            id="passwordConfirm"
            style ={{backgroundColor: "#f5fafe"}}
          /> : null}
         
          <AlertMessage messageId={messageId} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{backgroundColor: "#5dc2d7", marginBottom: "20px", marginTop: "20px"}}
          >
            Verstuur
          </Button>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
