import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import AdminCellHealth from "./AdminCellHealth";
import AdminCellLastSeen from "./AdminCellLastSeen";
import AdminCellCompany from "./AdminCellCompany";
import AdminCellId from "./AdminCellId";
import LoadingIndicator from '../LoadingIndicator'
import AdminCellConnect from "./AdminCellConnect";
import { player } from "../../types/player";

interface PlayersTableProps{
  players: player[] | undefined
  connect?: boolean
  search?: string
}

export default function PlayersTable(props:PlayersTableProps) {
  const [sortBy, set_sortBy] = useState('name')
  const [direction, set_direction] = useState<'ascending' | 'descending' >('descending')
  

  if ( !props?.connect  && props.players && props.players.length <= 1) return <LoadingIndicator loadingText="Players laden" />
  if( props?.connect && props?.players?.length === 0 ) return null
  
  const changeSortDirection = (type:string) => {
    if(type === sortBy){
      set_direction(direction === 'ascending' ? 'descending' : 'ascending')
    }else{
      set_sortBy(type)
    }
  }

  const sortByName = (a:player,b:player) =>{
    if(a?.piplayerHealth?.member?.company < b?.piplayerHealth?.member?.company) { return (direction === 'descending' ? -1 : 1); }
      if(a?.piplayerHealth?.member?.company>  b?.piplayerHealth?.member?.company) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  const sortByLastSeen = (a:player,b:player) =>{
    if(a?.LastSeenDay <  b?.LastSeenDay) { return (direction === 'descending' ? -1 : 1); }
      if(a?.LastSeenDay >  b?.LastSeenDay) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  const sortById = (a:player,b:player) =>{
    if(parseInt(a?.piplayerHealth.id) < parseInt(b?.piplayerHealth.id)) { return (direction === 'descending' ? -1 : 1); }
      if(parseInt(a?.piplayerHealth.id) >  parseInt(b?.piplayerHealth.id)) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  const sortByHealth = (a:player,b:player) =>{
    if(a?.piplayerHealth?.health < b?.piplayerHealth?.health) { return (direction === 'descending' ? -1 : 1); }
      if(a?.piplayerHealth?.health>  b?.piplayerHealth?.health) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  if(!props.players) return <LoadingIndicator loadingText="Players laden"/>
  const sorted = [...props.players].sort( sortBy === "name" ? sortByName :  sortBy === "seen" ? sortByLastSeen :  sortBy === "id" ? sortById: sortByHealth)


  const filtered =  sorted.filter(e =>{
    if(props?.search){
      var re = new RegExp(props?.search,"i");
      if(e?.piplayerHealth?.member?.company?.match(re)){
        return true
      }
      if(e?.piplayerHealth?.id?.match(re)){
        return true
      }
      if(e?.piplayerHealth?.mac?.match(re)){
        return true
      }
      if(e?.piplayerHealth?.member?.basenetshop?.Woonplaats_vestigingsadres?.match(re)){
        return true
      }
      return false
    }else{
      return true
    }
  })

  return (
    <div>
      <Table celled sortable unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
             sorted={'id' === sortBy ? direction : undefined}
             onClick={ () => changeSortDirection("id") }
             style={{cursor: 'pointer'}}
            >id</Table.HeaderCell>
            <Table.HeaderCell sorted={'name' === sortBy ? direction : undefined}
              onClick={ () => changeSortDirection("name") }
              style={{cursor: 'pointer'}}
              >{ !props.connect ? "Company" : "select een company"} </Table.HeaderCell>
            { props.connect ? <Table.HeaderCell>Save</Table.HeaderCell> : null}
            { props.connect ? <Table.HeaderCell>Remove</Table.HeaderCell> : null}
            <Table.HeaderCell 
             sorted={'health' === sortBy ? direction : undefined}
             onClick={ () => changeSortDirection("health") }
             style={{cursor: 'pointer'}}
            >Status</Table.HeaderCell>
            <Table.HeaderCell 
            sorted={'seen' === sortBy ? direction : undefined}
            onClick={ () => changeSortDirection("seen") }
            style={{cursor: 'pointer'}}
            >Seen</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filtered.map((player, i) => {
            if (!player.piplayerHealth) return null;
            if(player.PiplayerExtraInterface) return null;
            if(props.connect) return <AdminCellConnect player={player} key={i}/>
            return (
                <Table.Row key={i}>
                <Table.Cell>
                  { !props.connect ? <AdminCellId player={player}  />: null }
                </Table.Cell>
                <Table.Cell>
                  { !props.connect ? <AdminCellCompany player={player}/> : null}
                </Table.Cell>
                <Table.Cell style={{ textAlign: "center" }}>
                  <AdminCellHealth player={player} />{" "}
                </Table.Cell>
                <Table.Cell style={{ textAlign: "center" }}>
                  <AdminCellLastSeen player={player} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
