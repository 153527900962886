import React, { useState } from "react";
// import SelectWeek from "../../components/SelectWeek";
import { Input, Label } from "semantic-ui-react";
import { addFestiveDay } from "../../actions/videoInput";
import { useAppDispatch } from "../../hooks/redux";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function FestiveAdd(): JSX.Element {
  const [date, onChange] = useState<Value>(new Date());
  const [festivedayName, set_festivedayName] = useState("");
  const dispatch = useAppDispatch();
  const addNewFestiveDay = () => {
    dispatch(addFestiveDay(date, festivedayName));
  };
  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];

  return (
    <div>

  <div style={{backgroundColor: "white"}}>
    <DatePicker 
      onChange={onChange} 
      value={date} 
      clearIcon={null}/>
  </div>
      <form onSubmit={addNewFestiveDay}>
        <Label>Waarom zou onze klant aandacht aan deze dag besteden?</Label>
        <Input
          placeholder="Naam feestdag"
          onChange={(e) => set_festivedayName(e.target.value)}
          value={festivedayName}
        />
        <button className="adminButton" type="submit">
          Toevoegen aan Kalender
        </button>
      </form>

      {/* <SelectWeek admin={set_date} /> */}
    </div>
  );
}
