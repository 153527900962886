import {Dispatch} from 'redux';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { backendBaseUrl } from "../constants";
import { constructionMediaType, LibaryMediaType } from '../types/constructionMedia';
import { liveday } from '../types/user';
import {
  setWeekSelect,
  setVideoInput,
  setVideoInputById,
  setMembers,
  setPreview,
  setHeibaWeeks,
  setMe,
  setProductVideo,
  setPlayers,
  setNightlyPlayers,
  setAmbianceVideo,
  setPlayerDetails,
  setCustomerDetails,
  setPlayerHistory,
  setPlayerServer,
  setPlayerSsmInfo,
  setYearInput,
  setAccountmanagers,
  setLivedays,
  setFestiveDays,
  setConnectPlayers,
  setOldPlayers,
  setVideoStats,
  setPlayerLessButtons,
  setGeneralLivedays,
  setMalfunctions,
  setMediaLibaryMedia,
  setUploadedFiles,
  setDashboardCount,
  setDaySummary,
  setTodolist,
  setInstallationMedia,
  setPlayerSwitch,
  setCurrentlyOnServer,
  setLoginLike,
  setFtpMembers,
  setPlayerDownloads,
  setServerLogRecords,
  setSummaryGraph,
  setWebshopProducts,
  setInPlayerFolders,
  setMemberLocations,
  setPlayerLocations,
  setPasswordReset,
  setBasenetshop,
  setMemberPiPlayerCopydays,
  setPlayerStorageInfo,
  setConstructionItem,
  setMediaTags,
  setTaskList,
  setBaseContent,
  setManagingMembers,
  setSubscriptions,
  setSubscriptionOptions
} from './actionCreators'
import { generalApiCall } from "./apiCalls";
import { todoListOwner } from '../types/todo';


export function getWeekSelect() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setWeekSelect,
      `${backendBaseUrl}/weeks`
    );
  };
}


export function getVideoInput(id?:number)
 {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setVideoInput,
      `${backendBaseUrl}/videoInput/${id}`,
      { id}
    );
  };
}
export function InputStats() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setVideoStats,
      `${backendBaseUrl}/videoInputStats`
    );
  };
}
export function getYearInput(id:number, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setYearInput,
      `${backendBaseUrl}/videoYearInput/${id}/${year}/`
    );
  };
}
export function getFilesOnServer(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setCurrentlyOnServer,
      `${backendBaseUrl}/storage/${id}`,
      { id}
    );
  };
}
export function getVideoInputById(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setVideoInputById,
      `${backendBaseUrl}/videoInputById/${id}`,
      { id}
    );
  };
}
export function getVideoInputAccountManager(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setVideoInput,
      `${backendBaseUrl}/videoInputAccountmanager/${id}`,
      { id}
    );
  };
}
export function updateWeekSelect(id:number, comments:string|null, status?:string, member?:number|null, accountmanager?:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",            
      getState,
      dispatch,
      setVideoInput,
      `${backendBaseUrl}/videoInput/${id}`,
      { id, comments, status, member, accountmanager}
    );
  };
}
export function getConstructionItem(id:number, category:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setConstructionItem,
      `${backendBaseUrl}/constructionItem/${id}/${category}`,
    );
  };
}
export function addTagToConstructionItem(id:string, tagId:number, mediaType:constructionMediaType) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",            
      getState,
      dispatch,
      setConstructionItem,
      `${backendBaseUrl}/tagconstructionitem`,
      { id, tagId, mediaType}
    );
  };
}
export function removeTagFromConstructionItem(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",            
      getState,
      dispatch,
      setConstructionItem,
      `${backendBaseUrl}/tagconstructionitem`,
      { id}
    );
  };
}
export function getMediaTags() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setMediaTags,
      `${backendBaseUrl}/mediaTagsType`
    );
  };
}

export function addMediaTag(name:string, description:string, contentRightsLockTag:boolean, color:SemanticCOLORS) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",            
      getState,
      dispatch,
      setMediaTags,
      `${backendBaseUrl}/mediaTagsType`,
      {name, description, contentRightsLockTag, color}
    );
  };
}
export function updateConstructionItem(id:number, description:string, category:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",            
      getState,
      dispatch,
      setVideoInput,
      `${backendBaseUrl}/constructionItem/${id}`,
      { id, description, category}
    );
  };
}
export function deleteConstructionItem(id:number, type:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",            
      getState,
      dispatch,
      setMediaLibaryMedia,
      `${backendBaseUrl}/constructionItem/${id}`,
      { id, type }
    );
  };
}
export function getMembers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setMembers,
      `${backendBaseUrl}/members`,
    );
  };
}
export function getMemberLocation() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setMemberLocations,
      `${backendBaseUrl}/memberLocations`
    );
  };
}
export function getMemberStoreLocation(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setMemberLocations,
      `${backendBaseUrl}/memberLocations/${id}`
    );
  };
}
export function getInPlayerFolders() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setInPlayerFolders,
      `${backendBaseUrl}/storage`,
    );
  };
}
export function getPreviews() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setPreview,
      `${backendBaseUrl}/preview`,
    
    );
  };
}
export function getInstallationMedia(memberId:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setInstallationMedia,
      `${backendBaseUrl}/installationMedia/${memberId}`,
    
    );
  };
}
export function updateInstallationMedia(photoId:number, text: string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",            
      getState,
      dispatch,
      setInstallationMedia,
      `${backendBaseUrl}/installationMedia/${photoId}`,
      {text}

    );
  };
}
export function addPlayerSwitch(MAC: string, memberId:string, SwitchSchedule: liveday) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",            
      getState,
      dispatch,
      setPlayerSwitch,
      `${backendBaseUrl}/playerSwitch`,
      {MAC, memberId , SwitchSchedule}
    
    );
  };
}
export function removePlayerSwitch(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",            
      getState,
      dispatch,
      setPlayerSwitch,
      `${backendBaseUrl}/playerSwitch`,
      {id}
    
    );
  };
}
export function getPlayerSwitch(mac:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",            
      getState,
      dispatch,
      setPlayerSwitch,
      `${backendBaseUrl}/playerSwitch/${mac}`,
    
    );
  };
}
export function SelectAWeek(weekNumber:number, year:number, pf:string, ss:string, hs:string, ei:string, nf:string, cm01:string, cm02:string, status:string, typeOfInput:string) {
  return (dispatch: Dispatch, getState: any) => {
    const type = (!typeOfInput) ? getState().videoInput.type : typeOfInput
    generalApiCall(
      "post",
      getState,
      dispatch,
      setWeekSelect,
      `${backendBaseUrl}/week`,
      { weekNumber, year, pf, ss, hs, ei, nf, cm01, cm02,status, type }
    );
  };
}
export function SelectAWeekQuickSave(weekNumber:number, year:number, pf:string, ss:string, hs:string, ei:string, nf:string, cm01:string, cm02:string, status:string, typeOfInput:string) {
  return (dispatch: Dispatch, getState: any) => {
    const type = (!typeOfInput) ? getState().videoInput.type : typeOfInput
    generalApiCall(
      "post",
      getState,
      dispatch,
      null,
      `${backendBaseUrl}/week`,
      { weekNumber, year, pf, ss, hs, ei, nf, cm01, cm02,status, type }
    );
  };
}
export function previewComments(videoId:number, accepted:string, rework:string, comment:string, accountmanagerComment?:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setPreview,
      `${backendBaseUrl}/preview`,
      { videoId, accepted, rework, comment, accountmanagerComment }
    );
  };
}
export function getProductMedia(weekNumber:number, year:number, category:string, admin?:boolean) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setProductVideo,
      `${backendBaseUrl}/productVideo?year=${year}&weekNumber=${weekNumber}&category=${category}${admin ? `&admin=${admin}` : "" }`
    );
  };
}
//below: type should be image photo video? check 
export function removeProductMedia(id:number, weekNumber:number, year:number, category:string, type:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",
      getState,
      dispatch,
      setProductVideo,
      `${backendBaseUrl}/productMedia?year=${year}&weekNumber=${weekNumber}&category=${category}&id=${id}&type=${type}`
    );
  };
}
export function getHeibaWeeks(liveday:string, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setHeibaWeeks,
      `${backendBaseUrl}/heibaweeks/${liveday ? liveday: "Monday"}/year/${year}`
    );
  };
}
export function getMalfunctions( year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setMalfunctions,
      `${backendBaseUrl}/malfunctions/${year}`
    );
  };
}
export function addMalfunction(data:any, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setMalfunctions,
      `${backendBaseUrl}/malfunctions/${year}`,
      {data, year}
    );
  };
}
export function addFtpMember( data:any ) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setFtpMembers,
      `${backendBaseUrl}/ftpmembers`,
      {data}
    );
  };
}
export function solveMalfunction( malfunction:any ,year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",
      getState,
      dispatch,
      setMalfunctions,
      `${backendBaseUrl}/malfunctions/${year}`,
      {malfunction,year}
    );
  };
}

export function getAmbianceVideo(weekNumber:number, year:number, category:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setAmbianceVideo,
      `${backendBaseUrl}/productVideo?year=${year}&weekNumber=${weekNumber}&category=${category}`
    );
  };
}
export function getPlayers(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayers,
      `${backendBaseUrl}/players/${id}`
    );
  };
}
export function getBasenetshop(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setBasenetshop,
      `${backendBaseUrl}/memberBasenetShop/${id}`
    );
  };
}
export function getMemberCopydays(memberId:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setMemberPiPlayerCopydays,
      `${backendBaseUrl}/membercopydays/${memberId}`
    );
  };
}
export function getPlayerLessButtons() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerLessButtons,
      `${backendBaseUrl}/buttons`
    );
  };
}
export function getPlayerStorageVideoInfo(id: string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerStorageInfo,
      `${backendBaseUrl}/getplayerstoragevideoinfo/${id}`
    );
  };
}
export function AddButtonToCustomer(button_id:string, memberId:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setCustomerDetails,  // is this correct?
      `${backendBaseUrl}/buttons`,
      {button_id, memberId}
    );
  };
}
export function addCopyday(copydayType:"website"|"banner", memberId:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setMemberPiPlayerCopydays,  
      `${backendBaseUrl}/membercopydays`,
      {copydayType, memberId}
    );
  };
}
export function getLivedays(week: number, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setLivedays,
      `${backendBaseUrl}/liveday/${week}/${year}/`
    );
  };
}
export function getLivedaysWeb(week:number ,year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setLivedays,
      `${backendBaseUrl}/livedayweb/${week}/${year}/`
    );
  };
}
export function addIgnoreDay(memberId:number, schedule:string,week:number, year:number, copy:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setLivedays,
      `${backendBaseUrl}/livedayignore`,
      {memberId,schedule, week, year, copy}
    );
  };
}
export function addIgnoreDayWeb(memberId:number, schedule:string ,week:number, year:number, copy:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setLivedays,
      `${backendBaseUrl}/livedayignoreweb`,
      {memberId,schedule, week, year, copy}
    );
  };
}
export function addManagingMember(memberId:number, managedMember: number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setManagingMembers,
      `${backendBaseUrl}/managingMembers`,
      {memberId,managedMember}
    );
  };
}
export function getManagingMember(memberId:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setManagingMembers,
      `${backendBaseUrl}/managingMembers/${memberId}`
    );
  };
}
export function getSubscriptions(memberId:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setSubscriptions,
      `${backendBaseUrl}/subscription/${memberId}`
    );
  };
}
export function getSubscriptionOptions() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setSubscriptionOptions,
      `${backendBaseUrl}/subscriptionOptions`
    );
  };
}
export function addSubscriptionToMember(memberId: number, heibaSubscriptionId: number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setSubscriptions,
      `${backendBaseUrl}/subscription`,
      {memberId, heibaSubscriptionId}
    );
  };
}
export function addGeneralLiveDay(schedule:number ,week:number, year:number, copy:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setGeneralLivedays,
      `${backendBaseUrl}/livedaygeneral`,
      {schedule, week, year, copy}
    );
  };
}
export function addGeneralLiveDayWeb(schedule:string,week:number, year:number, copy:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setGeneralLivedays,
      `${backendBaseUrl}/livedaygeneralweb`,
      {schedule, week, year, copy}
    );
  };
}
export function getGeneralLiveDay(week:number, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setGeneralLivedays,
      `${backendBaseUrl}/livedaygeneral/${week}/${year}`
    );
  };
}
export function getGeneralLiveDayWeb(week:number, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setGeneralLivedays,
      `${backendBaseUrl}/livedaygeneralWeb/${week}/${year}`
    );
  };
}
export function addFestiveDay(date:any, name:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setFestiveDays,
      `${backendBaseUrl}/festiveDay`,
      {date, name }
    );
  };
}
export function getAllPlayers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayers,
      `${backendBaseUrl}/players`
    );
  };
}
export function getNightlyPlayers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setNightlyPlayers,
      `${backendBaseUrl}/nightlyplayers`
    );
  };
}
export function getNewPlayers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setConnectPlayers,
      `${backendBaseUrl}/newplayers`
    );
  };
}
export function getOldPlayers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setOldPlayers,
      `${backendBaseUrl}/oldplayers`
    );
  };
}
export function getAllAccountmanagers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setAccountmanagers,
      `${backendBaseUrl}/accountmanagers`
    );
  };
}
export function getCustomerInfo(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setCustomerDetails,
      `${backendBaseUrl}/member/${id}`
    );
  };
}

export function getPlayerInfo(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerDetails,
      `${backendBaseUrl}/playerinfo/${id}`
    );
  };
}
// export function getLivePi(date:any) {
//   return (dispatch: Dispatch, getState: any) => {
//     generalApiCall(
//       "get",
//       getState,
//       dispatch,
//       setLivePi,
//       `${backendBaseUrl}/livedaypiplayer/${date}`
//     );
//   };
// }
export function getPlayerServer(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerServer,
      `${backendBaseUrl}/memberStatus/${id}`
    );
  };
}
export function getPlayerSsmInfo(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerSsmInfo,
      `${backendBaseUrl}/player/ssmState/${id}`
    );
  };
}
export function unlinkPlayer(mac:string, memberId:number, cb?:Function) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",
      getState,
      dispatch,
      setPlayers,
      `${backendBaseUrl}/player`,
      {mac, memberId},
      null,
      null,
      cb
    );
  };
}
export function linkPlayer(mac:string, memberId:number, cb?:Function) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setPlayers,
      `${backendBaseUrl}/player`,
      {mac, memberId},
      null,
      null,
      cb
    );
  };
}
export function deletePlayer(mac:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",
      getState,
      dispatch,
      setConnectPlayers,
      `${backendBaseUrl}/player`,
      {mac}
    );
  };
}
export function getFestiveDays(month:any,year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setFestiveDays,
      `${backendBaseUrl}/FestiveDays/${month}/${year}`
    );
  };
}
export function getPlayerHistory(id:string, limit:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerHistory,
      `${backendBaseUrl}/playerHistory/${id}`,
      undefined,
      undefined,
      { limit }
    );
  };
}
export function getPlayerLocations(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerLocations,
      `${backendBaseUrl}/playerLocation/${id}`
    );
  };
}
export function getPlayerDownloads(mac:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setPlayerDownloads,
      `${backendBaseUrl}/playersdownload/${mac}`
    );
  };
}
export function getServerLogRecords(id:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setServerLogRecords,
      `${backendBaseUrl}/ftplog/${id}`
    );
  };
}
export function getDashboardCount(type:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setDashboardCount,
      `${backendBaseUrl}/libaryCount/${type}`
    );
  };
}
export function getTodolist() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setTodolist,
      `${backendBaseUrl}/todo`
    );
  };
}
export function addTodo(todo:string, owner:todoListOwner) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setTodolist,
      `${backendBaseUrl}/todo`,
      {todo, owner}
    );
  };
}
export function getDaySummary(year:number, month:number, day:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setDaySummary,
      `${backendBaseUrl}/daySummary/${year}/${month}/${day}/`
    );
  };
}
export function UpdateLibary(type:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "patch",
      getState,
      dispatch,
      setDashboardCount,
      `${backendBaseUrl}/libaryCount/${type}`
    );
  };
}
export function addVideoToWeek(week:number, year:number, category:string, constructionVideoId:any, step:any, mediaType:constructionMediaType) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setProductVideo,
      `${backendBaseUrl}/productMediaUsage`,
      { week, year, category, constructionVideoId, step, mediaType }
    );
  };
}
export function getConstructionMedia(category:LibaryMediaType|undefined, mediaType:constructionMediaType|undefined) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setMediaLibaryMedia,
      `${backendBaseUrl}/productMedia/${category}`,
      { mediaType }
    );
  };
}
export function getFtpMembers() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setFtpMembers,
      `${backendBaseUrl}/ftpmembers`,
    );
  };
}
export function getSummaryGraph(days:number, weekday:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setSummaryGraph,
      `${backendBaseUrl}/summaryGraph/${days}/${weekday}`,
    );
  };
}
export function getMyWebshopProducts(memberId:string) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setWebshopProducts,
      `${backendBaseUrl}/webshopProducts/${memberId}`,
    );
  };
}
export function getTaskList() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setTaskList,
      `${backendBaseUrl}/tasks`,
    );
  };
}
export function addTaskToQueue(baseContentId: number ) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setTaskList,
      `${backendBaseUrl}/tasks`,
      {baseContentId}
    );
  };
}
export function createWpProduct(id:string|number, memberId:string|number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setWebshopProducts,
      `${backendBaseUrl}/webshopProducts`,
      {id, memberId}
    );
  };
}
export function getBaseContent() {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setBaseContent,
      `${backendBaseUrl}/baseContent`,
      {}
    );
  };
}
export function getBaseContentId(id: number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setWebshopProducts,
      `${backendBaseUrl}/baseContent`,
      {}
    );
  };
}
export function deleteWpProduct(id:string|number, memberId:string|number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",
      getState,
      dispatch,
      setWebshopProducts,
      `${backendBaseUrl}/webshopProducts`,
      {id, memberId}
    );
  };
}
export function removeVideoFromWeek(id:string, mediaType:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "delete",
      getState,
      dispatch,
      setProductVideo,
      `${backendBaseUrl}/productMediaUsage`,
      { id ,mediaType}
    );
  };
}
export function changePositionInWeek(placeId:number, gotoPosition:number, mediaType:constructionMediaType) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "put",
      getState,
      dispatch,
      setProductVideo,
      `${backendBaseUrl}/productMediaUsage`,
      { placeId, gotoPosition, mediaType}
    );
  };
}
export function loginLike(id:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "get",
      getState,
      dispatch,
      setLoginLike,
      `${backendBaseUrl}/loginLike/${id}`
    );
  };
}
export function passwordReset( password:string, confirmPassword:string ) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setPasswordReset,
      `${backendBaseUrl}/selfpasswordreset`,
      { password, confirmPassword}
    );
  };
}
export function uploadConstruction(file:any, type:any) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setUploadedFiles,
      `${backendBaseUrl}/uploadConstruction`,
      { type},
      file
    );
  };
}
export function uploadInstallation(file:any, memberId:number, cb?:Function) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setUploadedFiles,
      `${backendBaseUrl}/uploadInstallation`,
      { memberId},
      file,
      null,
      cb
    );
  };
}
export function uploadPreview(file:any, id:number, week:number, year:number) {
  return (dispatch: Dispatch, getState: any) => {
    generalApiCall(
      "post",
      getState,
      dispatch,
      setUploadedFiles,
      `${backendBaseUrl}/uploadPreview`,
      { id, week, year},
      file
    );
  };
}


export function me() {
  return (dispatch: Dispatch, getState: any) => {
    let heibaToken = localStorage.getItem("heibaToken");
    if (heibaToken && heibaToken.length > 15) {
      generalApiCall(
        "get",
        getState,
        dispatch,
        setMe,
        `${backendBaseUrl}/me`
      );
    }
  };
}
export function reAuth(setter:any) {
  return (dispatch: Dispatch, getState: any) => {
    let heibaToken = localStorage.getItem("heibaToken");
    if (heibaToken && heibaToken.length > 15) {
      generalApiCall(
        "get",
        getState,
        dispatch,
        setter,
        `${backendBaseUrl}/me`
      );
    }
  };
}
