import React, { useState } from "react";

import DropzoneWrapper from '../components/DropzoneWrapper'
import InputNavigationFeed from "../components/InputNavigationFeed";
import { selectVideoInput } from "../actions/selectors";
import { useAppSelector } from "../hooks/redux";

export default function InputUpload(){
 

    const [lock , setLock] = useState(false)
    const [warning , setWarning] = useState(false) 
    const [wait , setWait] = useState(false)  
    const videoInput = useAppSelector(selectVideoInput);
    let stepNumber = "stap 2 van 3"
    return (
      <div className="productFocus">
        <div className="steps">
          <div>
            <h3 className="stepName">Bestanden Uploaden</h3>
            <p className="stepExplanation">
              U kunt de volgende bestandstypen uploaden:
              .jpg / .png / .tif / .gif / .pdf / .heic
            </p>
          </div>
          <div className="stepNumber">
           <h3 className="stepNumber">{stepNumber}</h3>
            <h4>voor week {videoInput?.weekNumber || 0}</h4>
          </div>
        </div>
      
        <DropzoneWrapper setLock={setLock} setWarning={setWarning} setWait={setWait}/>
        <InputNavigationFeed
          back="/inputfeed"
          to="/summary"
          prevStep={1}
          nextStep={3}
          lock={lock}
          uploadWarning={warning}
          uploadWait={wait}
          noContent={ videoInput?.uploadedFiles && videoInput?.uploadedFiles?.length <= 0 }
          
        />
      </div>
    );
  }

