import React, { useState } from "react";
import { Icon } from "semantic-ui-react";

interface generalMessageProps {
  text: string | null
}

export default function GeneralMessage({text}: generalMessageProps) {
  const [hide, set_hide] = useState(false)
  const clickHandler = () => {
    set_hide(true)
  }
  if(!text) return null
  if(hide) return null
  if(text.length > 120) return null
  return (
    <div className="GeneralMessageBar">
      <div className="GeneralMessageFrame">
        <p className="GeneralMessageText">{text}</p>
        <Icon name="close" circular inverted onClick={clickHandler} style={{cursor: 'pointer'}}/>
      </div>
    </div>
  );
}
