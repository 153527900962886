import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function InputTypeSelector() {
  const navigate = useNavigate()
  const redirect = () => {
    navigate("/inputfeed")
  }

  return (
    <div className='inputTypeSelector'>
      <p className='inputTypeSelectorInstruction'>Input aanleveren voor:</p>
    <div className='inputTypeSelectorTopRow'>
      <div className='inputTypeSelectorTopLeft' onClick={redirect}>
        <p className='inputTypeSelectorText'>shopmovie</p>
      </div>
      <div className='inputTypeSelectorTopRight' onClick={redirect}>
        <p className='inputTypeSelectorText'>facebook</p>
      </div>
    </div>
    <div className='inputTypeSelectorCenterRow'>
      <div className='inputTypeSelectorCenter'>
        <p>Aanleveren voor week</p>
      </div>
    </div>
    </div>
  )
}
