import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Dropdown } from "semantic-ui-react";
import { selectMediaLibaryPhoto } from "../../actions/selectors";
import { addVideoToWeek, getConstructionMedia, getProductMedia } from "../../actions/videoInput";
import { constructionMediaType } from "../../types/constructionMedia";
import { memberCategory } from "../../types/member";


interface PlacePhotoProps{
  type: constructionMediaType
  week: number
  year: number
  step: number
  complete: boolean
  category: memberCategory | "image" | "sfeer"
}

export default function PlacePhoto(props:PlacePhotoProps) {
  const dispatch = useAppDispatch();
  const [selectedVideo, set_selectedVideo] = useState(0);
  const mediaLibaryPhoto = useAppSelector( selectMediaLibaryPhoto)
  
  useEffect(() => {
    dispatch(getConstructionMedia( props.category, props.type));
    dispatch(getProductMedia(props.week, props.year, props.type, true));
  }, [props.type, dispatch,  props.week, props.year, props.category]);

  const submitHandler = (e:FormEvent) => {
    e.preventDefault();
    if (selectedVideo !== 0) {
      dispatch(
        addVideoToWeek(
          props.week,
          props.year,
          props.type,
          selectedVideo,
          props.step,
          "image"
        )
      );
    }
  };

  const options = mediaLibaryPhoto?.map((media) => { return {key: media.id , value: media.id, text: `${media.description}(${media.name})`} })

  if (props.complete) return null;
  return (
    <div>
      <p>
        Voeg een {props.type === "image" ? "image" : "video"} toe aan week :{" "}
        {props.week}
      </p>
      <form onSubmit={submitHandler}>
      <Dropdown
          placeholder={`Voeg een ${props.type === "image" ? "image" : "video"} toe aan week ${props.week}`}
          fluid
          search
          selection
          onChange={(e, f) => set_selectedVideo(Number(f.value))} 
          options={options}
        />
        <button className="adminButton" type="submit">
          voeg toe!
        </button>
      </form>
    </div>
  );
}
