import React, { useState, useEffect, FormEvent } from "react";
import { Checkbox } from "semantic-ui-react";
import { extraOrIgnoreCopyDay, generalLiveday, livedays, PIPlayerCopyDays, WebPlayerCopyDays } from "../../types/livedays";
import { liveday } from "../../types/user";
import { useAppDispatch } from "../../hooks/redux";

interface WeekdayTogggleProps{
  ignores?:  extraOrIgnoreCopyDay[]
  schedule?: PIPlayerCopyDays[] | WebPlayerCopyDays[] | undefined | liveday
  extras?: extraOrIgnoreCopyDay[] | undefined
  day: liveday
  year: number
  week: number
  generalLiveDay?:generalLiveday[]
  generalDay?: Boolean
  member?: livedays
  addIgnoreDay?: Function
  addGeneralLiveDay?: Function
}

export default function WeekdayTogggle(props:WeekdayTogggleProps) {
  const [active, set_active] = useState(false);
  const [error, set_error] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (props.ignores && props.schedule && props.extras) {
      let pleaseIgnore = null;
      let pleaseExtra = null;
      let pleaseLiveday = null;
      let pleaseGeneral = null;
      //@ts-ignore TDOO no clue how this fix this ts error
      let filter = props.schedule.reduce((acc, curr) => {
        if (curr.schedule === props.day) {
          return curr;
        }
        return acc;
      }, []);
      if (filter.id) {
        pleaseLiveday = true;
      }

      const matchingIgnores = props.ignores.filter((i) => {
        if (
          i.schedule === props.day &&
          i.year === props.year &&
          i.week === props.week
        ) {
          return i;
        } else {
          return null;
        }
      });
      if (matchingIgnores.length >= 1) {
        pleaseIgnore = true;
      }
      const matchingExtras = props.extras.filter((i) => {
        if (
          i.schedule === props.day &&
          i.year === props.year &&
          i.week === props.week
        ) {
          return i;
        } else {
          return null;
        }
      });

      if (matchingExtras.length >= 1) {
        pleaseExtra = true;
      }

      if (props.generalLiveDay && props.generalLiveDay.length >= 1) {
        const matchingGeneralLiveday = props.generalLiveDay.filter((i) => {
          if (
            i.schedule === props.day &&
            i.year === props.year &&
            i.week === props.week
          ) {
            return i;
          } else {
            return null;
          }
        });

        if (matchingGeneralLiveday.length >= 1) {
          pleaseGeneral = true;
        } else {
          pleaseGeneral = false;
        }
      }

      if ((pleaseExtra || pleaseLiveday || pleaseGeneral) && !pleaseIgnore) {
        set_active(true);
      } else if ((!pleaseExtra || !pleaseLiveday) && pleaseIgnore) {
        set_active(false);
      } else if (active && !pleaseExtra && !pleaseIgnore && !pleaseLiveday) {
        set_active(false);
      }

      if ((pleaseExtra && pleaseIgnore) || (pleaseLiveday && pleaseExtra)) {
        set_error(true);
      } else {
        set_error(false);
      }
    } else {
      if (!props.generalLiveDay || !props.generalLiveDay.length) {
        set_active(false);
        return;
      }
      const matchingGeneralLiveday = props.generalLiveDay.filter((i) => {
        if (
          i.schedule === props.day &&
          i.year === props.year &&
          i.week === props.week
        ) {
          return i;
        } else {
          return null;
        }
      });

      if (matchingGeneralLiveday.length >= 1) {
        set_active(true);
      } else {
        set_active(false);
      }
    }
  }, [
    props.ignores,
    props.day,
    props.year,
    props.week,
    props.schedule,
    active,
    props.extras,
    props.generalLiveDay,
  ]);

  const changeHandler = (e:FormEvent<HTMLInputElement>) => {
    const copy = active ? false : true;
    if (!props.generalDay&& props.member && props.addIgnoreDay) {
      dispatch(
        props.addIgnoreDay(props.member.id, props.day, props.week, props.year, copy)
      );
    } else {
      if(props.addGeneralLiveDay){
        dispatch(props.addGeneralLiveDay(props.day, props.week, props.year, copy));
      }
    }
  };
  const resolveError = () => {
    if (props.member && props.addIgnoreDay) {
    dispatch(
      props.addIgnoreDay(props.member.id, props.day, props.week, props.year, false)
    )}
    if(props.addGeneralLiveDay){
      dispatch(props.addGeneralLiveDay(props.day, props.week, props.year, false));
    }
  }

  if (error) return <p style={{color: "black"}} onClick={resolveError}>error!</p>;

  return (
    <div>
      <Checkbox
        style={{ zIndex: 0 }}
        toggle
        checked={active}
        onChange={changeHandler}
      />
    </div>
  );
}
