import React, {useEffect,useState} from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { selectPlayers } from '../../actions/selectors';
import { getAllPlayers, getMalfunctions } from "../../actions/videoInput";
import Graph from './Graph'

export default function PlayersGraph() {
    const dispatch = useAppDispatch();
    const players = useAppSelector(selectPlayers);
    const [graphData, set_graphData ]= useState([{}]) 
    
    useEffect(()=> {
      if(players){
        const online =  players.filter(p => p.LastSeenDay === 0)  || []
        const offline = players.length - online.length
        set_graphData([{name: "Online", value: online.length} ,{name: "Offline", value: offline, color: "#FF4263"}])
      }
      } , [players])
    

  useEffect(() => {
    dispatch(getAllPlayers());
    const date =  new Date()
    dispatch(getMalfunctions(date.getFullYear()));
  }, [dispatch]);

    return (
        <div>
            <Graph title="Players" data={graphData}/>
        </div>
    )
}
