import React from "react";
import { player } from "../../types/player";

interface AdminCellMacProps{
  player: player
  owner: number
  bold?: Boolean
  navigate: Function
}

export default function AdminCellMac({player, owner, bold, navigate}:AdminCellMacProps):JSX.Element {

  return (
    <div
      onClick={() =>
        navigate(`/players/${player["MAC adres"]}/${owner}`)
      }
      style={{cursor: 'pointer', fontWeight: bold ? 800: undefined}}
    >
      {player["MAC adres"]}
    </div>
  );
}
