import React, { useEffect, useState } from "react";
import { getYearInput } from "../../actions/videoInput";
import { Table } from "semantic-ui-react";
import { selectYearInput } from "../../actions/selectors";
import { member } from "../../types/member";
import { useNavigate } from "react-router-dom"
import { useAppDispatch,useAppSelector } from "../../hooks/redux";

interface InputAdminSummaryProps{
  member: member
}

export default function InputAdminSummary(props:InputAdminSummaryProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const yearInput = useAppSelector(selectYearInput);
  
  const getYear = () => {
    const date = new Date()
    return date.getFullYear()
  }
  const [year, set_year] = useState(getYear());
  
  useEffect(() => {
    if (props.member && props.member.id) {
      dispatch(getYearInput(props.member.id, year));
    }
  }, [props.member, year, dispatch]);

  const findInYearInput = (weekNumber: number) => {
    if (yearInput !== undefined) {
      const found = yearInput.find((w) => parseInt(w.week) === weekNumber);
      return found ? true : false;
    }
  };
  const findInYearInputId = (weekNumber: number) => {
    if (yearInput !== undefined) {
      const found = yearInput.find((w) => parseInt(w.week) === weekNumber);
      return found ? found.id : false;
    }
  };

  const createTable = () => {
    let table = [];
    let weekNumber = 1;

    for (let i = 0; i < 8; i++) {
      let children = [];
      for (let j = 0; j < 7; j++) {
        if (weekNumber <= 53) {
          const color = findInYearInput(weekNumber);
          const id = findInYearInputId(weekNumber)
          children.push(
            <Table.Cell
              key={i+j}
              negative={!color}
              positive={color}
              style={{cursor: id ? "pointer": null}}
              onClick={()=> id ? navigate(`/office/${id}`) : null}
            >{`${weekNumber}`}</Table.Cell>
          );
        } else {
          children.push(<Table.Cell key={i+j}></Table.Cell>);
        }
        weekNumber++;
      }
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  const renderYearButton = (newYear:number) => (
    <div className="ui button" onClick={(e) => set_year(newYear)} style={{cursor: 'pointer'}}>
      {newYear}
    </div>
  );
  const headerStyle = { display: "flex", justifyContent: "space-between" };
  if (!props.member || !props.member.id) return null;
  return (
    <div>

    <div style={{ width: "250px" }}>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={headerStyle}>
              {renderYearButton(year - 1)} {year} {renderYearButton(year + 1)}{" "}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <table>
            <tbody>
            {createTable()}
            </tbody>
            </table>
        </Table.Body>
      </Table>
    </div>
    </div>
  );
}
