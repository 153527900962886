import React from "react";
import { basicAccountmanager } from "../../types/acountmanager";

interface PreviewCellAccountmanagerProps{
  input: any
  set_Accountmanager(a: basicAccountmanager): void
}

export default function PreviewCellAccountmanager(props:PreviewCellAccountmanagerProps) {
  if (
    !props.input.member ||
    !props.input.member.MemberAccountmanager ||
    !props.input.member.MemberAccountmanager.Accountmanager ||
    !props.input.member.MemberAccountmanager.Accountmanager.name
  ) return null
  
    return (
      <div
        onClick={() =>
          props.set_Accountmanager(
            props.input.member.MemberAccountmanager.Accountmanager
          )
        }
        style={{cursor: 'pointer'}}
      >
        {props.input.member.MemberAccountmanager.Accountmanager.name}
      </div>
    );
}
