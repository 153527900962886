import React from "react";
import ProductFocusFeed from "../components/ProductFocusFeed";
import SeasonFeed from "../components/SeasonFeed";
import AmbianceFeed from "../components/AmbianceFeed";
import ProductExtraInfoFeed from "../components/ProductExtraInfoFeed";
import InputNavigationFeed from "../components/InputNavigationFeed";
import BannerShop from "../components/BannerShop";
import Gallery from "../components/Gallery";

export default function InputFeed() {
  return (
    <div>
        <section className="banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="banner-wrapper">
                            <BannerShop/>
                            <ProductFocusFeed/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="season-area">
            <div className="container position-relative season-after">
                <div className="row">
                    <div className="column-1">
                        <div className="season-title">
                            <h4>Seizoen en Streek</h4>
                        </div>
                    </div>
                </div>
                <SeasonFeed/>
                <AmbianceFeed/>
            </div>
        </section>

        <Gallery/>
        <ProductExtraInfoFeed/>
        <InputNavigationFeed 
        back="/input"
        to="/upload"
          // prevStep={}
        nextStep={4}/>
    </div>
  );
}
