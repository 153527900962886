import React from "react";
import MenuItem from "./MenuItem";
import { useAppSelector } from "../hooks/redux";
export default function Menu():JSX.Element {
  const user = useAppSelector((reduxState) => reduxState.user);
  return (
    <div className="menuTopBar">
      <ul className="menuItems">
        <MenuItem pos="TopRight" to="logout" name="UITLOGGEN" />
        <MenuItem pos="TopRight" to="support" name="HANDLEIDING" />
      </ul>
      <nav role="navigation">
        <div id="menuToggle">
          <input type="checkbox" /> 
          <span></span>
          <span></span>
          <span></span>
          <div id="menu">
            <ul id="menuback">
                <a href="preview"><li>PREVIEW</li></a>
                <a href="input"><li>INPUT</li></a>
                {user.role === "admin" ? <a href="/admin"><li>ADMIN</li></a> : null}
                {user.role === "admin" ? <a href="/players"><li>PLAYERS</li></a> : null}
                {user.role === "admin" ? <a href="/office"><li>OFFICE</li></a> : null}
                {user.role === "admin" ? <a href="/installation"><li>INSTALLATIE</li></a> : null}
                <a href="support"><li>HANDLEIDING</li></a>
                <a href="logout"><li>UITLOGGEN</li></a>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
