import {SET_PRODUCTIONVIDEO} from '../actions/actionCreators'
import { ambiancevideo } from '../types/ambiance'
import { constructionVideoOrPhoto } from './constructionPhoto'


type constructionPhotoActionTypes = 
| {type: typeof SET_PRODUCTIONVIDEO, payload: constructionVideoOrPhoto}



const reducer =  (state:ambiancevideo[] = [],  action:constructionPhotoActionTypes) => {
    switch (action.type) {
    case SET_PRODUCTIONVIDEO:
        if(action.payload.constructionVideo !== undefined){
            return action.payload.constructionVideo
        }else{
            return state
        }

    default:
        return state
    }
}

export default reducer
