
import React from "react";
import statusGreen from "./images/statusGreen.png"
import statusOrange from './images/statusOrange.png'
import statusRed from './images/statusRed.png'
import statusBlueOrange from './images/statusBlueOrange.png'
import statusBlueRed from './images/statusBlueRed.png'
import statusBlueGreen from './images/statusBlueGreen.png'
import statusGray from './images/statusGray.png'
import { player } from "../../types/player";

interface AdminCellHealthProps{
     player: player
}

export default function AdminCellHealth(props:AdminCellHealthProps):JSX.Element {
  
  let image = ""  
  let title = ""
  let alt = ""
  if (props.player.piplayerHealth.health === "green") {
       image = statusGreen
       title="Deze PiPlayer is heeft zich het afgelopen uur gemeld." 
       alt="Deze PiPlayer is heeft zich het afgelopen uur gemeld."
  }
  if (props.player.piplayerHealth.health === "yellow") {
       image = statusOrange
       title="Deze PiPlayer heeft vandaag gemeld maar langer dan 3 uur geleden." 
       alt="Deze PiPlayer heeft vandaag gemeld maar langer dan 3 uur geleden"
  }
  if (props.player.piplayerHealth.health === "red") {
       image = statusRed
       title="Deze PiPlayer heeft zich vandaag niet gemeld." 
       alt="Deze PiPlayer heeft zich vandaag niet gemeld."
  }
  if (props.player.piplayerHealth.health === "redBlue") {
      image = statusBlueRed
      title="Deze PiPlayer heeft zich vandaag niet gemeld en heeft iets anders op de FTP staan."
      alt="Deze PiPlayer heeft zich vandaag niet gemeld en heeft iets anders op de FTP staan."
  }
  if (props.player.piplayerHealth.health === "blueYellow") {
      image = statusBlueOrange
      title="Deze PiPlayer heeft vandaag gemeld maar langer dan 3 uur geleden en heeft iets anders op de FTP staan." 
      alt="Deze PiPlayer heeft vandaag gemeld maar langer dan 3 uur geleden en heeft iets anders op de FTP staan."
    }
  if (props.player.piplayerHealth.health === "blue") {
      image = statusBlueGreen
      title="Deze PiPlayer is heeft zich het afgelopen uur gemeld en heeft iets anders op de FTP staan." 
      alt="Deze PiPlayer is heeft zich het afgelopen uur gemeld en heeft iets anders op de FTP staan."
     }
  if (props.player.piplayerHealth.health === "unknown") {
      image = statusGray
      title="Deze PiPlayer heeft nog geen status gerapporteerd." 
      alt="Deze PiPlayer heeft nog geen status gerapporteerd."
     }
  
  return (
       <img src={image} alt={alt} title={title} />
    );
}
