import React from "react";
import { Icon } from "semantic-ui-react";
import { unlinkPlayer } from "../../actions/videoInput";
import { player } from "../../types/player";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { useAppDispatch } from "../../hooks/redux";


interface AdminCellDetachProps{
  player: player
  customer: number
  style?: object
  size?: IconSizeProp
  text?: string,
  cb?: Function
}

export default function AdminCellDetach(props:AdminCellDetachProps):JSX.Element {
  const dispatch = useAppDispatch();
 
  const handleUnlink = () => {
    dispatch(unlinkPlayer(props.player["MAC adres"], props.customer, props.cb));
  };

  return (
    <div onClick={handleUnlink} style={props.style}>
      <Icon name="remove circle" size={props.size} />
      {props.text ? <p>{props.text}</p> : null}
    </div>
  );
}
