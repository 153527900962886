import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Button, Checkbox, Dropdown, Input, Table } from "semantic-ui-react";
import {
  addMediaTag,
  getMediaTags,
} from "../../actions/videoInput";
import { selectMediaTags } from "../../actions/selectors";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { mediaTagType } from "../../types/tags";
import AdminMediaTag from "../../components/admin/AdminMediaTag";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { setAppError } from "../../actions/actionCreators";




export default function HeibaLibaryTags() {
  const mediaTags = useAppSelector(selectMediaTags);
  const dispatch = useAppDispatch();

  const [sortBy, set_sortBy] = useState('name')
  const [direction, set_direction] = useState<'ascending' | 'descending' >('descending')

  const [name, set_name] = useState("")
  const [description, set_description] = useState("")
  const [contentRightsLockTag, set_contentRightsLockTag] = useState(false)
  const [color, set_color] = useState<SemanticCOLORS>("blue")

  type paramTypes = {id: string, mediaType: string}
  const {id, mediaType} =  useParams<paramTypes>()

  useEffect(() => {
    dispatch(getMediaTags())
  }, [mediaType, dispatch, id]);


  const addTag = () => {
    if(name.length < 3) return dispatch(setAppError("Tag naam te kort"))
    if(description === "") return dispatch(setAppError("Geen omschrijving ingevuld"))
    dispatch( addMediaTag(name, description, contentRightsLockTag, color))
  }

  const changeSortDirection = (type:string) => {
    if(type === sortBy){
      set_direction(direction === 'ascending' ? 'descending' : 'ascending')
    }else{
      set_sortBy(type)
    }
  }
  const sortByName = (a:mediaTagType,b:mediaTagType) =>{
    if(a?.name < b?.name) { return (direction === 'descending' ? -1 : 1); }
      if(a?.name >  b?.name) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  const sortByContentRightsLockTag = (a:mediaTagType,b:mediaTagType) =>{
    if(a?.contentRightsLockTag < b?.contentRightsLockTag) { return (direction === 'descending' ? -1 : 1); }
      if(a?.contentRightsLockTag >  b?.contentRightsLockTag) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }
  const sortByColor = (a:mediaTagType,b:mediaTagType) =>{
    if(a?.color < b?.color) { return (direction === 'descending' ? -1 : 1); }
      if(a?.color >  b?.color) { return (direction === 'descending' ? 1 : -1); }
      return 0;
  }

  const semanticColors:SemanticCOLORS[]= ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']
  const colorOptions = semanticColors?.map((color) => { return {key: color , value: color, text: `${color}`} })

  if(!mediaTags) return <LoadingIndicator loadingText="Tags laden" />

  const sorted = [...mediaTags].sort( sortBy === "name" ? sortByName :  sortBy === "contentRightsLockTag" ? sortByContentRightsLockTag :  sortBy === "id" ? sortByColor: sortByColor) //TODO remove 1 sortByColor
  return (
    <div>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
               Tag
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={"name" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("name")}
              style={{cursor: 'pointer'}}
            >
              Naam of id</Table.HeaderCell>
            <Table.HeaderCell 
               >Omschrijving</Table.HeaderCell>
            <Table.HeaderCell
             sorted={"contentRightsLockTag" === sortBy ? direction : undefined}
             onClick={() => changeSortDirection("contentRightsLockTag")}
             style={{cursor: 'pointer'}}
            >
              contentRightsLockTag</Table.HeaderCell>
            <Table.HeaderCell
              sorted={"color" === sortBy ? direction : undefined}
              onClick={() => changeSortDirection("color")}
              style={{cursor: 'pointer'}}
            >Kleur</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>

          {sorted.map( (tag, i) => { 
            return (
              <Table.Row key={i}>
                <Table.Cell>{<AdminMediaTag mediaTagType={tag}/>}</Table.Cell>
                <Table.Cell>{tag?.name}</Table.Cell>
                <Table.Cell>{tag?.description}</Table.Cell>
                <Table.Cell><Checkbox label='Locked' checked={tag?.contentRightsLockTag } disabled /></Table.Cell>
                <Table.Cell>{tag?.color}</Table.Cell>
              </Table.Row>
            );
          })}
         

           <Table.Row>
                <Table.Cell> <AdminMediaTag mediaTagType={{name, description, contentRightsLockTag, color}}/></Table.Cell>
                <Table.Cell>
                  <Input value={name } placeholder="Naam tag" onChange={e => set_name((e.target.value))} />
                </Table.Cell>
                <Table.Cell>
                  <Input value={description} placeholder="Omschrijving" onChange={e => set_description((e.target.value))} />
                  </Table.Cell>
                  <Table.Cell><Checkbox label='Locked' c
                                        hecked={contentRightsLockTag }
                                        onChange={e => set_contentRightsLockTag(!contentRightsLockTag)}  /></Table.Cell>
                <Table.Cell>
                <Dropdown
                  placeholder={`Selecteer een kleur`}
                  fluid
                  search
                  selection
                  value={color}
                  //@ts-ignore
                  onChange={(e, f) => set_color(f.value)} 
                  options={colorOptions}
                />
                </Table.Cell>
              </Table.Row>
        </Table.Body>
      </Table>
      <Button.Group floated="right">
        <Button color="orange" onClick={addTag} >Voeg toe</Button>
      </Button.Group> 

      {semanticColors.map( (color, i) => { 
        return (
          <AdminMediaTag mediaTagType={{name: color, description: "", contentRightsLockTag: false, color}} cb={(e,d)=>set_color(d?.color)} />
        );
      })}
    </div>
  );
}


