import React from "react";
import { player } from "../../types/player";


interface AdminCellLastSeenProps{
  player: player
}

export default function AdminCellLastSeen(props:AdminCellLastSeenProps):JSX.Element {
  return <div>{props.player.LastSeenDay}</div>;
}
