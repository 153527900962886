import Alert from "@mui/material/Alert";
import React, { Fragment } from "react";

interface AlertMessageProps{
  messageId: number
}

export default function AlertMessage(props:AlertMessageProps):JSX.Element {
  let message = "";
  switch (props.messageId) {
    case 1:
      message =
        "De combinatie E-mail en Wachtwoord die u heeft ingevuld behoort niet tot een Heiba Service account.";
      break;
    case 2:
      message = "Controleer uw mailbox en volg de instructies in de mail.";
      break;
    case 3:
      message = "Uw wachtwoord is opgeslagen.";
      break;
    case 4:
      message = "Gebruiker onbekend. Probeer opnieuw. ";
      break;
    case 5:
      message = "Wachtwoord is veranderd, u kunt nu inloggen. ";
      break;
    case 6:
      message = "De reset-link is verlopen. Probeer opnieuw. ";
      break;
    case 7:
      message = "De reset-link ongeldig. Probeer opnieuw.";
      break;
    case 8:
      message =
        "Er is al een Heibaservice account bekend, Gebruik de optie 'Wachtwoord vergeten' om uw wachtwoord opnieuw in te stellen. ";
      break;
    case 9:
      message =
        "Uw account is geblokkeerd, neem contact op met Heibaservice. +31 (0)20 4229898 ";
      break;
    case 10:
      message = "Onbekende fout opgetreden. Probeer opnieuw.";
      break;
    case 11:
      message = "Email-adres is ongeldig. Probeer opnieuw. ";
      break;
    case 12:
      message = "Passwords zijn niet gelijk. Probeer opnieuw. ";
      break;
    case 13:
      message = "Passwords moeten minimaal 8 tekens bevatten. Probeer opnieuw. ";
      break;
    case 14:
      message = "U dient uw password te veranderen, klik op Wachtwoord vergeten om uw password te wijzigen";
      break;
    case 15:
      message = "Vul een email adres en wachtwoord in om in te loggen";
      break;
    case 16:
      message = "Uw wachtwoord is veranderd";
      break;
    case 40:
      message = "Een of meer bestanden werden niet geaccepteerd";
      break;
    case 101:
      message = "Update succes";
      break
    case 102:
      message = "Upload succes";
      break;
    default:
      return (<Fragment></Fragment>)
  }

  return (
    <div>
      <Alert variant="filled" severity="warning">
        {message}
      </Alert>
    </div>
  );
}
