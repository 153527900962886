import React from 'react'
import { LibaryMediaType } from '../../types/constructionMedia'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger, faSeedling, faCamera, faGuitar} from '@fortawesome/free-solid-svg-icons'
// import { faHamburger , faFish, faCheese, faEgg, faSeedling, faBreadSlice, faBirthdayCake, faStore, faAppleAlt, faCamera, faGuitar} from '@fortawesome/free-solid-svg-icons'


interface CustomerTypesListProps{
  type: LibaryMediaType
  set_type(a: LibaryMediaType): void 
}

export default function CustomerTypesList(props:CustomerTypesListProps) {
    return (
        <div className="typeOfVideo">
        
        <form>
          <input
            type="radio"
            id="vlees"
            name="typeOfVideo"
            checked={props.type === "vlees"}
            onChange={(e)=> props.set_type("vlees")}
          />
          <label htmlFor="vlees">Vlees</label>
          
          <FontAwesomeIcon icon={faHamburger} />
          {/* <input
            type="radio"
            id="vis"
            name="typeOfVideo"
            checked={props.type === "vis"}
            onChange={(e)=> props.set_type("vis")}
          /> */}
          {/* <label htmlFor="vis">Vis </label>
          <FontAwesomeIcon icon={faFish} />
          <input
            type="radio"
            id="kaas"
            name="typeOfVideo"
            checked={props.type === "kaas"}
            onChange={(e)=> props.set_type("kaas")}
          />
          <label htmlFor="kaas">Kaas</label>
          <FontAwesomeIcon icon={faCheese} />
          <input
            type="radio"
            id="kip"
            name="typeOfVideo"
            checked={props.type === "kip"}
            onChange={(e)=> props.set_type("kip")}
          />
          <label htmlFor="kip">Kip</label>
          <FontAwesomeIcon icon={faEgg} />
          <input
            type="radio"
            id="groente"
            name="typeOfVideo"
            checked={props.type === "groente"}
            onChange={(e)=> props.set_type("groente")}
          />
          <label htmlFor="groente">Groente</label>
          <FontAwesomeIcon icon={faAppleAlt} />
          <input
            type="radio"
            id="brood"
            name="typeOfVideo"
            checked={props.type === "brood"}
            onChange={(e)=> props.set_type("brood")}
          />
          <label htmlFor="brood">Brood</label>
          <FontAwesomeIcon icon={faBreadSlice} />
          <input
            type="radio"
            id="banket"
            name="typeOfVideo"
            checked={props.type === "banket"}
            onChange={(e)=> props.set_type("banket")}
          />
          <label htmlFor="banket">Banket</label>
          <FontAwesomeIcon icon={faBirthdayCake} />
          <input
            type="radio"
            id="algemeen"
            name="typeOfVideo"
            checked={props.type === "algemeen"}
            onChange={(e)=> props.set_type("algemeen")}
          />
          <label htmlFor="algemeen">Algemeen</label>
          <FontAwesomeIcon icon={faStore} />
          <input
            type="radio"
            id="bloem"
            name="typeOfVideo"
            checked={props.type === "bloem"}
            onChange={(e)=> props.set_type("bloem")}
          /> */}
          {/* <label htmlFor="bloem">Bloem</label> */}
          <FontAwesomeIcon icon={faSeedling} />
          <input
            type="radio"
            id="sfeer"
            name="typeOfVideo"
            checked={props.type === "sfeer"}
            onChange={(e)=> props.set_type("sfeer")}
          />
          <label htmlFor="sfeer">Sfeervideo's</label>
          <FontAwesomeIcon icon={faGuitar} />
          <input
            type="radio"
            id="image"
            name="typeOfVideo"
            checked={props.type === "image"}
            onChange={(e)=> props.set_type("image")}
          />
          <label htmlFor="image">Image</label>
          <FontAwesomeIcon icon={faCamera} />
        </form>
      </div>
    )
}
