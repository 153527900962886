import React from 'react'
import styled from "styled-components"
import { heibaweek } from '../types/heibaweek'

 interface styleProps{
         active: boolean
 }

const Div = styled.div<styleProps>`
        width: 7em;
        height: 8em;
        border-style: solid; 
        margin: 1em; 
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: 20%;
        background-color: ${props => props.active ? "#cae7ef" : "#f5fafe"};
        border-color: ${props => props.active ? "#ec7026" : "#878a8f"};
        cursor: 'pointer';
        margin-left: calc(100% * (1/16) - -4px - 1px);
        margin-right: calc(100% * (1/16) - -4px - 1px);
        cursor: pointer;
    `

const Pnumber = styled.p`
        font-weight: bold;
        font-size: 2.5em;
        margin-top: 0em;
        margin-bottom: 0.1em;
        color: black;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: -21px;
`
const Ptext =  styled(Pnumber)`
        font-size: 1em;
        color: black;
        margin: auto;
        width: 100%;
        text-align: center;
        position: relative;
        top: 8px;
`
const Pname =  styled(Pnumber)`
        font-size: 1em;
        color: #fa4609;
        margin: auto;
        width: 100%;
        text-align: center;
        position: relative;
        top: 11px;
`
interface InputWeekProps{
        week: heibaweek,
        cb: Function
        active: boolean
}

export default function InputWeek(props:InputWeekProps) {
    if(!props.week) return null
    
    return (
    <Div onClick={e=> props.cb()} active={props.active}> 
            <Ptext>week </Ptext>  
            <Pname>6-12 juni </Pname>  
            <Pnumber>{props.week.week} </Pnumber>     
    </Div>
    )
}
