import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getVideoInput, getVideoInputAccountManager } from "../../actions/videoInput";
import { Table } from "semantic-ui-react";
import PreviewCellComments from "./PreviewCellComments";
import PreviewCellCompany from "./PreviewCellCompany";
import PreviewCellStatus from "./PreviewCellStatus";
import PreviewUpload from "./PreviewUpload";
import PreviewCellAccountmanager from "./PreviewCellAccountmanager";
import PreviewCellProduct from "./PreviewCellProduct";
import LoadingIndicator from '../LoadingIndicator'
import { basicAccountmanager } from "../../types/acountmanager";
import { selectWeekinput } from "../../actions/selectors";
import { member } from "../../types/member";

interface OnPreviewAdminProps{
  accountmanager: basicAccountmanager | undefined
  member: member | undefined
  set_CustomerId(n: number): void 
  set_Accountmanager(a: basicAccountmanager): void
}

export default function OnPreviewAdmin(props:OnPreviewAdminProps) {
  const dispatch = useAppDispatch();
  const weekInput = useAppSelector(selectWeekinput);
  useEffect(() => {
    if(!props.accountmanager) {
      if(props.member && props.member.id){
        dispatch(getVideoInput(props.member.id))
      }else{
        dispatch(getVideoInput())
      }
    }
    else  {
      dispatch(getVideoInputAccountManager(`${props.accountmanager.id}`))
    }
  }, [props.member, props.accountmanager, dispatch]);

  if( !weekInput) return <LoadingIndicator loadingText="Aanleveringen laden" />
  if( weekInput &&  weekInput.length === 0 ) return null
  
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            { !props?.member?.id ?  <Table.HeaderCell>AccountManager</Table.HeaderCell> : null}
            <Table.HeaderCell>Week</Table.HeaderCell>
            
            { !props?.member?.id ?  <Table.HeaderCell>Company</Table.HeaderCell> : null}
            {  props?.member?.id ?  <Table.HeaderCell>Product</Table.HeaderCell> : null}
            <Table.HeaderCell>Commentaar</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Upload</Table.HeaderCell>

          </Table.Row>
        </Table.Header>

        <Table.Body>
          {weekInput.map(input => {
            return (
              <Table.Row key={input.id}>
                { !props?.member?.id ?   <Table.Cell><PreviewCellAccountmanager input={input} set_Accountmanager={props.set_Accountmanager}/></Table.Cell> : null}
                <Table.Cell>{input.week + "(" + input.year + ")"}</Table.Cell>
                { !props?.member?.id ?   <Table.Cell><PreviewCellCompany input={input} set_CustomerId={props.set_CustomerId} /> </Table.Cell> : null}  
                { props?.member?.id ?   <Table.Cell><PreviewCellProduct input={input}/></Table.Cell> : null}
                <Table.Cell><PreviewCellComments input={input}/></Table.Cell>
                <Table.Cell><PreviewCellStatus input={input} accountmanager={props?.accountmanager} member={props?.member} /> </Table.Cell>
                <Table.Cell><PreviewUpload input={input}/></Table.Cell>
              
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
