import React, { useState, useEffect, Fragment } from "react";
import { Table, Icon } from "semantic-ui-react";
import LoadingIndicator  from "../../components/LoadingIndicator";

import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {
  selectMalfunctionsOpen,
  selectMalfunctionsAll,
} from "../../actions/selectors";
import { getMalfunctions, addMalfunction, solveMalfunction, getPlayers } from "../../actions/videoInput";
import CustomerSelect from "../../components/admin/CustomerSelect";
import InputCbOnEnter from "../../components/InputCbOnEnter";
import PlayerSelect from "../../components/admin/PlayerSelect";

export default function Malfunctions() {
  const malfunctionsOpen = useAppSelector(selectMalfunctionsOpen);
  const malfunctions = useAppSelector(selectMalfunctionsAll);
  const user = useAppSelector((reduxStore) => reduxStore.user);
  const members_list = useAppSelector(reduxState => reduxState.admin.members)
  const dispatch = useAppDispatch();
  const [showAll, set_showAll] = useState(false);
  const [customerId, set_CustomerId] = useState("Selecteer een klant");
  const [problem, set_problem] = useState("");
  const [solution, set_solution] = useState("");
  const [extraInfo, set_extraInfo] = useState("");
  const [player, set_player] = useState("");

  const date = new Date();
  const today = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;

  useEffect(() => {
    const date = new Date()
    dispatch(getMalfunctions(date.getFullYear()));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPlayers(customerId));
  }, [customerId, dispatch]);

  const addALine = () => {
    const date = new Date()
    const company = members_list.filter(m => m.id === customerId)
    dispatch(
      addMalfunction(
        [
          customerId,
          company[0].company,
          player,
          problem,
          solution,
          today,
          "",
          extraInfo,
          user.name
        ],
        date.getFullYear()
      )
    );
    set_CustomerId("Selecteer een klant")
    set_problem("")
    set_solution("")
    set_player("")
    set_extraInfo("")

  };
  const solve = (malfunction) =>{
    //matching happens on id + date 
    const date = new Date()
    dispatch(solveMalfunction({...malfunction , dateClose: today}, date.getFullYear()))
  }
  const done = (malfunction) =>{
    if(malfunction.dateClose !== "") return <div> 
      {/* {data} */}
      <Icon  name="circle check" />
      <p>Opgelost</p>

    </div>
    return <Fragment> 
      <Icon  name="circle wrench" />
      <p>Open</p>
      </Fragment>
  }


  const possibleSolution = (malfunction) =>{
    return <InputCbOnEnter key={malfunction.row } value={malfunction} cb={solve}  cbProperty="solution" lock={malfunction.dateClose}/>
  }
  
  if (!malfunctionsOpen || malfunctionsOpen.length <= 1)
    return <LoadingIndicator loadingText="Storingen laden" />
  if (malfunctionsOpen && malfunctionsOpen.length === 0) return null;
  const malfunctionsToShow = showAll ? malfunctions : malfunctionsOpen;
  return (
    <div>
      <h1>
        Totaal aantal onopgeloste storingen: ({malfunctionsOpen.length}/
        {malfunctions.length}){" "}
      </h1>
      <button onClick={(e) => set_showAll(!showAll)} style={{cursor: 'pointer'}}>
        {showAll ? "Toon onopgeloste" : "Toon alle"}
      </button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{malfunctions[0].customerId}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].customerName}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].mac}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].type}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].solution}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].dateOpen}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].dateClose}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].extraInfo}</Table.HeaderCell>
            <Table.HeaderCell>{malfunctions[0].name}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {malfunctionsToShow.map((malfunction, i) => {
            if (i === 0) return null;
            // if (!player.PIplayerHealth) return null;
            // if(props.connect) return <AdminCellConnect player={player} key={i}/>
            return (
              <Table.Row key={i}>
                <Table.Cell> {malfunction.customerId}</Table.Cell>
                <Table.Cell> {malfunction.customerName}</Table.Cell>
                <Table.Cell> {malfunction.mac}</Table.Cell>
                <Table.Cell> {malfunction.type}</Table.Cell>
                <Table.Cell> {possibleSolution( malfunction)}</Table.Cell>
                <Table.Cell> {malfunction.dateOpen}</Table.Cell>
                <Table.Cell> {done(malfunction )}</Table.Cell>
                <Table.Cell> {malfunction.extraInfo}</Table.Cell>
                <Table.Cell> {malfunction.name}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Row>
          <Table.Cell> {customerId}</Table.Cell>
          <Table.Cell>
            {" "}
            {<CustomerSelect set_CustomerId={set_CustomerId} />}
          </Table.Cell>
          <Table.Cell>
            
            {<PlayerSelect set_player={set_player}  customerId={customerId}/>}
          </Table.Cell>
          <Table.Cell>
            {
              <input
                placeholder="Probleem"
                onChange={(e) => set_problem(e.target.value)}
                value={problem}
              ></input>
            }
          </Table.Cell>
          <Table.Cell>
            {" "}
            {
              <input
                placeholder="Mogelijke oplossing"
                onChange={(e) => set_solution(e.target.value)}
                value={solution}
              ></input>
            }
          </Table.Cell>
          <Table.Cell> {today}</Table.Cell>
          <Table.Cell> {}</Table.Cell>
          <Table.Cell>
            {" "}
            {
              <input
                placeholder="toevoeging"
                onChange={(e) => set_extraInfo(e.target.value)}
                value={extraInfo}
              ></input>
            }
          </Table.Cell>
          <Table.Cell> {user.name}</Table.Cell>
        </Table.Row>
      </Table>
      <button onClick={addALine} style={{cursor: 'pointer'}}>voeg storing toe</button>
    </div>
  );
}
