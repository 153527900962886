import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import {
  backendBaseUrl,
} from "../../constants";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import React  from "react";
import { reAuth } from "../../actions/videoInput";
import * as Sentry from "@sentry/browser"
import { selectUser } from "../../actions/selectors";
import UploadPreviewComponent from "../UploadPreviewWebshop";

interface DropzoneWrapperProps{
  setLock: any
  setWarning: any
  setWait: any,
  set_errorList: any
  set_productList: any
}


const DropzoneWrapperWebshop= ({ setLock, setWarning, setWait,set_errorList, set_productList}:DropzoneWrapperProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser);

  const getUploadParams = ({ file, meta }:any) => {
    console.log("file", file)
    return {
      url: `${backendBaseUrl}/uploadWebshop`,
      headers: {
        Authorization: "Bearer " + user.jwt,
        //todo make dynamic
        memberid: user.id,
        contentUpload: file.name
      },
    };
  };

  //this function might create a infinite loop
  const handleChangeStatus = async (file:any,status:any) => {
    console.log("file", file?.xhr?.response)//file {"error":"jwt expired"}
    console.log("status", status)
    if(file?.xhr?.response){
      try{
        const json = JSON.parse(file?.xhr?.response)
        //error: false}) handle this
        if(json.webshopProductsStoreFailed){
          set_productList(null)
          return set_errorList(json.webshopProductsStoreFailed)
        }
        if(json.webshopProductsNotValidated){
          set_productList(null)
          return set_errorList(json.webshopProductsNotValidated)

        }
        if(json.productList){ // i think this one can go 
          set_errorList(null)
          set_productList(json.webshopTemplateProducts)
        }
        if(json.webshopTemplateProducts){
          set_errorList(null)
          set_productList(json.webshopTemplateProducts)
        }
      }
      catch(err){
        console.log("error", err)
      }

    }

    if(status === 'error_upload'){
      if( file.xhr.getResponseHeader("Content-Length") ===  "23" ){ 
        dispatch(reAuth( file.restart)) 
      }else{
        Sentry.captureMessage("Other error in customer upload process")
      }
    }
    
  }

  const validate = (files:any) => {
  const status = files.map((f:any) => f.meta.status);

      //@ts-ignore
    const uploadStatus = [...new Set([...status])];
    setLock(  uploadStatus.includes("error_upload") || 
              uploadStatus.includes("exception_upload") ||
              uploadStatus.includes("rejected_file_type") ||
              uploadStatus.includes("rejected_max_files") ||
              uploadStatus.includes("error_file_size") ||
              uploadStatus.includes("error_validation") ||
              uploadStatus.includes("error_upload_params") ||
              uploadStatus.includes("aborted"))
    setWait(  uploadStatus.includes("uploading") || 
              uploadStatus.includes("getting_upload_params") ||
              uploadStatus.includes("preparing") 
               )
    console.log("us",uploadStatus)
    if (
      //todo clean this up, no need for csv files
      uploadStatus.includes("low_resolution")  ||
      uploadStatus.includes("small_file")
    ) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  return (
    <Dropzone 
    //@ts-ignore
      getUploadParams={getUploadParams}
      //@ts-ignore
      onChangeStatus={handleChangeStatus}
      onSubmit={validate}
      accept="text/csv"
      //@ts-ignore
      PreviewComponent={UploadPreviewComponent}
      canCancel={false}
      SubmitButtonComponent={({ files }) => {
        validate(files);
        return null;
      }}
      inputContent={"Sleep de gevulde HeibaWebshop Template in dit vlak of klik om up te loaden"}
      inputWithFilesContent="Bestanden toevoegen"
    />
  );
};

export default DropzoneWrapperWebshop;
