import React, { useState, useEffect, useRef } from 'react'
import { frontEndUrl } from '../../constants'
import { getWeek } from '../../getWeek'

export default function GenerateLinkForInput() {
    const [week, set_week] = useState( getWeek(new Date()))
    const [year, set_year] = useState(new Date().getFullYear())
    const [url, set_url] = useState("")
    const [copied, set_copied] = useState(false)
    
    const refLink = useRef<HTMLTextAreaElement>(null)

    useEffect(( ) => {
        set_url(btoa( JSON.stringify({year, week})))
    }, [week, year])

    const copyCodeToClipboard = () => {
        //@ts-ignore
        refLink.current.select()
        document.execCommand("copy")
        set_copied(true)
      }
    useEffect(()=>{
        if(copied){
            setTimeout(() => {
                set_copied(false);
              }, 4000);
        }
    },[copied])
      
      
    return (
        <div className="officeItem double ">
            <h2>Week<em>links</em></h2>
            <p>Deze links kan je naar een klant sturen om deze aan te laten leveren voor een specifieke week</p>
                    <p> Lever aan voor de eerst volgende week waarvoor je nog niet aangeleverd hebt(max 5) via <em className='copyLink'> {`${frontEndUrl}/input/next`}</em>
                         </p>
                <p> Lever aan voor specifieke week:</p>
                <div className='officeSpecificWeekInput'>
                    <p>Weeknummer:</p>
                    <input value={week} type="number" onChange={e => set_week(Number(e.target.value))}/>
                    <p>Jaar:</p>
                    <input value={year} type="number" onChange={e => set_year(Number(e.target.value))}/>
                    <br/>
                </div>
                    <textarea hidden={true} value={`${frontEndUrl}/input/${url}`} ref={refLink}  onChange={()=>{}}/>
                    <p> Lever aan voor week {week} ({year}): <em className='copyLink'> {`${frontEndUrl}/input/${url}`}</em></p>
                <div className='officeButtonHolder'>    
                    <p>{copied ? "link gekopieerd" : null}</p>
                    <button className='adminButton' onClick={copyCodeToClipboard}>Copy link</button>
                </div>    
        </div>
    )   
}
