import React, { FormEvent, useCallback, useEffect, useState } from "react";
import superagent from "superagent";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { firstPageAfterLogin, backendBaseUrl, firstAdminPageAfterLogin } from "../constants";
import { setLogin, setMe } from "../actions/actionCreators";
import logo from "../components/heibalogo2019.svg";
import AlertMessage from "../components/AlertMessage";
import { officePhoneNumber } from "../constants";
import { selectUser } from "../actions/selectors";
import { reAuth } from "../actions/videoInput";
import { user} from "../types/user"
import { userActionTypes } from "../reducers/user";
import { Box, Button, CardMedia, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, Popover, TextField, Typography } from "@mui/material";


export const UserRole = () => {
  const user = useAppSelector(selectUser);
  return user.role;
};

export default function SignIn() {
  const [email, emailHandler] = useState(
    localStorage.getItem("username") || ""
  );
  const [messageId, setMessageId] = useState(0);
  const [password, passwordHandler] = useState("");
  const [storeUsername, set_storeUsername] = useState(true);
  const dispatch = useAppDispatch();
  const disp = useAppDispatch()
  const navigate = useNavigate()
  let location = useLocation();
  //@ts-ignore not sure if line below actually works, didn't test when conversion to ts is done.. 
  let { from } = location.state || { from: { pathname: "/" } };
  const user = useAppSelector(selectUser)
  
  const redirectUser = useCallback( (a:user):userActionTypes => {
    if(a.role === "admin"){
      navigate(firstAdminPageAfterLogin, { replace: true });
    }else{
      navigate(firstPageAfterLogin, { replace: true });
    }
    return(setMe(a))
  },[navigate])

  useEffect(() => {
    if(user?.jwt){
      disp(reAuth(redirectUser))
    }
  }, [user?.jwt, disp, redirectUser])  

  const submitHandler = (event:FormEvent) => {
    event.preventDefault();
    if(email !== "" && password !== ""){

      superagent
      .post(`${backendBaseUrl}/login`)
      .send({ username: email, password })
      .then((result) => {
        if (result.body.jwt === undefined) {
          setMessageId(result.body.messageId);
        }
        dispatch(setLogin(result.body));
        return result.body;
      })
      .then((body) => {
        localStorage.setItem("heibaToken", body.jwt);
        if (storeUsername) {
          localStorage.setItem("username", email);
        }
        if (from.pathname !== "/" && from.pathname !== "/logout") {
          console.log("should go to a specific location", from)
          navigate(from.pathname, { replace: true });
        } else {
          if(body.role === "admin"){
            navigate(firstAdminPageAfterLogin, { replace: true });
          }else{
            navigate(firstPageAfterLogin, { replace: true });
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.body && err.response.body.id) {
          setMessageId(err.response.body.id);
        } else {
          setMessageId(10);
        }
      });
    }else{
      setMessageId(15)
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = { media: "media", container: "container", 
                    paper: "paper", form: "form", bottomContainer: "bottomContainer", typography: "typography",
                    submit: "submit"}
  return (
    <Container component="main" maxWidth="xs" style={{backgroundColor: "#F5E1CF",
        borderRadius: "20px",
        paddingLeft: "20px",
        paddingRight: "20px"}}>
      <CssBaseline />
      <div style={{     //marginTop: //theme.spacing(8),
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center"}}>
        <CardMedia
          component="img"
          style={{  marginTop: "20px"}}
          image={logo}
          title="Heibaservice logo"
        />

        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form style={{width: "100%", // Fix IE 11 issue.
}} noValidate onSubmit={submitHandler}>
          <TextField
            variant="outlined"
            onChange={(event:any) => {
              emailHandler(event.target.value);
              setMessageId(0);
            }}
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mailadres"
            name="email"
            autoComplete="email"
            autoFocus
            style={{backgroundColor: "#f5fafe"}}
          />
          <TextField
            variant="outlined"
            onChange={(event:any) => {
              passwordHandler(event.target.value);
              setMessageId(0);
            }}
            value={password}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Wachtwoord"
            type="password"
            id="password"
            autoComplete="current-password"
            style ={{backgroundColor: "#f5fafe"}}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                onClick={(e:any) => set_storeUsername(e.target.checked)}
              />
            }
            label="Onthoud mij"
          />
          <AlertMessage messageId={messageId}/>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{backgroundColor: "#5dc2d7", marginBottom: "20px", marginTop: "20px"}}
          >
            Log In
          </Button>
          <Grid container style={{marginBottom: "20px"}}>
            <Grid item xs>
              <Link href="forgot" variant="body2">
                Wachtwoord vergeten?
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={handleClick} variant="body2" style={{cursor: 'pointer'}}>
                {"Problemen met inloggen?"}
              </Link>
              <div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography style={{margin: "20px", color: "#1976d2"}}>
                    Bel met kantoor: {officePhoneNumber}
                  </Typography>
                </Popover>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
