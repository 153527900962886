import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Icon, Table } from "semantic-ui-react";
import dateParser from "../../dateParser";
import { videoOnServer } from "../../types/player";
import AdminCellHealth from "./AdminCellHealth";

interface PlayerDetailsCustomerInfoProps{
  data: any
  serverVideo: videoOnServer[]
}

export default function PlayerDetailsCustomerInfo(props:PlayerDetailsCustomerInfoProps) {
  const [age, set_age] = useState("Deze player is nieuw");
  const navigate = useNavigate()
  useEffect(() => {
    let ageArr:number[] = [];
    if (
      props.data &&
      props.data.PiplayerOpeningsHistoryDays &&
      props.data.PiplayerOpeningsHistoryDays[0]
    ) {
      Object.keys(props.data.PiplayerOpeningsHistoryDays[0]).forEach(function (
        count
      ) {
        //@ts-ignore
        ageArr.push(parseInt(props.data.PiplayerOpeningsHistoryDays[0][count]));
      });
    }
    const helloCount = ageArr.reduce((a, b) => a + b, 0);
    const dayCount = Math.round((helloCount * 4) / 60 / 24);
    const Count = `${Math.floor(dayCount / 365)} jaar en  ${Math.floor(
      dayCount % 365
    )} dagen `;
    set_age(Count);
  }, [props.data]);

  if (!props.data) return null;

  const generalCell = (header:string, info:string|JSX.Element|number, key?:number, link?:string) => {
    return (
      <Table.Row key={key||header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        { !link ? 
          <Table.Cell style={{ padding: ".28571429em" }}>{info}</Table.Cell> :
          <Table.Cell style={{ padding: ".28571429em" }} onClick={()=> navigate(link)}>{info}</Table.Cell> }
        { link ?  <Table.Cell style={{ padding: ".28571429em" }} onClick={()=> navigate(link)} > <div style={{ padding: ".28571429em", display: "flex", justifyContent: "center" }} onClick={()=> navigate(link)}> <Icon name='film'   /></div> </Table.Cell> :  <Table.Cell style={{ padding: ".28571429em" }}></Table.Cell>  }
      </Table.Row>
    );
  };
  const hotCell = (header:string, info:number) => {
    let warning = info >= 10 ? true : false;

    return (
      <Table.Row key={header}>
        <Table.Cell negative={warning} style={{ padding: ".28571429em" }}>
          {header}
        </Table.Cell>
        <Table.Cell negative={warning} style={{ padding: ".28571429em" }}>
          {info}
        </Table.Cell>
      </Table.Row>
    );
  };
  const playerVersionCell = (header:string, info:string) => {
    let data = info;
    data = info === "1200" ? "Raspberry pi 3B" : data;
    data = info === "1400" ? "Raspberry pi 3B+" : data;
    data = info === "1500" ? "Raspberry pi 4B" : data;
    return generalCell(header, data);
  };
  const PlayingCell = (header:string, info:videoOnServer[]|string) => {
    if (Array.isArray(info)) {
      return info.map((f,i) => {
        const name = f.filename;
        const split = name.split("/");
        return generalCell(
          header,
          makeListOfPlayingItems(split[split.length - 1]),
          i,
          `/playerStorage/${f.id}`
        );
      });
      
    }
    let data = info.replace(
      "omxplayer_-o_hdmi_--loop_-b_--no-osd_/home/Heiba/Player/",
      ""
    );
    return generalCell(header, makeListOfPlayingItems(data));
  };

  const makeListOfPlayingItems = (items:string):JSX.Element => {
    return (
      <ul>
        {items
          .split(".mp4")
          .map((v, i) => (v.length === 0 ? null : <li key={i}>{v}.mp4</li>))}
      </ul>
    );
  };
  return (
    <div >
    {/* <div style={{ width: "50%" }}> */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Item</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Info</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em" }}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
        {props.data.id ? generalCell("MemberId", props.data.id) : null}
          {props.data.username
            ? generalCell("Username", props.data.username)
            : null}
          {props.data.company
            ? generalCell("Company", props.data.company)
            : null}
          {props.data.contact
            ? generalCell("Contactpersoon", props.data.contact)
            : null}
          {props.data.category
            ? generalCell("Categorie", props.data.category)
            : null}
          {props.data.directory
            ? generalCell("Klant map", props.data.directory)
            : null}
          {props?.data?.MemberAccountmanager?.Accountmanager?.name
            ? generalCell("Accountmanager", props?.data?.MemberAccountmanager?.Accountmanager?.name)
            : null}
          {props.data.visit
            ? generalCell("Laatste bezoek", props.data.visit)
            : null}
          {props.data.visit
            ? generalCell("Aantal bezoeken", props.data.amount)
            : null}
          {props.data.class
            ? generalCell("Type gebruiker", props.data.class)
            : null}
          {props.data.HQ ? generalCell("Hoofdvestiging", props.data.HQ) : null}
          {props.data.subscription
            ? generalCell("Heeft een abo", props.data.subscription)
            : null}
          {props.data.live ? generalCell("Livedag", props.data.live) : null}
                 
          {props.data["MAC adres"]
            ? generalCell("Mac", props.data["MAC adres"])
            : null}
          {props.data.Link
            ? generalCell("Kijkt naar map", props.data.Link)
            : null}
          {props.data.piplayerHealth && props.data.piplayerHealth.id
            ? generalCell("CustomerId", props.data.piplayerHealth.id)
            : null}
          {props.data.piplayerHealth && props.data.piplayerHealth.health
            ? generalCell("Gezondheid", <AdminCellHealth player={props.data} />)
            : null}

          {props.data.PIplayerStatus && props.data.PIplayerStatus.version
            ? generalCell("Software Versie", props.data.PIplayerStatus.version)
            : null}
          {props.data.PIplayerStatus && props.data.PIplayerStatus.cpufreq
            ? playerVersionCell(
                "Hardware versie Cpu",
                props.data.PIplayerStatus.cpufreq
              )
            : null}

          {props.data.PIplayerStatus && props.data.PIplayerStatus.uptime
            ? generalCell("Uptime", props.data.PIplayerStatus.uptime)
            : null}
          {props.data.PiplayerOpeningsHistoryDays
            ? generalCell("Geschatte leeftijd", age)
            : null}
          {props.data.LastSeen
            ? generalCell("Laatst gezien", dateParser(props.data.LastSeen))
            : null}
          {props.data.PIplayerStatus && props.data.PIplayerStatus.playlist
            ? PlayingCell("Playing", props.data.PIplayerStatus.playlist)
            : null}
          {props.serverVideo && props.serverVideo.length
            ? PlayingCell("Op de server", props.serverVideo)
            : null}
          {props.data.WaitingnumberHardware &&
          props.data.WaitingnumberHardware.id
            ? generalCell("Nummerautomaat", "Yes")
            : props.data.WaitingnumberHardware &&
            generalCell("Nummerautomaat", "No")}
          {props?.data?.awsssm && props?.data?.awsssm?.ssmInstanceId 
            ? generalCell("Aws onboarded", props?.data?.awsssm?.ssmInstanceId)
            : props?.data["MAC adres"] ? generalCell("Aws onboarded", "No") : null }
          {props.data.PiplayerTemps && props.data.PiplayerTemps.length >= 1
            ? hotCell("Aantal keer te warm", props.data.PiplayerTemps[0].count)
            : null}
        </Table.Body>
      </Table>
    </div>
  );
}
