import React from "react";
import { Table } from "semantic-ui-react";
import dateParser from "../../dateParser";
import {  playerLocations } from "../../types/player";

interface  PlayerDetailsLocationInfoProps{
  data: playerLocations[] | undefined
}

export default function PlayerDetailsLocationInfo(props:PlayerDetailsLocationInfoProps) {
  if (!props.data) return null;
  return (
    <div style={{ width: "50%" }}>
       <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>MemberId</Table.HeaderCell>
            <Table.HeaderCell>FirstSeen</Table.HeaderCell>
            <Table.HeaderCell>LastSeen</Table.HeaderCell>
             <Table.HeaderCell>Times</Table.HeaderCell>
             <Table.HeaderCell>Ip</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props?.data?.map((l, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  <div>{l.memberId}</div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{width: "92px"}}>{dateParser(l.initialDate , "date")}</div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{width: "92px"}}>{dateParser(l.lastSeenDate, "date")}</div>
                </Table.Cell>
               <Table.Cell>
                  <div>{l.seenCount}</div>
                </Table.Cell> 
               <Table.Cell>
                  <div>{l.sourceIP}</div>
                </Table.Cell> 
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
