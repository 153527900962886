import React from 'react'
import { useNavigate } from 'react-router-dom'
import { player } from '../../types/player'

interface AdminCellIdProps{
    player: player
    
}


export default function AdminCellId(props:AdminCellIdProps):JSX.Element {
    const navigate = useNavigate()
    
    return (
        <div onClick={e => navigate(`/players/${props.player['MAC adres']}/${props.player.piplayerHealth.id}`)} style={{cursor: 'pointer'}}>
            {props.player.piplayerHealth.id}
        </div>
    )
}
