import React, { useEffect, useState, Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Segment, Table } from "semantic-ui-react";
import WeekdayTogggle from "./WeekdayTogggle";
import getDate from "../../getDate";
import LoadingIndicator  from "../LoadingIndicator";
import { selectGeneralLivedays, selectLivedays } from "../../actions/selectors";
import { getWeek } from "../../getWeek";
import * as Sentry from "@sentry/browser"

interface LivedaysSchedulerProps{
  getLivedays: Function
  getGeneralLiveDay: Function
  addGeneralLiveDay: Function
  scheduleType: "PIPlayerCopyDays" | "WebPlayerCopyDays"
  ignoreType: "PIPlayerCopyDayIgnores" | "WebPlayerCopyDayIgnores"
  extraType:  "PIPlayerCopyDayExtras" | "WebPlayerCopyDayExtras" 
  addIgnoreDay: Function
}

export default function LivedaysScheduler(props:LivedaysSchedulerProps) {
  const livedays = useAppSelector(selectLivedays)
  const generalLiveDay = useAppSelector(selectGeneralLivedays);
  const [dateSelector, set_dateSelector] = useState({ week: getWeek(new Date())+1, 
                                                      year: new Date().getFullYear() })
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    dispatch(props.getLivedays(dateSelector?.week, dateSelector?.year));
    dispatch(props.getGeneralLiveDay(dateSelector?.week, dateSelector?.year));
// 
  }, [dispatch, dateSelector, props]);

  const weekHandlerIncrease = () => {
    const selectedYear = new Date(dateSelector.year -1,11,31)
    //yeah minus one here is strange, get week needs to be fixed
    if( getWeek(selectedYear) === 1 || getWeek(selectedYear) === 52 ){
      if((dateSelector.week + 1 ) <= 52) {
        return set_dateSelector({...dateSelector, week: dateSelector.week + 1})
      }else{
        return set_dateSelector({week: 1, year: dateSelector.year + 1})
      }
    } 
    if( ((dateSelector.week + 1 ) <= 53) ){
      return set_dateSelector({...dateSelector, week: dateSelector.week + 1})
    }else{
      return set_dateSelector({week: 1, year: dateSelector.year + 1} )
    }
  }
  const weekHandlerDecrease = () => {
    //yeah minus one here is strange, get week needs to be fixed
    // year minus two is needed, we need to know the week count of last year. 
    const selectedYear = new Date(dateSelector.year -2,11,31)
    if( dateSelector.week -1  ===  0){
      // year has 52 weeks
      if(getWeek(selectedYear) === 1 || getWeek(selectedYear) === 52) {
        return set_dateSelector({...dateSelector, year: dateSelector.year - 1, week: 52})
      }
      else{
        // year has 53 weeks
        return set_dateSelector({...dateSelector, year: dateSelector.year - 1, week: 53})
      }
    }else{
        return set_dateSelector({...dateSelector, week: dateSelector.week - 1})
    }
  }
  if (!livedays || livedays.length < 1) return <LoadingIndicator loadingText="Livedagen laden"/>
  return (
    <Fragment>
      <button className="adminButton" onClick={(e) => set_dateSelector({...dateSelector, year: dateSelector?.year - 1})} style={{cursor: 'pointer'}}>
        Vorig jaar
      </button>
      <button className="adminButton" onClick={weekHandlerDecrease} style={{cursor: 'pointer'}}>
        Vorige week
      </button>
      <label> {`week: ${dateSelector?.week} (${dateSelector?.year})`}</label>
      <button className="adminButton" onClick={weekHandlerIncrease} style={{cursor: 'pointer'}}>
        Volgende week
      </button>
      <button className="adminButton" onClick={(e) => set_dateSelector({...dateSelector, year: dateSelector?.year + 1})} style={{cursor: 'pointer'}}>
        Volgend jaar
      </button>

      <div>
      <Segment
      style={{
        overflow: "auto",
        height: "80vh"
        
      }}
    >
        <Table celled unstackable>
          <Table.Header >
            <Table.Row>
              <Table.HeaderCell >Klant</Table.HeaderCell>
              <Table.HeaderCell >Folder</Table.HeaderCell>
              <Table.HeaderCell >Ma</Table.HeaderCell>
              <Table.HeaderCell>DI</Table.HeaderCell>
              <Table.HeaderCell>Wo</Table.HeaderCell>
              <Table.HeaderCell>Do</Table.HeaderCell>
              <Table.HeaderCell>Vrij</Table.HeaderCell>
              <Table.HeaderCell>Za</Table.HeaderCell>
              <Table.HeaderCell>Zo</Table.HeaderCell>
            </Table.Row>
            <Table.Row> 
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell ></Table.HeaderCell>  
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Monday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Tuesday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Wednesday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Thursday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Friday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Saturday")}</Table.HeaderCell>
              <Table.HeaderCell >{getDate(dateSelector?.week,dateSelector?.year,"Sunday")}</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell >Alle klanten</Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Monday"}
                      schedule={"Monday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Tuesday"}
                      schedule={"Tuesday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Wednesday"}
                      schedule={"Wednesday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Thursday"}
                      schedule={"Thursday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Friday"}
                      schedule={"Friday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Saturday"}
                      schedule={"Saturday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
              <Table.HeaderCell ><WeekdayTogggle
                      day={"Sunday"}
                      schedule={"Sunday"}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      generalDay={true}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    /></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {livedays?.map((c) => {
              return (
                <Table.Row key={c.id}>
                  <Table.Cell>{`${c.company}( ${c.id}) `}</Table.Cell>
                  <Table.Cell>{c.directory}</Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Monday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Tuesday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Wednesday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                      />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Thursday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Friday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      addIgnoreDay={props.addIgnoreDay}
                      generalLiveDay={generalLiveDay}
                      addGeneralLiveDay={props.addGeneralLiveDay}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Saturday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <WeekdayTogggle
                      day={"Sunday"}
                      schedule={c[props.scheduleType]}
                      week={dateSelector?.week}
                      year={dateSelector?.year}
                      member={c}
                      ignores={c[props.ignoreType]}
                      extras={c[props.extraType]}
                      generalLiveDay={generalLiveDay}
                      addIgnoreDay={props.addIgnoreDay}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
    </Segment>
      </div>
    </Fragment>
  );
}

