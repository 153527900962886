import { SET_WEEKSELECT, SET_HEIBAWEEKS, RESET_WEEKINPUT, ACTIVATE_VIDEOTYPE, WEEKSELECT, FOCUSPRODUCT, AMBIANCE, SEASON, EXTRAINFO, UPLOADEDFILES, FACEBOOK } from "../actions/actionCreators";
import { heibaweek } from "../types/heibaweek";
import { productActivation } from "../types/productActivation";
import { upload, weekInput } from "../types/weekinput";

const initialState = {
  weekNumber: 0,
  focusProduct: "",
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1, 
  season: "",
  ambiance: "",
  extraInfo: "",
  uploadedFiles: [],
  cm01: "",
  cm02: "",
  status: 0,
  festiveDays: [],
  heibaWeeks: [],
  facebook: "",
  type: null 
};

export interface videoInputType{
  heibaWeeks:  heibaweek[]
  uploadedFiles: upload[]
  facebook?: string | null
  type?: null | "facebook"
  weekNumber: number
  focusProduct: string,
  year:number
  month: number, 
  season: string,
  ambiance: string,
  extraInfo: string,
  cm01: string,
  cm02: string,
  status: number
}

export type videoInputTypeAction = 
| {type:  typeof SET_WEEKSELECT, payload: weekInput}
| {type:  typeof WEEKSELECT, payload: number}
| {type:  typeof FOCUSPRODUCT, payload: string}
| {type:  typeof AMBIANCE,  payload: string} 
| {type:  typeof SEASON ,  payload: string} 
| {type:  typeof SET_HEIBAWEEKS, payload: heibaweek[]}
| {type:  typeof EXTRAINFO ,  payload: string}
| {type:  typeof FACEBOOK,  payload: string}
| {type:  typeof UPLOADEDFILES}
| {type:  typeof ACTIVATE_VIDEOTYPE, payload: productActivation}
| {type:  typeof RESET_WEEKINPUT}

const reducer =  (state:videoInputType= initialState, action:videoInputTypeAction) => {
  switch (action.type) {
    case SET_WEEKSELECT:
      if(action?.payload?.week){
        if(action.payload.type === "facebook"){
          return {
            ...state,
            facebook: action.payload.pf
          };
        }
        else{
          return { ...state,
                weekNumber: action.payload.week,
                year: action.payload.year,
                focusProduct: action.payload.pf,
                season: action.payload.ss,
                ambiance: action.payload.hs,
                extraInfo: action.payload.ei,
                uploadedFiles: action.payload.uploads,
                cm01: action.payload.cm01,
                cm02: action.payload.cm02,
                facebook: action.payload.facebook,
                status: action.payload.status,
                type: action.payload.type 
          };
        }
      }
      else{
        return state
      }
        
    case WEEKSELECT:
      return { ...state, weekNumber: action.payload };
    case FOCUSPRODUCT:
      return { ...state, focusProduct: action.payload };
    case AMBIANCE:
      return { ...state, ambiance: action.payload };
    case SEASON:
      return { ...state, season: action.payload };
    case EXTRAINFO:
      return { ...state, extraInfo: action.payload };
    case FACEBOOK:
      return { ...state, facebook: action.payload };
    case SET_HEIBAWEEKS:
      return {...state, 
        heibaWeeks: action.payload}
    
    case ACTIVATE_VIDEOTYPE:
      const heibaWeeks = state.heibaWeeks.map( week => {
        if(week.id === action.payload.id && week.type === action.payload.type ){
          const newWeek = {...week}
          newWeek.active = !newWeek.active
          return newWeek
        }
        return week
      })
      return {...state, heibaWeeks}    
    case RESET_WEEKINPUT:
        return initialState
    default:
      return state;
  }
};


export default reducer