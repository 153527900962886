import React from "react";

export default function Gallery() {
  return (
    <section className="gallery-area">
      <div className="gallery-wrapper">
        <div className="gallery-item">
          <img src="img/g-1.png" alt="" />
        </div>
        <div className="gallery-item">
          <img src="img/g-2.png" alt="" />
        </div>
        <div className="gallery-item">
          <img src="img/g-7.png" alt="" />
        </div>
        <div className="gallery-item">
          <img src="img/g-4.png" alt="" />
        </div>
        <div className="gallery-item">
          <img src="img/g-5.png" alt="" />
        </div>
        <div className="gallery-item">
          <img src="img/g-6.png" alt="" />
        </div>
      </div>
    </section>
  );
}
