import {
  SET_MEMBERS,
  SET_VIDEOINPUT,
  SET_VIDEOINPUT_BY_ID,
  SET_PLAYERS,
  SET_CUSTOMER_DETAILS,
  SET_PLAYER_DETAILS,
  SET_PLAYER_HISTORY,
  SET_PLAYER_SERVER,
  SET_YEAR_INPUT,
  SET_ACCOUNTMANAGER,
  SET_LIVEDAYS,
  SET_NEW_PLAYERS,
  SET_VIDEOINPUT_STATS,
  SET_PLAYERLESSBUTTONS,
  SET_GENERAL_LIVEDAYS,
  SET_MALFUNCTIONS,
  DASHBOARD_COUNT,
  DAY_SUMMARY,
  TODOLIST,
  INSTALLATION_MEDIA,
  SET_PLAYER_SWITCH,
  VIDEO_ON_SERVER,
  SET_FTP_MEMBERS,
  SET_PLAYER_DOWNLOAD,
  SET_SUMMARY_GRAPH,
  SET_IN_PLAYERFOLDER,
  SET_MEMBER_LOCATIONS,
  SET_OLD_PLAYERS,
  SET_PLAYER_LOCATIONS,
  SET_SERVER_DOWNLOAD_LOGS,
  SET_BASENETSHOP,
  SET_PLAYER_STORAGE_INFO,
  SET_MEMBER_COPYDAYS,
  SET_NIGHT_PLAYERS,
  SET_CONSTRUCTION_ITEM,
  SET_MEDIA_TAGS,
  SET_PLAYER_SSM_DETAILS,
  SET_BASE_CONTENT,
  SET_MANAGING_MEMBER,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_OPTIONS
} from "../actions/actionCreators";
import { accountmanager } from "../types/acountmanager";
import { baseContentType, constructionMediaItem, constructionVideo } from "../types/constructionMedia";
import { dayResult, daySummary, summaryCount } from "../types/daySummary";
import { ftpMembers } from "../types/ftpMembers";
import { generalLiveday, livedays, PIPlayerCopyDays, WebPBannerCopyDays, WebPlayerCopyDays } from "../types/livedays";
import { malfunction } from "../types/malfunction";
import { managingMember, fullBasenetshop, fullMember, member, memberLocation, serverStatus, yearInput, memberSubscription, heibaSubscription } from "../types/member";
import { inThePlayerFolder, summaryGraph } from "../types/serverInfo";
import { player, playerDownload,serverLogRecords, playerHistory, playerLessbuttons, playerLocations, playerSwitch, videoOnServer, playerSsmInfo } from "../types/player";
import { todo } from "../types/todo";
import { videoInputStats } from "../types/videoInputStats";
import { weekInput } from "../types/weekinput";
import { playerStorageInfo } from "../types/storage";
import { APImembercopydays } from "../types/API";
import { mediaTagType } from "../types/tags";

export interface adminState {
  members?: member[],
  players?: player[],
  connectPlayers?: player[],
  nightPlayers?: player[],
  player?: player,
  accountManagers: accountmanager[], 
  dashboard?: any
  dayResult?: dayResult[],
  weekInput?: weekInput[], 
  weekDetails?: any, 
  member?: any,
  playerHistory?: any,
  playerLocations?: playerLocations[],
  PlayerSwitches?: playerSwitch[],
  server?: any,
  yearInput?: yearInput[],
  legacy?: any,
  livedays?: livedays[],
  videoInputStats?: videoInputStats[],
  playerLessButtons?: playerLessbuttons[], 
  generalLiveday?: any,
  malfunctions?: any,
  dadashboard?: any,
  todo?: any,
  installationMedia?: any, 
  serverVideo?: any,
  ftpMembers?: any,
  playerDownloads?: playerDownload[],
  serverLogRecords?: serverLogRecords[], 
  summaryGraph?: any,
  playerFolders?: inThePlayerFolder[],
  summaryCount?: summaryCount[],
  customerLocations?: memberLocation[]
  basenetshop?: fullBasenetshop
  playerStorageInfo?: playerStorageInfo
  playerCopyday?: PIPlayerCopyDays[]
  playerSsmInfo?: playerSsmInfo 
  webplayercopyday?: WebPlayerCopyDays[]
  webBannercopyday?: WebPBannerCopyDays[] //TODO this is incorrect
  constructionItem?: constructionMediaItem 
  mediaTags?: mediaTagType[]
  baseContent?: baseContentType[]
  managingMembers?: managingMember[]
  memberSubscriptions?: memberSubscription[]
  memberSubscriptionOptions?:  heibaSubscription[]
}

export type adminActionTypes = 
  | { type: typeof SET_MEMBERS, payload: member[]}
  | { type: typeof SET_VIDEOINPUT, payload: weekInput[] }
  | { type: typeof SET_VIDEOINPUT_BY_ID, payload: weekInput}
  | { type: typeof SET_PLAYERS, payload: player[] }
  | { type: typeof SET_NEW_PLAYERS, payload: player[] }
  | { type: typeof SET_NIGHT_PLAYERS, payload: player[] }
  | { type: typeof SET_OLD_PLAYERS, payload: player[] }
  | { type: typeof SET_CUSTOMER_DETAILS, payload: fullMember }
  | { type: typeof SET_PLAYER_DETAILS, payload: player}
  | { type: typeof SET_PLAYER_HISTORY , payload: playerHistory[]}
  | { type: typeof SET_PLAYER_LOCATIONS , payload: playerLocations[]}
  | { type: typeof SET_PLAYER_SWITCH, payload: playerSwitch[]}
  | { type: typeof SET_PLAYER_SERVER, payload: serverStatus }
  | { type: typeof SET_YEAR_INPUT, payload: yearInput[]}
  | { type: typeof SET_ACCOUNTMANAGER, payload: accountmanager[] }
  | { type: typeof SET_LIVEDAYS, payload: livedays[]}
  | { type: typeof SET_VIDEOINPUT_STATS, payload: videoInputStats[] }
  | { type: typeof SET_PLAYERLESSBUTTONS, payload: playerLessbuttons[]}
  | { type: typeof SET_GENERAL_LIVEDAYS, payload: generalLiveday[] }
  | { type: typeof SET_MALFUNCTIONS, payload: malfunction[] }
  | { type: typeof DASHBOARD_COUNT, payload: {[key: string]:number} }
  | { type: typeof DAY_SUMMARY, payload:  daySummary[]}
  | { type: typeof TODOLIST, payload: todo[]}
  | { type: typeof INSTALLATION_MEDIA, payload: constructionVideo }
  | { type: typeof VIDEO_ON_SERVER, payload: videoOnServer[]}
  | { type: typeof SET_FTP_MEMBERS, payload: ftpMembers[]}
  | { type: typeof SET_PLAYER_DOWNLOAD, payload: playerDownload[]}
  | { type: typeof SET_SERVER_DOWNLOAD_LOGS, payload: serverLogRecords[]}  
  | { type: typeof SET_SUMMARY_GRAPH, payload: summaryGraph[]}
  | { type: typeof SET_IN_PLAYERFOLDER, payload: inThePlayerFolder[]}
  | { type: typeof SET_MEMBER_LOCATIONS, payload: memberLocation[]}
  | { type: typeof SET_PLAYER_SSM_DETAILS, payload: playerSsmInfo}
  | { type: typeof SET_BASENETSHOP, payload: fullBasenetshop}
  | { type: typeof SET_PLAYER_STORAGE_INFO, payload: playerStorageInfo}
  | { type: typeof SET_MEMBER_COPYDAYS, payload: APImembercopydays}
  | { type: typeof SET_CONSTRUCTION_ITEM, payload: constructionMediaItem}
  | { type: typeof SET_MEDIA_TAGS, payload: mediaTagType[]}
  | { type: typeof SET_BASE_CONTENT, payload: baseContentType[]}
  | { type: typeof SET_MANAGING_MEMBER, payload: managingMember[]}
  | { type: typeof SET_SUBSCRIPTION, payload: memberSubscription[]}
  | { type: typeof SET_SUBSCRIPTION_OPTIONS, payload: heibaSubscription[]}



const initalstate = { members: [] , players: [],connectPlayers: [], accountmanagers: [], dashboard: {}, dayResult: []};
//@ts-ignore //TODO no clue why
const reducer = (state:adminState = initalstate, action:adminActionTypes) => {
  switch (action.type) {
    case SET_MEMBERS:
      return { ...state, members: action.payload };
    case SET_VIDEOINPUT:
      return { ...state, weekInput: action.payload };
    case SET_VIDEOINPUT_BY_ID:
      return { ...state, weekDetails: action.payload };
    case SET_PLAYERS:
      return { ...state, players: action.payload };
    case SET_NEW_PLAYERS:
      return { ...state, connectPlayers: action.payload };
    case SET_NIGHT_PLAYERS:
      return { ...state, nightPlayers: action.payload };
    case SET_OLD_PLAYERS:
      return { ...state, players: action.payload };
    case SET_CUSTOMER_DETAILS:
      return { ...state, member: action.payload };
    case SET_PLAYER_DETAILS:
      return { ...state, player: action.payload };
    case SET_PLAYER_HISTORY:
      return { ...state, playerHistory: action.payload };
    case SET_PLAYER_LOCATIONS:
      return { ...state, playerLocations: action.payload };
    case SET_PLAYER_SWITCH:
      return { ...state, PlayerSwitches: action.payload };
    case SET_PLAYER_SERVER:
      return { ...state, server: action.payload };
    case SET_PLAYER_SSM_DETAILS:
      return { ...state, playerSsmInfo: action.payload };
    case SET_YEAR_INPUT:
      return {...state, yearInput: action.payload};
    case SET_ACCOUNTMANAGER:
      return {...state, accountManagers: action.payload};
    case SET_LIVEDAYS:
      return {...state, livedays: action.payload}
    case SET_VIDEOINPUT_STATS:
      return {...state, videoInputStats: action.payload}
    case SET_PLAYERLESSBUTTONS:
      return {...state, playerLessButtons: action.payload}
    case SET_GENERAL_LIVEDAYS:
        return{ ...state, generalLiveday: action.payload }  
    case SET_MALFUNCTIONS:
        return {...state, malfunctions: action.payload}    
    case DASHBOARD_COUNT:
        return {...state,  dashboard: {...state.dashboard , ...action.payload}}
    case DAY_SUMMARY:
        return {...state,  ...action.payload}
    case TODOLIST:
        return {...state,  todo: action.payload}
    case INSTALLATION_MEDIA:
        return {...state,  installationMedia: action.payload}
    case VIDEO_ON_SERVER:
        return {...state,  serverVideo: action.payload}
    case SET_FTP_MEMBERS:
        return {...state,  ftpMembers: action.payload}
    case SET_PLAYER_DOWNLOAD:
        return {...state,  playerDownloads: action.payload}
    case SET_SERVER_DOWNLOAD_LOGS:
        return {...state,  serverLogRecords: action.payload}
    case SET_SUMMARY_GRAPH:
        return {...state,  summaryGraph: action.payload}
    case SET_IN_PLAYERFOLDER:
        return {...state,  playerFolders: action.payload}
    case SET_MEMBER_LOCATIONS:
        return {...state,  customerLocations: action.payload}
    case SET_BASENETSHOP:
        return {...state,  basenetshop: action.payload}
    case SET_PLAYER_STORAGE_INFO:
        return {...state,  playerStorageInfo: action.payload}
    case SET_MEMBER_COPYDAYS:
        return {...state,  
          playerCopyday: action.payload.playerCopyday,
          webplayercopyday: action.payload.webplayercopyday,
          webBannercopyday: action.payload.webBannercopyday
        }
    case SET_CONSTRUCTION_ITEM:
      return {...state,  constructionItem: action.payload}
    case SET_MEDIA_TAGS:
      return {...state,  mediaTags: action.payload}
    case SET_BASE_CONTENT:
      return {...state,  baseContent: action.payload}
    case SET_MANAGING_MEMBER:
      return {...state,  managingMembers: action.payload}
    case SET_SUBSCRIPTION:
      return {...state,  memberSubscriptions: action.payload}
    case SET_SUBSCRIPTION_OPTIONS:
      return {...state,  memberSubscriptionOptions: action.payload}
    default:
      return state;
  }
};

export default reducer
