import React, { useState } from "react";
import { Icon, Label } from "semantic-ui-react";
import { removeTagFromConstructionItem } from "../../actions/videoInput";
import { mediaTag, mediaTagType } from "../../types/tags";
import { useAppDispatch } from "../../hooks/redux";

interface AdminMediaTagProps {
  mediaTag?: mediaTag 
  mediaTagType?: mediaTagType
  removeable?: boolean
  cb?: (event: React.MouseEvent<HTMLElement, MouseEvent>, d:any) => void
}

export default function AdminMediaTag({mediaTag,mediaTagType,cb}: AdminMediaTagProps) {
  const [showRemoveIcon, set_showRemoveIcon] = useState(false);
  const dispatch = useAppDispatch();
  const unhideRemoveIcon = (e: React.MouseEvent<HTMLElement, MouseEvent>, d:any ) => {

    if (mediaTag && !cb) {
      return set_showRemoveIcon(!showRemoveIcon);
    }
    if(cb){
      return cb(e,d)
    }
  };
  const removeTagLink = () => {
    if(mediaTag){
        dispatch(removeTagFromConstructionItem(mediaTag.id ))
      }
  }
 
  if(mediaTagType){
      if(!mediaTagType?.name) return null
    return (
      <Label as="a" tag color={mediaTagType?.color} onClick={cb ? cb : undefined}>
        {mediaTagType?.contentRightsLockTag ? <Icon name="lock" /> : null}
        {mediaTagType?.name}
      </Label>
    );
  }else if(mediaTag){
    return (
        <Label as="a" tag color={mediaTag?.tagType?.color} onClick={unhideRemoveIcon} name={mediaTag?.tagType?.name}>
          {mediaTag?.tagType?.contentRightsLockTag ? <Icon name="lock" /> : null}
          {mediaTag?.tagType?.name}
          {showRemoveIcon ? (
            <Icon name="trash alternate outline"  onClick={removeTagLink} />
          ) : null}
        </Label>
      );
  }
  return null;
}
