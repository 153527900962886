import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"

interface NumberBoxOfficeAdminProps {
  selectorFn?: boolean,
  selector: Function,
  click: Function,
  actionValue?: string |  number,
  action: Function,
  title: string
}

export default function NumberBoxOfficeAdmin(props:NumberBoxOfficeAdminProps) {
  const dispatch = useAppDispatch();
    const count = useAppSelector( props.selectorFn ? props.selector(props.actionValue) : props.selector);
 
    useEffect(() => {
    dispatch(props.action(props.actionValue));
  }, [dispatch, props]);

  const clickHandler = () => {
    if( typeof(props.click) === "function" && props.selectorFn){
      dispatch(props.click(props.actionValue))
    }
    else{
      props.click()
    }

  }


  return (
    <div
      onClick={clickHandler} 
      className="officeItem"
    >
      <p style={{ fontSize: "5em", marginTop: "0.5em", fontWeight: "bold" }}>
        {" "}
        {count}
      </p>
      <p style={{ fontSize: "2em", marginBottom: "1em", fontWeight: "bold", marginTop: "2em"  }}>{props.title}</p>
    </div>
  );
}
