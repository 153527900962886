import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Label } from "semantic-ui-react";
import {
  addTagToConstructionItem,
  getConstructionItem,
  getMediaTags,
} from "../../actions/videoInput";
import { selectConstructionItem, selectMediaTags } from "../../actions/selectors";
import { constructionMediaType } from "../../types/constructionMedia";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import AdminMediaTag from "../../components/admin/AdminMediaTag";
import HeibaLibaryItem from "../../components/admin/HeibaLibaryItem";
import OverlayImage from "../../components/OverlayImage";
import { useAppDispatch,useAppSelector } from "../../hooks/redux";


export default function HeibaLibaryInfo() {
  const constructionItem = useAppSelector(selectConstructionItem);
  const mediaTags = useAppSelector(selectMediaTags);
  const dispatch = useAppDispatch();
  const [selectedMediaTag, set_selectedMediaTag] = useState(0)
  const [showLarge, set_showLarge] = useState(false);
  const [imgUrl, set_imgUrl] = useState("");
  //todo here, add versions in the mete table

  type paramTypes = {id: string, mediaType: constructionMediaType}
  const {id, mediaType} =  useParams<paramTypes>()
  useEffect(() => {
    dispatch(getMediaTags())
    //TODO can this not be done in one call?
    if(id && mediaType){
      dispatch(getConstructionItem(Number(id), mediaType));
    }
  }, [mediaType, dispatch, id]);

  const addTagtoMediaItem = () => {
    if(id && mediaType){
      dispatch(addTagToConstructionItem(id, selectedMediaTag, mediaType))
    }
  }
  if(!mediaType) return <p>Missing mediaType</p>
  if(!constructionItem) return <LoadingIndicator loadingText="Media item laden" />
  const mediaTagOptions = mediaTags?.map((tag) => { return {key: tag.id , value: tag.id, text: `${tag.name}`} })
  return (
    <div>
       {showLarge ?   
      <OverlayImage
          src={imgUrl}
          showLarge={set_showLarge}
        /> : null }

      <p>mediaType: {mediaType}</p>
      {/* <p>Gebruikt in week 2022: {weeks.map(w => w.week + ", ")} </p>  */}
      <p>id: {constructionItem.id} </p>
      <p>name: {constructionItem.name} </p>
      <p>description: {constructionItem.description} </p>
      <p>category: {constructionItem.category} </p>
      <p>Thumbnail: {constructionItem?.thumbnail} </p>
      <p>link: {constructionItem.link} </p>
      <p>quality's available:

      <ul>

      {constructionItem?.constructionVideoMeta?.map(quality => <li> <a  href={quality?.url} 
                                                                        target="_blank"
                                                                        rel="noreferrer">{quality?.stream_1_width }</a></li> )}
      </ul>
      </p>
      {constructionItem?.mediaTags?.map( (tag:any) => { 
        return (
          <AdminMediaTag mediaTag={tag}/> )
      })
      }
      <br/>
      <Label.Group>
        <Form onSubmit={addTagtoMediaItem}>
          <Dropdown
            placeholder={`Selecteer een tag`}
            fluid
            search
            selection
            //@ts-ignore
            onChange={(e, f) => set_selectedMediaTag(f.value)} 
            options={mediaTagOptions}
          />
        </Form>
      </Label.Group>
      <Button.Group floated="right">
        <Button color="orange" onClick={addTagtoMediaItem} >Voeg toe</Button>
      </Button.Group> 
      <HeibaLibaryItem
        mediaItem={constructionItem}
        set_showLarge={set_showLarge}
        set_imgUrl={set_imgUrl}
        showLarge={showLarge}
        mediaType={mediaType}
      />
    </div>
  );
}
