import React from "react";

interface PreviewButtonsProps{
  data: {id: number},
  disabled: number
  handleSubmit: Function
}

export default function PreviewButtons(props:PreviewButtonsProps):JSX.Element {
  const clickRework = () => {
    props.handleSubmit(props.data.id, null, true)
  }
  const clickAccept = () => {
    props.handleSubmit(props.data.id, true)
  }
  if (props.disabled >= 1) {
    return (
      <div className="previewButtons">
        <button className="buttonAcceptBlocked" style={{cursor: 'pointer'}}> {"Akkoord >"}</button>
        <button onClick={clickRework} className="buttonDecline" style={{cursor: 'pointer'}}> Correctie X </button>
      </div>
    );
  } else {
    return (
      <div className="previewButtons">
        <button onClick={clickAccept} className="buttonAccept" style={{cursor: 'pointer'}}> {"Akkoord >"}</button>
        <button className="buttonDeclineBlocked" style={{cursor: 'pointer'}}> Correctie X </button>
      </div>
    );
  }
}
