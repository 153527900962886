import React, {  useEffect } from "react";
import { getProductMedia } from "../../actions/videoInput";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import PlaceVideo from "./PlaceVideo";
import { selectConstructionVideo } from "../../actions/selectors";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { MediaPlacementLocation } from "./MediaPlacementLocation";
import { LibaryMediaType } from "../../types/constructionMedia";


interface videoPlacementProps{
  week: number
  year: number
  type: LibaryMediaType
  set_week(a: number): void
  set_year(a: number): void
}

export default function VideoPlacement(props:videoPlacementProps) {
  const constructionVideos = useAppSelector(selectConstructionVideo);
 
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProductMedia(props.week, props.year, props.type, true));
  }, [props.week, props.type, props.year, dispatch]);

  const complete =
    constructionVideos.length === 6 ? "compleet" : "niet compleet";
  const boolComplete = constructionVideos.length === 6 ? true : false;

  return (
    <div className="videoPlacement">
      <h1>Product/sfeer video selectie ({props.year})</h1>
      <p> Selecteer hier welke video gebruikt worden voor de komende weken</p>
      <button className="adminButton" onClick={e => props.set_week(props.week - 1)} style={{cursor: 'pointer'}}>Vorige week</button>
      <button className="adminButton" onClick={e => props.set_week(props.week + 1)} style={{cursor: 'pointer'}}>Volgende week</button>
      <button className="adminButton" onClick={e => props.set_year(props.year - 1)} style={{cursor: 'pointer'}}>Vorige jaar</button>
      <button className="adminButton" onClick={e => props.set_year(props.year + 1)} style={{cursor: 'pointer'}}>Volgende jaar</button>
      <p>Week {props.week} {props.type} is {complete}</p>
      <DndProvider backend={HTML5Backend}>
      <div className="videoPlacementCards">
          <MediaPlacementLocation step={1}/>
          <MediaPlacementLocation step={2}/>
          <MediaPlacementLocation step={3}/>
          <MediaPlacementLocation step={4}/>
          <MediaPlacementLocation step={5}/>
          <MediaPlacementLocation step={6}/>
        </div>
        </DndProvider>
        <PlaceVideo
          complete={boolComplete}
          week={props.week}
          year={props.year}
          category={props.type}
        />
    </div>
  );
}
