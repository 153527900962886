import React from 'react'
import { useNavigate } from 'react-router-dom'
import { player } from '../../types/player'


interface AddminCellCompanyProps {
    player: player
    
}

export default function AdminCellCompany({player}:AddminCellCompanyProps):JSX.Element {
    const navigate = useNavigate()
    const city = player?.piplayerHealth?.member?.basenetshop?.Woonplaats_postadres || "Onbekend"

    return (
        <div onClick={() => navigate(`/players/${player['MAC adres']}/${player?.piplayerHealth?.id }`)} style={{cursor: 'pointer'}}>
            {player?.piplayerHealth?.member?.company?.replace("%26", "&") + "(" + city  + ")"}
        </div>
    )
}
