import { backendBaseUrl } from "../constants";
import superagent from "superagent"
import { setNewJwt, setInvalidJWT, setAppError } from "./actionCreators";
import * as Sentry from "@sentry/browser"

       
export function generalApiCallSetter( 
                      type: "get"|"post" ,
                      url: string ,
                      setter: any, 
                      partOfResult: any, 
                      body: any) {
    return async (dispatch: Function, getState:Function) => {
      try{
        const data = await generalApiCall(
          type,
          getState,
          dispatch,
          null,
          `${backendBaseUrl}${url}`,
          body
          )
          if(data && data[partOfResult]){
            setter(data[partOfResult])
          }
        }
        catch(err){
          console.error(err)
        }
    };
  }



export async function generalApiCall(
    type: "get"|"post"|"put"|"delete"|"patch",
    getState: Function,
    dispatch: Function,
    actionCreator: Function| null,
    endpoint: string,
    headers?: object,
    file?: any,
    query?: any,
    callback?: Function | null  ) {
    const { jwt } = getState().user;
    if (jwt !== null) {
      try {
        let res;
        await renewInProgress
        if(file){
           res = await superagent[type](endpoint)
            .set("Authorization", "Bearer " + jwt)
            .set(headers? headers : {})
            .attach("contentUpload", file)
        }else{
          res = await superagent[type](endpoint)
          .set("Authorization", "Bearer " + jwt)
          .query(query)
          .send(headers);
        }
        if (typeof actionCreator !== "function") {
          return res.body;
        } else {
          dispatch(actionCreator(res.body));
          
        }
        if (typeof callback === "function") {
          callback(0)
        }
      } catch (err:any) {
        if (
          err.response &&
          err.response.body &&
          err.response.body.error === "jwt expired"
        ) {
          const resultRenewJwt = await renewJwt(getState, dispatch);
          if (resultRenewJwt) {
            //@ts-ignore
            generalApiCall(...arguments);
          }else{
            dispatch(setAppError("Authenticatie mislukt, log opnieuw in"))
            Sentry.captureMessage(`SHOULD NOT SEEE THIS!!!!!!!!!!! ${endpoint}`)         
          }
        }else if(err?.status === 401 && err?.response?.req?.method !== "GET"){
          //@ts-ignore
          let args =  [...arguments]
          args[0] = "get" // not sure if this works for every request
          //@ts-ignore
          generalApiCall(...args );
        }
        else if(err?.status === 403){
          Sentry.captureMessage("user was did not have proper rights")
          dispatch(setAppError("Je hebt geen rechten om de gevraagde actie uit te voeren"))
        }
        else {
          Sentry.captureException(err);   
          dispatch(setAppError("Kon geen verbinding maken met de server, probeer later opnieuw"))
          if (typeof callback === "function") {
            callback(-1)
          }
        }
      }
    } else {
      Sentry.captureMessage("jwt was null");  
      dispatch({type: "LOGOUT"})
    }
  }


let renewInProgress: null|Promise<boolean> =  null
  
export async function renewJwt(getState:any, dispatch:any) {
    const { jwt, refreshToken } = getState().user;
    if(renewInProgress){
      return await renewInProgress
    }else{
      renewInProgress =  new Promise( (resolve, reject ) => {
    return superagent
      .post(`${backendBaseUrl}/renew`)
      .set("Authorization", "Bearer " + jwt)
      .send({ refreshToken: refreshToken })
      .then(res => {
        if (res.body.error === "RT is invalid") {
          Sentry.captureMessage("the RT was wrong, logout")
          dispatch(setInvalidJWT());
          return false;
        } else {
          dispatch(setNewJwt(res.body.jwt));
          resolve(true)
          renewInProgress =  null
          return true;
        }
      })
      .catch(err => {
        reject(false)
        //could not renew our token
        Sentry.captureException(err)
      });
    })
    return renewInProgress
    }
  }
  