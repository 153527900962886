import React, { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { Icon } from "semantic-ui-react";
import { selectConstructionPhoto } from "../actions/selectors";
import { removeProductMedia } from "../actions/videoInput";
import { constructionPhoto, LibaryMediaType } from "../types/constructionMedia";

interface InputPhotoSuggestionProps{
  week?: number
  year?: number
  admin?: boolean
  type?: LibaryMediaType
  step: number
}


export default function InputPhotoSuggestion(props:InputPhotoSuggestionProps) {
    const media = useAppSelector( selectConstructionPhoto)
    const photosForThisStep = media?.filter( photo => photo.step === props.step)
    const dispatch = useAppDispatch()
    
  const removeFromWeek = (media:constructionPhoto) => {
    if(props.week && props.year && props.type){
      dispatch(removeProductMedia(media.id, props.week, props.year, props.type, "photo"))
    }
  }


  return (
    <div className="focusproductHolder">
      {photosForThisStep?.map((image, i) => {
        if(!image.constructionPhoto) return null
        if(!image.constructionPhoto.link) return null
        return (
          <Fragment key={i}>
            <img src={image.constructionPhoto.link} alt="impression"  style={{width: "100%"}}/>
          {props.admin ? <Icon name="remove circle" size={"large"} style={{cursor: 'pointer'}} onClick={() => removeFromWeek(image.constructionPhoto)}/> : null}
          </Fragment>
        );
      })}
    </div>
  );
}
