import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../hooks/redux";
import { passwordReset } from "../actions/videoInput";
import AlertMessage from "./AlertMessage";

 export interface OverlayProps{
    show: Boolean
    hide?: Function
 }


export default function OverlayPassword(props:OverlayProps) {
  const passwordResetResult = useAppSelector((reduxStore) => reduxStore.appState.passwordReset)
  const dispatch = useAppDispatch() 
  
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [messageId, setMessageId] = useState(0);

  useEffect(()=> {
    if(passwordResetResult?.done === true){
      setMessageId(16)
      const timer = setTimeout(() => {
        setMessageId(0)
      }, 4000);
      return () => clearTimeout(timer);

    }
  }, [passwordResetResult?.done])


  if(!props.show){
        return null
  }   
    

    const buttonHandler = () => {
      // dispatch({type: RESET_WEEKINPUT})
      // navigate(props.to)
      
      if(password === confirmPassword){
        dispatch(passwordReset(password,confirmPassword))
      }else{
        setMessageId(12)
      }

    }

    const hide =() => {
      if(props?.hide ){
        props.hide(false)
      } 

    }

    return (
    <div className="overlay">
      <div className="box bigBox">
          <h3>Wachtwoord resetten</h3>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
          <p>Nieuw password</p>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></input>
          <p>Herhaal password</p>
          <p></p> 
          {/* ugly css quick fix */}
          <button onClick={buttonHandler} style={{cursor: 'pointer'}}>Wijzig </button>
          <h3 onClick={hide }>Annuleren</h3>
          <AlertMessage messageId={messageId}  />
      </div>
    </div>
  );
}
