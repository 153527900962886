import React from "react";
import { Table } from "semantic-ui-react";
import dateParser from "../../dateParser";
import { serverLogRecords } from "../../types/player";

interface PlayerDownloadInfoProps{
  data: serverLogRecords[] | undefined
}
 
export default function PlayerDownloadInfo(props:PlayerDownloadInfoProps) {
  if (!props.data) return null;
  const downloadCell = (header:number, info:serverLogRecords) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.file}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em", minWidth: "150px"}}>{dateParser(info.date)}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.size}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.mbsize}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.time}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.updown}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.user}</Table.Cell>
        {/* <Table.Cell style={{ padding: ".28571429em" }}>{info.hash}</Table.Cell> */}
      </Table.Row>
    );
  };

  return (
    <div >
      <p style={{color: "red"}}>Deze sectie is nog in ontwikkeling, exacte betekenis is nog onbekend</p>
      <p style={{color: "red"}}>Als meerdere players naar dezelfde map kijken, weet je niet welke player de video heeft gedownload</p>
      <Table celled unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Filename</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>Datum</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>FileSize(kb)</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>FileSize(Mb)</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>Time</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>up/down</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em"}}>User</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.data?.map( (download, i ) => {
           return downloadCell(i , download)
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
