import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {  getSubscriptionOptions } from "../../actions/videoInput";
import { Dropdown } from 'semantic-ui-react'
import { selectSubscriptionOptions } from "../../actions/selectors";


interface SubscriptionsOptionSelectProps{
  set_subscription(n:any): void
 }

export default function SubscriptionsOptionSelect(props:SubscriptionsOptionSelectProps) {
  const subscription_list = useAppSelector(selectSubscriptionOptions)
  const dispatch = useAppDispatch();
  useEffect(() => {
      dispatch(getSubscriptionOptions())
  }, [dispatch]);

  const options = subscription_list?.map(so => { return {key: so?.id , value: so.id, text: `${so?.name}`} })

  return (
    <div>
      <Dropdown
        placeholder='Selecteer een abbonnement'
        fluid
        search
        selection
        onChange={(e, f) => props.set_subscription(f.value) }
        options={options}
      />
    </div>
  );
}
