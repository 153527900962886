import React from "react";
import { useNavigate } from "react-router-dom";
import { weekInput } from "../../types/weekinput";

interface PreviewCellProductProps{
  input: weekInput
}

export default function PreviewCellProduct(props:PreviewCellProductProps) {
    const navigate = useNavigate()
  if (!props.input) return null;
 
  return <div onClick={e => navigate(`/office/${props.input.id}`)} style={{cursor: 'pointer'}}>{props.input.pf}</div>;
}
