import React, { useEffect, useState } from 'react'
import { Button, Table } from 'semantic-ui-react';
import { selectUser, selectWebshopProducts } from '../../actions/selectors';
import { getMyWebshopProducts,createWpProduct,deleteWpProduct } from '../../actions/videoInput';
import { backendBaseUrl } from '../../constants';
import { webshopProductType } from '../../types/webshop';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

export default function Webshop() {
    const webshopTemplateHeader   = ["PLU","Naam","Prijs","PrijsEenheid","VerkoopVolume","VerkoopEenheid","Hoofdproduct","Categorie", "subproduct", "createdAt", "updatedAt", "wpProduct", "", "" ]
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [memberId, set_memberId] = useState<number|null>(null)

    useEffect(()=>{
      if(user){
        set_memberId(user.id)
      }
    },[user])


    useEffect(() => {
      if(memberId){
        dispatch(getMyWebshopProducts(`${memberId}`));
      }
      }, [dispatch, memberId]);

  const products:webshopProductType[] = useAppSelector(selectWebshopProducts) 
  const makeAi = (product:webshopProductType ) => {
    if(memberId){
      dispatch(createWpProduct(product.id,memberId))
    }
  }
  const remove = (product:webshopProductType ) => {
    if(memberId){
      dispatch(deleteWpProduct(product.id,memberId))
    }
  }
  return (
    <div>Webshop My products

      <Table celled>
        <Table.Header>
          <Table.Row>
            {webshopTemplateHeader?.map((header,i) => <Table.HeaderCell key={i}>{header}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {products?.map((webshopProduct, i) => {
            console.log("websh", webshopProduct)
            return (
              <Table.Row key={i}>
                <Table.Cell>{webshopProduct?.plu}</Table.Cell>
                <Table.Cell>{webshopProduct?.name}</Table.Cell>
                <Table.Cell>{webshopProduct?.price}</Table.Cell>
                <Table.Cell>{webshopProduct?.priceUnit}</Table.Cell>
                <Table.Cell>{webshopProduct?.salesUnit}</Table.Cell>
                <Table.Cell>{webshopProduct?.salesVolume}</Table.Cell>
                <Table.Cell>{webshopProduct?.masterProduct}</Table.Cell>
                <Table.Cell>{webshopProduct?.categories}</Table.Cell>
                <Table.Cell>{webshopProduct?.subproduct ? "ja" : ""}</Table.Cell>
                <Table.Cell>{webshopProduct?.createdAt}</Table.Cell>
                <Table.Cell>{webshopProduct?.updatedAt}</Table.Cell>
                <Table.Cell>{webshopProduct?.webshopWpProduct?.id ? "Done": "todo"}</Table.Cell>
                <Table.Cell> { !webshopProduct?.webshopWpProduct?.id ? <Button onClick={()=> makeAi(webshopProduct)}>Create wp Prod</Button>: null}</Table.Cell>
                <Table.Cell> <Button onClick={()=> remove(webshopProduct)}>Delete product</Button></Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <p> Download a sample template file <a target="_blank" rel="noreferrer" href={`${backendBaseUrl}/webshopTemplate`}> here </a> </p>
      <p> Download a wordpress import file <a target="_blank" rel="noreferrer"  href={`${backendBaseUrl}/webshopProductsExport/${memberId}`}> here </a> </p>
      <p> Upload a template file here <a href={`/webshopUpload`}> here </a> </p>
    </div>
  );
}

