import { LOGIN, LOGIN_LIKE, LOGOUT, ME, RENEW_JWT, RENEW_JWT_RT_INVALID,REPLACE_JWT } from "../actions/actionCreators";
import { liveday, user } from "../types/user";


export interface userState extends user {
  logout: boolean
  redirect: boolean
 
}

const initialState = {
  jwt: null,
  logout: false,
  category: "algemeen",
  company: "",
  directory: "",
  id: 0,
  liveday: 'unknown' as liveday,
  name: "",
  refreshToken: null,
  role: "admin",
  redirect: false
};

export type userActionTypes = 
  | {type: typeof LOGIN; payload: user }
  | {type: typeof LOGOUT }
  | {type: typeof ME; payload: user}
  | {type: typeof REPLACE_JWT; payload: {refreshToken: string, jwt: string}}
  | {type: typeof RENEW_JWT; payload: string}
  | {type: typeof RENEW_JWT_RT_INVALID}
  | {type: typeof LOGIN_LIKE; payload: user}

const reducer =  (state:userState = initialState, action:userActionTypes) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload, logout: false };
    case LOGIN_LIKE:
      return { ...state, ...action.payload, logout: false, redirect: true };
    case LOGOUT:
      return { ...initialState,  logout: true };
    case ME:
      return { ...state, ...action.payload, redirect: false };
    case REPLACE_JWT: 
      return { ...state, ...action.payload };
    case RENEW_JWT: 
      return { ...state, jwt: action.payload };
    case RENEW_JWT_RT_INVALID:
      return { ...state, logout: true, jwt: null };
    default:
      return state;
  }
};


export default reducer