import React, { Component } from "react";
import { officePhoneNumber, officePhoneNumberLink } from "../constants";
import SendButton from "../components/SendButton";


export default class Support extends Component {
  state = {
    explanationPortalShow: "none",
    memoryCardShow: "none",
    message: "",
    subject: ""
  };

  explanationPortal = (e: any) => {
    e.preventDefault();
    if (this.state.explanationPortalShow === "none") {
      this.setState({ explanationPortalShow: "inline" });
    } else {
      this.setState({ explanationPortalShow: "none" });
    }
  };

 onChange = (e: any) => {
  this.setState({
    [e.target.name]: e.target.value
  })
}


  render() {
    return (
      <div className="manuals">
        <h1>Handleidingen</h1>
        <ul>
          <li onClick={this.explanationPortal} style={{cursor: 'pointer'}}>Uitleg Klantenportal(video)</li>
          <li><a download href="/manuals/Geheugenkaart_DV_player.pdf"> Geheugenkaart DV Player</a></li>
          <li><a download href="/manuals/LG_Scherm_tijd.pdf"> LG scherm tijd instellen</a></li>
          <li><a download href="/manuals/Sony_Scherm_tijd.pdf"> Sony scherm tijd instellen</a></li>
          <li><a download href="/manuals/Facebook.pdf"> Video op Facebook plaatsen</a></li>
        </ul>

        <div
          className="slidingDiv0"
          style={{ display: this.state.explanationPortalShow }}
        >
          <iframe
            title="Handleiding Portal"
            className="videoIframe"
            frameBorder="0"
            src="https://www.youtube.com/embed/tvwplOU_fIo"
          ></iframe>
          <button onClick={this.explanationPortal} style={{cursor: 'pointer'}}>video verbergen</button>
        </div>

        <div className="questions">
          <h1>Vragen over het gebruik van onze portal?</h1>
          <p>Bel ons tijdens kantoortijden op: <a href={officePhoneNumberLink}>{officePhoneNumber}</a> </p>
        
          <h3>Of stuur ons een bericht:</h3>
          <label >Onderwerp:</label><br/>
          <textarea className="subject"  name="subject" value={this.state.subject} onChange={this.onChange}></textarea>
          <br/>
          <label>Bericht:</label><br/>
          <textarea name="message" value={this.state.message} onChange={this.onChange}></textarea>
          
          <SendButton fieldOne={this.state.subject.length} fieldTwo={this.state.message.length}/>
        </div>


      </div>
    );
  }
}
