import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { SelectAWeek } from "../actions/videoInput";
import OverlayUploadWarning from './OverlayUploadWarning'
import { lowQualityUpload, noUpload } from "../constants";
import { selectVideoInput } from "../actions/selectors";
import { captureMessage } from "@sentry/browser";

interface InputNavigationFeedProps{
  uploadWarning?: boolean
  lock?: boolean
  noContent?: boolean
  setter?: Function 
  prevStep?: number
  nextStep?: number
  week?: any
  done?: any
  disabled?: any
  uploadWait?: boolean
  back?: string
  to: string
}

export default function InputNavigationFeed(props:InputNavigationFeedProps) {
  const [hover, set_hover]= useState(false)
  const navigate = useNavigate()
  const videoInput = useAppSelector(selectVideoInput)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const enter = () => set_hover(true)
  const leave = () => set_hover(false)
  const [showWarning, setShowWarning] = useState(false)
  const [noContentWarning, setNoContentWarning] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const nextHandler = () => {
    if(props.uploadWarning && !props.lock && !accepted){
      setShowWarning(true)
      return
    }
    if(props.noContent && !accepted){
      setNoContentWarning(true)
      return
    }
    if(typeof props.setter === "function"){
      nextText = "Verstuurd!"
      props.setter(true)
    } else if (!props.lock){
      //this is here, when users go to the next step in the process, everything is being send to the backend...
      dispatch(
        //@ts-ignore
        SelectAWeek(
          videoInput?.weekNumber,
          videoInput?.year,
          videoInput?.focusProduct,
          videoInput?.season,
          videoInput?.ambiance,
          videoInput?.extraInfo,
          null, //nf no clue what nf is?
          videoInput?.cm01,
          videoInput?.cm02
        )
      );
      navigate(props.to);
    }
  };
  const backHandler = () => {
    if(props?.back){
      navigate(props.back);
    }else{
      captureMessage("No back route defined")
    }
  };

  if(location.pathname !== "/input" && videoInput?.weekNumber === 0){
    navigate("/input");
  }

  let backText = "Terug naar stap " + props.prevStep;
  // let backStyle = {} 
  // let nextStyle = {};
  let nextText = "Naar uploaden"// + props.nextStep;
  // let nextText = "Naar versturen"// + props.nextStep;

  if (!props.prevStep) {
    backText = "Terug naar week Selecteren";
  }
  if (!props.back) {
    // backStyle = { display: "none" };
  }
  if (props.disabled >= 1) {
    // nextStyle = { backgroundColor: "#ec7026", color: "white" };
  } else {
    if (props.nextStep !== 1) {
      //was !=
      // nextStyle = { backgroundColor: "#70b960", color: "white" };

    }
  }
  if (
    props.week !== 0 &&
    props.week !== null &&
    props.week !== undefined
  ) {
    nextText = "Aanleveren voor week " + props.week;
    // nextStyle = { backgroundColor: "#ec7026", color: "$heibaWhite" };
  }
  if (!props.week && props.week != null) {
    nextText = "Selecteer eerst een week";
    // nextStyle = {
    //   backgroundColor: "#70b960",
    //   opacity: "0.5",
    //   color: "white",
    //   //pointerEvents: "none",
    // }; //doesn't work
  }
  if (!props.nextStep) {
    nextText = "Versturen";
    // nextStyle = {color: "white", backgroundColor: "#ec7026" }
  }
  
  if(props.nextStep === 3){
    // nextStyle = {color: "white", backgroundColor: "#ec7026" }
    nextText = "Naar versturen"
    if(props.lock){
      nextText = "Fout bij uploaden";
      // nextStyle = {color: "white", backgroundColor: "#ec7026", opacity: "0.5", pointerEvents: "none" }
    }
    if(props.uploadWait){
      nextText = "Wachten...";
      // nextStyle = {color: "white", backgroundColor: "#ec7026", opacity: "0.5", pointerEvents: "none" }
    }
  }
  if(props.done){
    nextText = "Verstuurd"
  }
  if(hover  && !(!props.week && props.week != null)){
    // nextStyle = {...nextStyle, backgroundColor: "#70b960", opacity: "1.0",cursor: 'pointer'}
  }
  if(videoInput?.status !== 0){
    nextText = "Reeds aangeleverd voor deze week"
    // nextStyle = {...nextStyle, pointerEvents: "none", backgroundColor: "#70b960", opacity: "0.5",cursor: 'pointer' }
  }

  
  if(noContentWarning)  {return <OverlayUploadWarning accepted={setAccepted} setShowWarning={setNoContentWarning}  message={noUpload}/>}
  if(showWarning)  {return <OverlayUploadWarning accepted={setAccepted} setShowWarning={setShowWarning} message={lowQualityUpload}/>}
  return (
    <div className="extra-texarea-btns extra-textarea-btns-top">
      <button className="extra-btn"  onClick={backHandler}>{backText}</button>
      <button className="extra-btn extra-btn-theme"
          onClick={nextHandler}
          onMouseEnter={enter} 
          onMouseLeave={leave}>
            {nextText}
        </button>
      </div>
  );
}


