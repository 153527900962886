import React, { useEffect, useState } from 'react'
import superagent from 'superagent'
import {backendBaseUrl} from '../../constants'

import {changePositionInWeek, removeVideoFromWeek} from '../../actions/videoInput'
import { ambiancevideo } from '../../types/ambiance';
import { useDrag, DragSourceMonitor } from 'react-dnd'
import { selectConstructionVideo } from '../../actions/selectors';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch ,useAppSelector} from '../../hooks/redux';



export const ItemTypes = {
    BOX: 'box',
  }
  

interface VideoCardProps{
    data: ambiancevideo
    name: string
}
interface DropResult {
    name: string
    droppedTo: number
  }

export default function VideoCard(props:VideoCardProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const constructionVideos = useAppSelector(selectConstructionVideo);
    const [count, set_count] = useState(0)
    const [weeks, set_weeks] = useState<ambiancevideo[]>([])
    useEffect(() => {
        //TODO -- this request is needed for every card, change in backend 
        superagent.get(`${backendBaseUrl}/productMediaUsage/${props.data.constructionVideoId}/${props.data.year}`)
        .then(res => {
            set_count(res.body.count.count)
            set_weeks(res.body.count.rows)
        })
        .catch(err => console.log('err', err))
    },[dispatch, props.data.constructionVideoId, props.data.year])

    const onClick = () =>{
        dispatch(removeVideoFromWeek(`${props.data.id}`, "video", ))
    }

    const isOccupied = (position: number):boolean => {
        const there = constructionVideos.find(video => video.position === position)
        return there ? true : false
    }

    const [ , drag] = useDrag(
        () => ({
          type: ItemTypes.BOX,
          item: props.name ,
          end(item, monitor) {
            const dropResult = monitor.getDropResult() as DropResult
            if (item && dropResult) {
              let alertMessage = ''
              const isDropAllowed = dropResult.droppedTo !== props.data.position && !isOccupied(dropResult.droppedTo)
              if (isDropAllowed) {
                dispatch(changePositionInWeek(props.data.id, dropResult.droppedTo, "video"))
              } else {
                alertMessage = `You cannot an item into the ${dropResult.name}`
                console.log(alertMessage)
              }
            }
          },
          collect: (monitor: DragSourceMonitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
          }),
        }),
        [props.name, props.data],
      )

        console.log("link", props.data.constructionVideo.link)
    return (
        <div ref={drag} className="placementVideoCard">
            <div className="infoHolder">
                <button onClick={()=> navigate(`/libaryInfo/${props.data.constructionVideoId}/video`)}>Info </button>
                <p>name: {props.data.constructionVideo.name} </p>
                <p>omschrijving: {props.data.constructionVideo.description} </p>
                <p>count: {count} </p>
                <p>Gebruikt in week: {weeks.map(w => w.week + ", ")} </p>
                <p>position: {props.data.position} </p>
            </div>
            {/* backgroundImage: "url(" + imgUrl + ")" */}
            {/* <div className="placementVideoCardImage" style={{ backgroundImage: `url(${props?.data?.constructionVideo?.thumbnail})` } }/> */}
            <div className="placementVideoCardImage" style={{ backgroundImage: "url(" + props?.data?.constructionVideo?.thumbnail + ")" } } />
            <button className="adminButtonAbsolute" onClick={onClick} style={{cursor: 'pointer'}}> Verwijder video uit deze week</button>
        </div>
    )
}
