import React from "react";
import { constructionVideo } from "../types/constructionMedia";

interface VideoTitleProps {
  click: Function
  video: constructionVideo
  active: string
  className: string
}

export default function VideoTitle (props:VideoTitleProps):JSX.Element {

  console.log("props", props)
  const clickHandler:React.MouseEventHandler = () => {
    props.click(props?.video?.id);
  }

    if (props?.video?.id === parseInt(props?.active)) {
      return (
        <p
          className={props.className}
          style={{ color: "white", backgroundColor: "#70b960",cursor: 'pointer' }}
          onClick={clickHandler}
        >
          {props.video.name}
        </p>
      );
    } else {
      return (
        <p  onClick={clickHandler} 
            style={{cursor: 'pointer'}}
            className={props.className} >
          {props?.video?.name}
        </p>
      );
    }
}
