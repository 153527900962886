import moment from "moment";
import { RootState } from "../store";
import { getWeek } from "../getWeek";

export const getTodoWeeks = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState?.videoInput?.heibaWeeks;
  if (!heibaWeeks) return []; 
  return heibaWeeks.filter(
    (week: any, index: number, self:any) => index === self.findIndex((t:any) => t.week === week.week)
  );
};
export const getGotoWeeks = (reduxState: RootState) => {
  const heibaWeeks = getTodoWeeks(reduxState);
  if (!heibaWeeks) return undefined; 
  return heibaWeeks.filter((week: any) => (week.status === 0 ? true : false)); //TODO check status maybe also 1)
};

export const inputForCurrentWeek = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  //@ts-ignore
  if (!heibaWeeks) return [];
  return heibaWeeks.filter(
    //todo fix weeknumbers sting or number???
    //@ts-ignore
    // eslint-disable-next-line
    (week:any) => week.week == reduxState.videoInput.weekNumber
  );
};
export const inputForCurrentWeekFacebook = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  if (!heibaWeeks) return false;
  const FacebookList = heibaWeeks.filter(
    (week:any) =>
      //@ts-ignore
      // eslint-disable-next-line
      week.week == reduxState.videoInput.weekNumber &&
      week.type === "facebook" &&
      week.active
  );
  return FacebookList.length >= 1 ? true : false;
};
export const inputForCurrentWeekShopMovie = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  if (!heibaWeeks) return false;
  const shopMovie = heibaWeeks.filter(
    (week:any) =>
      //@ts-ignore
      // eslint-disable-next-line
      week.week == reduxState.videoInput.weekNumber &&
      week.type === "shopmovie" &&
      week.active
  );
  return shopMovie.length >= 1 ? true : false;
};

export const getDoneDays = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  if (!heibaWeeks) return [];
  const doneWeeks = heibaWeeks.filter((week:any) => week.status >= 1);
  const doneWeeksDays = doneWeeks.map((week:any) =>
    getWeekDays(heibaWeeks, week.startDate)
  );
  const weekWDays = doneWeeksDays.map((w:any) =>
    w.filter((day:any, i:number) => i !== 0 && i !== 1 && i !== w.length - 1)
  );
  return weekWDays.flat();
};

export const getFirstDoneDays = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  if (!heibaWeeks) return [];
  const doneWeeks = heibaWeeks.filter((week:any) => week.status >= 1);
  //TS-CHECK
  //doesn't make sense, getWeekDays return an empty array if sencond argument is not there
  //@ts-ignore
  const doneWeeksDays = doneWeeks.map((week:any) => getWeekDays(week.startDate));
  return doneWeeksDays.map((w:any) => w[0]);
};

export const getLastDoneDays = (reduxState: RootState) => {
  //@ts-ignore
  const heibaWeeks = reduxState.videoInput.heibaWeeks;
  if (!heibaWeeks) return [];
  const doneWeeks = heibaWeeks.filter((week:any) => week.status >= 1);
  //getWeekDays returns empty array if not having a second argument
  //@ts-ignore
  const doneWeeksDays = doneWeeks.map((week:any) => getWeekDays(week.startDate));
  return doneWeeksDays.map((w:any) => w[w.length - 1]);
};

const getWeekDays = (heibaWeeks:any, day:string) => {
  const selectedDay:Date|undefined|null = stringToDate(day);
  if (!selectedDay) return [];
  const weekOfselectedDay = getWeek(selectedDay)
  const YearOfselectedDay = selectedDay.getFullYear();
  const wow = findMyWeekDay(heibaWeeks, weekOfselectedDay, YearOfselectedDay);
  //check if we want to keep using moment, no longer maintained
  //@ts-ignore
  const days = [new Date(moment(wow.startDate))];
  //@ts-ignore
  for (let i = 0; i < new Date(wow.daysCount); i += 1) {
    days.push(moment(moment(wow.startDate)).add(i, "days").toDate());
  }
  return days;
};

const stringToDate = (selectedDay:any) => {
  if (!selectedDay) return null;
  if (typeof selectedDay.getWeek !== "function") {
    const transformedSelectedDay = new Date(selectedDay);
    transformedSelectedDay.setUTCHours(0);
    //getWeek need to be typed
    //@ts-ignore
    if (typeof transformedSelectedDay.getWeek !== "function") {
      return null;
    }
    return transformedSelectedDay;
  }
};

const findMyWeekDay = (heibaWeeks:any, week:number, year:number) => {
  return heibaWeeks.reduce((w:any, acc:any) => {
    if (acc.year === year && acc.week === week) return acc;
    else return w;
  }, {});
};

export const selectMalfunctionsOpen = (reduxState: RootState) => {
  //@ts-ignore
  if (!reduxState.admin.malfunctions) return null;
  return selectMalfunctionsAll(reduxState).filter((mal:any, i:number) => {
    if (i === 0) return mal;
    return mal.dateClose === "" || mal.dateClose === undefined;
  });
};
//@ts-ignore
export const dayResult = (reduxState: RootState) => reduxState.admin.dayResult;
//@ts-ignore
export const countShopVideo = (reduxState: RootState) => reduxState.admin.countShopVideo;
//@ts-ignore
export const countWebVideo = (reduxState: RootState) => reduxState.admin.countWebVideo;
//@ts-ignore
export const countReboot = (reduxState: RootState) => reduxState.admin.countReboot;
//@ts-ignore
export const countBannerVideo = (reduxState: RootState) =>
//@ts-ignore
reduxState.admin.countBannerVideo;
export const countMonthShopVideo = (reduxState: RootState) =>
//@ts-ignore
reduxState.admin.monthShopVideo;

export const countSummary = (reduxState: RootState) => reduxState.admin.summaryCount;

export const selectMalfunctionsAll = (reduxState: RootState) => {
  //@ts-ignore
  if (!reduxState.admin.malfunctions) return null;
  //@ts-ignore
  return reduxState.admin.malfunctions.map((mf:any, i:number) => {
    return {
      row: i + 1,
      customerId: mf[0],
      customerName: mf[1],
      mac: mf[2],
      type: mf[3],
      solution: mf[4],
      dateOpen: mf[5],
      dateClose: mf[6],
      extraInfo: mf[7],
      name: mf[8],
    };
  });
};
export const selectMalfunctionsClosed = (reduxState:RootState) => {
  //@ts-ignore
  if (!reduxState.admin.malfunctions) return null;
  //@ts-ignore
  return reduxState.admin.malfunctions.filter((mal:any, i:number) => {
    if (i === 0) return mal;
    return mal[5] !== "";
  });
};

export const selectAllPlayers = (reduxState:RootState) => {
  //@ts-ignore
  return [...reduxState.admin.players, ...reduxState.admin.connectPlayers];
};

export const membersCount = (reduxState: RootState) => {
  const admin = reduxState.admin;
  //@ts-ignore
  if (admin && admin.members) {
    //@ts-ignore
    return admin.members.length;
  }
  return 0;
};

export const playersCount = (reduxState: RootState) => {
  const admin = reduxState.admin;
  //@ts-ignore
  if (admin && admin.players) {
    //@ts-ignore
    return admin.players.length;
  }
  return 0;
};

export const selectMalfunctionsOpenCount = () => selectMalfunctionsOpen.length;
export const AmbianceCount = (type:any) => {
  return (reduxState: RootState) => {
    //@ts-ignore
    return reduxState.admin.dashboard[type];
  };
};

export const todolist = (reduxState: RootState) => {
  //@ts-ignore
  return reduxState.admin.todo;
};

export const selectInstalaltionMedia = (reduxState: RootState) => {
  //@ts-ignore
  return reduxState.admin.installationMedia;
};

export const selectHeibaWeeks = (reduxState: RootState) => {
  return reduxState?.videoInput?.heibaWeeks || []
}

export const selectWeeknumber = (reduxState: RootState) => {
  return reduxState?.videoInput?.weekNumber || 0
}

export const selectUser = (reduxState: RootState) => reduxState.user
export const selectUserLogout = (reduxState: RootState) => reduxState.user.logout
export const selectPreview = (reduxState: RootState) => reduxState.preview
export const selectAccountmanagers = (reduxState: RootState) => reduxState.admin.accountManagers
export const selectFtpMembers = (reduxState: RootState) => reduxState.admin.ftpMembers
export const selectMediaLibaryPhoto = (reduxState: RootState) => reduxState.mediaLibary.photos
export const selectMediaLibaryVideo = (reduxState: RootState) => reduxState.mediaLibary.videos
export const selectLivedays = (reduxState: RootState) => reduxState.admin.livedays
export const selectGeneralLivedays = (reduxState: RootState) => reduxState.admin.generalLiveday
export const selectPlayers = (reduxState: RootState) => reduxState.admin.players
export const selectNightPlayers = (reduxState: RootState) => reduxState.admin.nightPlayers
export const selectPlayer = (reduxState: RootState) => reduxState.admin.player
export const selectConnectPlayers = (reduxState: RootState) => reduxState.admin.connectPlayers
export const selectAmbiancevideo = (reduxState: RootState) => reduxState.ambiancevideo
export const selectVideoInput = (reduxState: RootState) => reduxState.videoInput
export const selectVideoInputStats = (reduxState: RootState) => reduxState.admin.videoInputStats
export const selectrMembers = (reduxState: RootState) => reduxState.admin.members
export const selectrMember = (reduxState: RootState) => reduxState.admin.member
export const selectWeekinput = (reduxState: RootState) => reduxState.admin.weekInput
export const selectConstructionVideo = (reduxState: RootState) => reduxState.constructionvideo
export const selectConstructionPhoto = (reduxState: RootState) => reduxState.constructionphoto
export const selectSummaryGraph = (reduxState: RootState) => reduxState.admin.summaryGraph
export const selectServerVideo = (reduxState: RootState) => reduxState.admin.serverVideo
export const selectServer = (reduxState: RootState) => reduxState.admin.server
export const selectPlayerHistory = (reduxState: RootState) => reduxState.admin.playerHistory
export const selectPlayerDownloads = (reduxState: RootState) => reduxState.admin.playerDownloads
export const selectPlayerSwitch = (reduxState: RootState) => reduxState.admin.PlayerSwitches
export const selectPlayerLocations = (reduxState: RootState) => reduxState.admin.playerLocations
export const selectPlayerFolders = (reduxState: RootState) => reduxState.admin.playerFolders
export const selectPlayerLessButtons = (reduxState: RootState) => reduxState.admin.playerLessButtons
export const selectYearInput = (reduxState: RootState) => reduxState.admin.yearInput
export const selectMemberLocations = (reduxState: RootState) => reduxState.admin.customerLocations
export const selectAppStateError = (reduxState: RootState) => reduxState.appState.errorMessage
export const selectServerLogRecords = (reduxState: RootState) => reduxState.admin.serverLogRecords
export const selectBasenetshop = (reduxState: RootState) => reduxState.admin.basenetshop
export const selectPlayerStorageInfo = (reduxState: RootState) => reduxState.admin.playerStorageInfo
export const selectPlayerSsmInfo = (reduxState: RootState) => reduxState.admin.playerSsmInfo
export const selectMemberPiPlayerCopydays = (reduxState: RootState) => reduxState.admin.playerCopyday
export const selectWebplayercopyday = (reduxState: RootState) => reduxState.admin.webplayercopyday
export const selectWebBannercopyday = (reduxState: RootState) => reduxState.admin.webBannercopyday
export const selectConstructionItem = (reduxState: RootState) => reduxState.admin.constructionItem
export const selectMediaTags = (reduxState: RootState) => reduxState.admin.mediaTags
export const selectWebshopProducts = (reduxState: RootState) => reduxState.webshop.products
export const selectTasks = (reduxState: RootState) => reduxState.taskQueue.tasks
export const selectBaseContent =  (reduxState: RootState) => reduxState.admin.baseContent
export const selectManagingMembers =  (reduxState: RootState) => reduxState.admin.managingMembers
export const selectSubscriptions =  (reduxState: RootState) =>  reduxState.admin.memberSubscriptions
export const selectSubscriptionOptions =  (reduxState: RootState) =>  reduxState.admin.memberSubscriptionOptions