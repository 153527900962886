import React  from "react";
import "../getWeek";
import {
  SelectAWeek,
  getProductMedia,
  getAmbianceVideo,
} from "../actions/videoInput";
import {
  getTodoWeeks,
  selectHeibaWeeks,
  selectUser,
  selectWeeknumber,
} from "../actions/selectors";
import InputWeek from "./InputWeek";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { heibaweek } from "../types/heibaweek";
import * as Sentry from "@sentry/browser"
import { WEEKSELECT } from "../actions/actionCreators";

interface SelectBlocksProps{
  admin: Boolean
}

export default function SelectBlocks(props:SelectBlocksProps):JSX.Element {
  const weekNumber = useAppSelector(selectWeeknumber );
  const user = useAppSelector(selectUser);

  const heibaWeeks:heibaweek[] = useAppSelector(selectHeibaWeeks);
  const dispatch = useAppDispatch();

  const findMyWeekDay = (week: number, year: number):heibaweek | {}  => {
    return heibaWeeks.reduce((w, acc) => {
      if (acc.year === year && acc.week === week) return acc;
      else return w;
    }, {});
  };

  const todoWeeks = useAppSelector(getTodoWeeks);


  const handleWeekClick = (week:heibaweek) => {
    //@ts-ignore //TS-TODO
    if (findMyWeekDay(week.week, week.year).startDate) {
      dispatch({ type: WEEKSELECT, payload: week.week }); //TODO missing YEAR
      //@ts-ignore
      dispatch(SelectAWeek(week.week, week.year));
      dispatch(getProductMedia(week.week, week.year, user.category));
      dispatch(getAmbianceVideo(week.week, week.year, "sfeer"));
    } else {
      console.log("why and when does this happen?", week) //if the week was not found... 
      Sentry.captureMessage("Week not found, should not be possible")
      dispatch({ type: WEEKSELECT, payload: 0 }); //TODO check if this is needed?
    }
  };

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  return (
    <div className="selectWeekHolder">
      <div className="selectWeek">
        <h2> Week selecteren</h2>

        {!props.admin ? (
          <p className="selectWeekSubText"> Kies de week waarvoor u de input wilt gaan aanleveren</p>
        ) : null}
        <div className="inputCallenders" style={{ flexDirection: "column" }}>
          <div className="inputCallendersHolder">
            {todoWeeks && todoWeeks.length
              ? todoWeeks.map(
                  (week, i) => (
                    <InputWeek
                      key={i}
                      week={week}
                      cb={() => handleWeekClick(week)}
                      //TODO 
                      //eslint-disable-next-line
                      active={week.week == weekNumber}
                    />
                  )
                )
              : "je hebt alles al aangeleverd "}
          </div>
        </div>
      </div>
    </div>
  );
}
