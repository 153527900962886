import React from "react";
import { Table } from "semantic-ui-react";
import { playerHistory } from "../../types/player";

interface PlayerDetailsLastSeenProps{
  history: playerHistory[]
}

export default function PlayerDetailsLastSeen(props:PlayerDetailsLastSeenProps) {
  
  const oneCell = (d: number) => {
    const perfect = (d === 20) ? true : undefined
    const off = (d === 0) ? true : undefined
    const missedSome = (d === 0) ? true : undefined
    return (
      <Table.Cell  negative={off}  positive={perfect} warning={missedSome} style={{padding: ".28571429em"}}>{d}</Table.Cell>
    )
  }
  
  const lastSeenCell = (d:playerHistory) => {
    return (
      <Table.Row key={d.date}>
        <Table.Cell style={{padding: ".28571429em"}}>{`${d.day} (${d.date})`}</Table.Cell>
        { oneCell(d['00']) }
        { oneCell(d['01']) }
        { oneCell(d['02']) }
        { oneCell(d['03']) }
        { oneCell(d['04']) }
        { oneCell(d['05']) }
        { oneCell(d['06']) }
        { oneCell(d['07']) }
        { oneCell(d['08']) }
        { oneCell(d['09']) }
        { oneCell(d['10']) }
        { oneCell(d['11']) }
        { oneCell(d['12']) }
        { oneCell(d['13']) }
        { oneCell(d['14']) }
        { oneCell(d['15']) }
        { oneCell(d['16']) }
        { oneCell(d['17']) }
        { oneCell(d['18']) }
        { oneCell(d['19']) }
        { oneCell(d['20']) }
        { oneCell(d['21']) }
        { oneCell(d['22']) }
        { oneCell(d['23']) }
      </Table.Row>
    );
  };
  let header = []
  for(let i = 0; i <= 23 ; i++){
    header.push( <Table.HeaderCell key={i}>{i}</Table.HeaderCell>)
  }
 
  return (
    <div >
      <Table celled unstackable>
        <Table.Header>
         <Table.Row>
         <Table.Cell></Table.Cell>
           {header}
           </Table.Row>
        </Table.Header>
        <Table.Body>
          {props?.history?.map(h => lastSeenCell(h))}
        </Table.Body>
      </Table>
    </div>
  );
}
