import React, { ChangeEventHandler } from "react";
import { FocusEventHandler } from "react";
import { KeyboardEventHandler } from "react";
import { SelectAWeek, SelectAWeekQuickSave } from "../actions/videoInput";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export default function ProductFocusFeed():JSX.Element{
    
  const focusProduct = useAppSelector(reduxState => reduxState.videoInput.focusProduct)
  const videoInput = useAppSelector(reduxState => reduxState.videoInput)
  const dispatch = useAppDispatch()

    const onChangeHandler:ChangeEventHandler = (e:React.ChangeEvent<HTMLTextAreaElement>) =>{
      dispatch({type: "FOCUSPRODUCT", payload: e.target.value})
    }
    const  onKeyDownHandler:KeyboardEventHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if(e.key === "Enter"){
        //@ts-ignore
        dispatch(SelectAWeekQuickSave(videoInput.weekNumber,videoInput.year, e.target.value ))
      }
    }
    
    const onBlurHandler:FocusEventHandler = (e:React.FocusEvent<HTMLTextAreaElement>) => {
      //@ts-ignore
      dispatch(SelectAWeek(videoInput.weekNumber,videoInput.year, e.target.value)) 
    }  
    
    return (
      <div className="banner-content">
      <div className="banner-content-text">
          <h4>Focusproduct</h4>
          <p>Het artikel dat u deze week in de spotlight wilt zetten. <br/>
              In het uiteindelijk filmfragment zal het focusproduct het product zijn dat het
              meest frequent  zal worden.</p>
          <p>Het startpunt van het filmfragment die wekelijks gewisseld dient te worden.</p>
      </div>
      <div className="banner-form">
          <form action="">
              <div className="form-text">
                  <h3> <small>It ‘s all about </small> <br/> Focus</h3>
              </div>
              <textarea 
                name="comment" 
                onChange={onChangeHandler} 
                onKeyDown={onKeyDownHandler} 
                value={focusProduct} 
                onBlur={onBlurHandler}
                cols={30} rows={10}
                placeholder="Vul hier het focus artikel in:"
                ></textarea>
          </form>
      </div>
  </div>
             
        
    );
  }

