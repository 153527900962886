import React from "react";
import { MenuItemProps } from "semantic-ui-react";

export interface useAppSelectorProps{
  to: string,
  admin: Boolean
}

export default function MenuItem(props:MenuItemProps):JSX.Element{
  const pahthName = window.location.pathname.substr(1);
  let active = "";
  if (pahthName === props.to) {
    active = "active";
  }
  if (props.admin) {
    return (
      <div className={`menuBar${props.pos} ${active} dropdown`}>
        <a href={"/officehome"}>{props.name} </a>
        <div className="dropdown-content">
          <a href={"/office"}> {"AANLEVERINGEN"} </a>
          <a href={"/admin"}> {"SETUP INPUT"} </a>
          <a href={"/libary"}> {"LIBARY"} </a>
          <a href={"/libarytags"}> {"MEDIATAGS"} </a>
          <a href={"/libaryUpload"}> {"MEDIA UPLOAD"} </a>
          <a href={"/players"}> {"PLAYERS"} </a>
          <a href={"/loginlike"}> {"MEMBERS LOGIN"} </a>
          <a href={"/currentlyonftp"}> {"NOW PLAYING"} </a>
          <a href={"/accoutmanagers"}> {"ACCOUNTMANAGERS"} </a>
          <a href={"/livedays"}> {"LIVEDAYS PLAYERS"} </a>
          <a href={"/livedaysweb"}> {"LIVEDAYS WEBSITE"} </a>
          <a href={"/installation"}> {"INSTALLATIE"} </a>
          <a href={"/malfunctions"}> {"STORINGEN"} </a>
          {/* <a href={"/kaart"}> {"KAART"} </a> */}
          <a href={"/DayResult"}> {"DAY SUMMARY"} </a>
          <a href={"/ftpMembers"}> {"FTP MEMBERS"} </a>
        </div>
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}
