import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {
  getPlayerInfo,
  getCustomerInfo,
  getPlayerHistory,
  getPlayerServer,
  getPlayerSsmInfo,
  getPlayerSwitch,
  getFilesOnServer,
  getPlayerDownloads,
  getServerLogRecords,
  getPlayers,
  getPlayerLocations,
  getBasenetshop,
  getMemberCopydays,
  getManagingMember,
  getSubscriptions
} from "../../actions/videoInput";
import PlayerDetailsCustomerInfo from "../../components/admin/PlayerDetailsCustomerInfo";
import PlayerDetailsWaitingNumbers from "../../components/admin/PlayerDetailsWaitingNumbers";
import PlayerDetailsSwitch from "../../components/admin/PlayerDetailsSwitch";
import PlayerDetailsServerInfo from "../../components/admin/PlayerDetailsServerInfo";
import PlayerDetailsLastSeen from "../../components/admin/PlayerDetailsLastSeen";
import PlayerAdminPlayers from "../../components/admin/PlayerAdminPlayers";
import InputAdminSummary from "../../components/admin/InputAdminSummary";
import { Button, Icon } from "semantic-ui-react";
import InstallationPhotos from "../../components/admin/InstallationPhotos";
import {scrollTop} from "../../toTop"
import PlayerDetailsSD from "../../components/admin/PlayerDetailsSD";
import PlayerDownloadInfo from "../../components/admin/PlayerDownloadInfo";
import { selectPlayerHistory, selectPlayerDownloads, selectServer, selectServerVideo, selectPlayer,selectPlayerLocations, selectPlayerSsmInfo,selectPlayerSwitch, selectrMember, selectPlayers, selectServerLogRecords, selectBasenetshop, selectMemberPiPlayerCopydays, selectWebBannercopyday, selectWebplayercopyday, selectManagingMembers, selectSubscriptions } from "../../actions/selectors";
import LoadingIndicator  from "../../components/LoadingIndicator";
import PlayerDetailsLocationInfo from "../../components/admin/PlayerDetailsLocationInfo";
// import CustomerMap from "../../components/admin/CustomerMap";
import ServerDownloadInfo from "../../components/admin/ServerDownloadInfo";
import PlayerBasnetAdress from "../../components/admin/PlayerBasnetAdress";
import PlayerDetailsLiveday from "../../components/admin/PlayerDetailsLiveday";
import PlayerDetailsSsmInfo from "../../components/admin/PlayerDetailsSsmInfo";
import PlayerDetailsManagingMember from "../../components/admin/PlayerDetailsManagingMember";
import PlayerDetailsSubscriptions from "../../components/admin/PlayerDetailsSubscriptions";


export default function PlayerDetails() {
  const params = useParams<{id: string, mac: string}>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const [lastSeenDays, set_lastSeenDays] = useState(10);
  const member = useAppSelector(selectrMember);
  const player = useAppSelector(selectPlayer);
  const serverVideo = useAppSelector(selectServerVideo);
  const server = useAppSelector(selectServer);
  const playerDownloads = useAppSelector(selectPlayerDownloads);
  const serverDownloads = useAppSelector(selectServerLogRecords);
  const playerHistory = useAppSelector(selectPlayerHistory );
  const PlayerSwitches = useAppSelector(selectPlayerSwitch)
  const players = useAppSelector(selectPlayers);
  const playersSsmInfo = useAppSelector(selectPlayerSsmInfo);
  const playerLocations = useAppSelector(selectPlayerLocations)
  const basenetshop =  useAppSelector(selectBasenetshop)
  const playerCopyday =  useAppSelector(selectMemberPiPlayerCopydays)
  const webplayercopyday = useAppSelector(selectWebplayercopyday)
  const webBannercopyday = useAppSelector(selectWebBannercopyday)
  const managingMembers = useAppSelector(selectManagingMembers)
  const heibaSubscriptions = useAppSelector(selectSubscriptions)
  
  const fetchInfo = useCallback(
    () => {
      if(params?.mac){
        dispatch(getPlayerInfo(params.mac));
        dispatch(getPlayerSwitch(params.mac));
        dispatch(getCustomerInfo(Number(params.id)));
        dispatch(getPlayerHistory(params.mac, lastSeenDays));
        dispatch(getPlayerLocations(params.mac));
        dispatch(getPlayerDownloads(params.mac));
        dispatch(getPlayerServer(params.mac));
        dispatch(getPlayerSsmInfo(params.mac));
      }
        if(params?.id){
        dispatch(getFilesOnServer(Number(params.id))) 
        dispatch(getServerLogRecords(params.id));
        dispatch(getPlayers(Number(params.id))); 
        dispatch(getBasenetshop(params.id))
        dispatch(getMemberCopydays(params.id))
        dispatch(getManagingMember(Number(params.id)))
        dispatch(getSubscriptions(Number(params.id)))
      }
    },
    [params.id,params.mac,lastSeenDays, dispatch],
  );

  useEffect(() => {
    scrollTop()
    fetchInfo()
  }, [params.id, dispatch, params.mac, lastSeenDays, fetchInfo]);
  console.log("playersSsmInfo", playersSsmInfo)
  if(!player) return <LoadingIndicator loadingText="Loading player" />
  return (
    <div>
      <div className="adminContainer">
        <div className="adminItem">
          <h1>KlantInfo</h1>
          <PlayerDetailsCustomerInfo data={member} serverVideo={serverVideo}/>
        </div>

        <div className="adminItem">
          <PlayerAdminPlayers players={players} customer={Number(params.id)} currentMac={params.mac} unlink showTitle navigate={navigate}/>
        </div>
        <div className="adminItem">
          <h1>Player Info</h1>
          <PlayerDetailsCustomerInfo data={player} serverVideo={serverVideo} />
        </div>
        { playersSsmInfo?.InstanceId ? 
        <div className="adminItem">
          <h1>Player SSM Info</h1>
          <PlayerDetailsSsmInfo data={playersSsmInfo} />
        </div> : null }
        <div className="adminItem">
          <h1>Player Location Info</h1>
          <PlayerDetailsLocationInfo data={playerLocations} />
        </div>
        <div className="adminItem">
          <h1>Download Info</h1>
          <PlayerDownloadInfo data={playerDownloads} />
        </div>
        <div className="largeAdminItem adminItem">
          <h1>Ftp server log</h1>
          <ServerDownloadInfo data={serverDownloads} />
        </div>
        <div className="adminItem">
          <h1>Sd Info</h1>
          <PlayerDetailsSD data={player}/>
        </div>
        <div className="adminItem">
          <h1>Copyday Info</h1>
          <PlayerDetailsLiveday playerCopyday={playerCopyday} webplayercopyday={webplayercopyday} webBannercopyday={webBannercopyday} memberId={Number(params.id)}/>
        </div>
        <div className="adminItem">
          <h1>Accounts bij deze klant</h1>
          <PlayerDetailsManagingMember  managingMembers={managingMembers} memberId={Number(params?.id)} />
        </div>
        <div className="adminItem">
          <h1>Abonnementen van deze klant</h1>
          <PlayerDetailsSubscriptions  heibaSubscriptions={heibaSubscriptions} memberId={Number(params?.id)} />
        </div>
        <div className="adminItem">
          <h1>Aangeleverde input</h1>
          <InputAdminSummary member={member} />
        </div>
        <div className="adminItem">
          <h1>Server Info</h1>
          <PlayerDetailsServerInfo
            player={player}
            member={member}
            server={server}
          />
        </div>
        <div className="adminItem">
          <h1>Waiting numbers</h1>
          <PlayerDetailsWaitingNumbers player={player} member={member} />
        </div>
        <div className="adminItem">
          <h1>PlayerSwitch Program</h1>
          <PlayerDetailsSwitch PlayerSwitches={PlayerSwitches} mac={params?.mac} />
        </div>
        {/* <div className="adminItem">
          <h1>Website copy</h1>
          <PlayerDetailsWebsites player={player} member={member} />
        </div> */}
        <div className="adminItem">
          <h1>Installatiefoto's</h1>
          <InstallationPhotos  member={member}/>
          </div>
        <div className="largeAdminItem adminItem">
          <h1>Kaart</h1>
          {/* <CustomerMap  member={member}/> */}
          <PlayerBasnetAdress basenetshop={basenetshop}/>
          </div>

        <div className="largeAdminItem adminItem">
          <h1>LastSeen</h1>
          <div style={{marginBottom: "1em"}}>
            <Button onClick={() => fetchInfo()} style={{cursor: 'pointer'}}><Icon name="sync alternate"/></Button>
            <Button onClick={() => set_lastSeenDays(20)} style={{cursor: 'pointer'}}>20 dagen</Button>
            <Button onClick={() => set_lastSeenDays(50)} style={{cursor: 'pointer'}}>50 dagen</Button>
            <Button onClick={() => set_lastSeenDays(100)} style={{cursor: 'pointer'}}>100 dagen</Button>
            <Button onClick={() => set_lastSeenDays(365)} style={{cursor: 'pointer'}}>1 jaar</Button>
            <Button onClick={() => set_lastSeenDays(1095)} style={{cursor: 'pointer'}}>3 jaar</Button>
            <Button onClick={() => set_lastSeenDays(1825)} style={{cursor: 'pointer'}}>5 jaar</Button>
          </div>
          <PlayerDetailsLastSeen history={playerHistory} />
        </div>
      </div>
    </div>
  );
}

