import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {  getMembers } from "../../actions/videoInput";
import { Dropdown } from 'semantic-ui-react'
import { selectrMembers } from "../../actions/selectors";


interface CustomerSelectProps{
  set_CustomerId(n:any): void
 }

export default function CustomerSelect(props:CustomerSelectProps) {
  const members_list = useAppSelector(selectrMembers)
  const dispatch = useAppDispatch();
  useEffect(() => {
      dispatch(getMembers())
  }, [dispatch]);

  const options = members_list?.map(member => { return {key: member.id , value: member.id, text: `${member.company}(${member.id}) in ${member.basenetshop?.Woonplaats_postadres || "Onbekend"}`} })

  return (
    <div>
      <Dropdown
        placeholder='Selecteer een klant'
        fluid
        search
        selection
        onChange={(e, f) => props.set_CustomerId(f.value) }
        options={options}
      />
    </div>
  );
}
