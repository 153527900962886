import React, { useEffect } from "react";
import CompanyInfo from "../components/CompanyInfo";
import TopicLine from "../components/TopicLine";
import {
  getHeibaWeeks,
  SelectAWeek,
  getAmbianceVideo,
  getProductMedia,
} from "../actions/videoInput";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { useParams, useNavigate } from "react-router-dom";
import { getTodoWeeks, getGotoWeeks, selectWeeknumber, selectUser } from "../actions/selectors";
import * as Sentry from '@sentry/browser';
import SelectBlocks from "../components/SelectBlocks";
import { WEEKSELECT } from "../actions/actionCreators";
import InputTypeSelector from "../components/InputTypeSelector";


export default function Input() {
  const dispatch = useAppDispatch();
  const params = useParams(); //TODO type
  const navigate = useNavigate();
  const todoWeeks = useAppSelector(getTodoWeeks);
  const gotoWeeks = useAppSelector(getGotoWeeks);
  const weekNumber = useAppSelector(selectWeeknumber);
  const user = useAppSelector(selectUser);

  const dispatchWeek = (gotoWeek:number, gotoYear:number, dispatch:Function, history:any) => {
    dispatch({ type: WEEKSELECT, payload: gotoWeek });
    //@ts-ignore
    dispatch(SelectAWeek(gotoWeek, gotoYear));
    dispatch(getProductMedia(gotoWeek, gotoYear, user.category));
    dispatch(getAmbianceVideo(gotoWeek, gotoYear, "sfeer"));
    navigate("/inputfeed");
  };

  useEffect(() => {
    let liveday = user.liveday;
    if (user.liveday === "Unknown") liveday = "Monday"; //is not a valid day
    //TODO this can go wrong
    dispatch(getHeibaWeeks(liveday, new Date().getFullYear()));
  }, [user.liveday, dispatch]);

  useEffect(() => {
    if (params.next && todoWeeks && weekNumber === 0) {
      //go to specific week here
      try {
        const weekFromParams = JSON.parse(atob(params.next));
        return dispatchWeek(
          weekFromParams.week,
          weekFromParams.year,
          dispatch,
          navigate
        );
      } catch (err) {
        Sentry.captureMessage('This was not an valid input gotoweek link');
      }
    }

    if (params.next && gotoWeeks && gotoWeeks.length >= 1 && weekNumber === 0) {
      let gotoWeek = gotoWeeks[0].week;
      let gotoYear = gotoWeeks[0].year;

      if (typeof params.next === "number") {
        gotoWeek = params.next;
        const date = new Date()
        gotoYear = date.getFullYear();
      }
      dispatchWeek(gotoWeek, gotoYear, dispatch, navigate);
    }
    //eslint-disable-next-line
  }, [dispatch, params.next, todoWeeks]);

  return (
    <div>
      <CompanyInfo />
      <TopicLine />
      <SelectBlocks admin={false}/>
      <InputTypeSelector/>
    </div>
  );
}
