import React from "react";
import LibaryCellDiscription from "../../components/admin/LibaryCellDescription";
import { Icon } from "semantic-ui-react";
import { deleteConstructionItem } from "../../actions/videoInput";
import VideoPlayer from "../../components/VideoPlayer";
import {
  constructionMediaItem,
  constructionMediaType,
  constructionPhoto,
  constructionVideo,
} from "../../types/constructionMedia";
import { useNavigate } from "react-router-dom"
import AdminMediaTag from "./AdminMediaTag";
import { useAppDispatch } from "../../hooks/redux";

interface HeibaLibaryItemProps {
  mediaItem: constructionMediaItem | constructionPhoto | constructionVideo;
  set_showLarge: Function;
  set_imgUrl: Function;
  showLarge: Boolean;
  mediaType: constructionMediaType;
}

export default function HeibaLibaryItem({
  mediaItem,
  set_showLarge,
  set_imgUrl,
  showLarge,
  mediaType,
}: HeibaLibaryItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const remove = (id: number, mediaType: constructionMediaType) => {
    dispatch(deleteConstructionItem(id, mediaType));
  };

  const showBig = (e: any) => {
    set_showLarge(!showLarge);
    set_imgUrl(e.target.src);
  };
  return (
    <div style={{ borderStyle: "solid", width: "45%", margin: "1em", color: '#333333' }}>
      <h3>
        {" "}
        <em> Bestandsnaam: </em> {mediaItem.name}{" "}
        <Icon
          name="remove circle"
          onClick={() => remove(mediaItem.id, mediaType)}
          style={{ cursor: "pointer" }}
        />
        <Icon
          name="edit"
          onClick={() =>
            navigate(`/libaryInfo/${mediaItem?.id}/${mediaType}`)
          }
          style={{ cursor: "pointer" }}
        />
      </h3>
      {mediaItem?.mediaTags?.map((tag: any) => {
        return <AdminMediaTag mediaTag={tag} />;
      })}
      <h3>
        <em> Omschrijving: </em> <LibaryCellDiscription image={mediaItem} />
      </h3>
      {mediaType === "image" ? (
        <img
          src={mediaItem.link}
          alt="impression"
          style={{ width: "100%" }}
          onClick={showBig}
        />
      ) : null}
      {mediaType === "video" ? (
        <VideoPlayer
          styleName="previewVideoPlayer"
          noAutoPlay={true}
          toPlay={mediaItem.link}
          //Todo
          // @ts-ignore
          poster={mediaItem?.thumbnail}
        />
      ) : null}
    </div>
  );
}
