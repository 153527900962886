import React from "react";
//@ts-ignore 
import { Player, BigPlayButton,ControlBar, VolumeMenuButton } from "video-react";

import "../../node_modules/video-react/dist/video-react.css"; // import css

interface VideoPlayerProps{
  noAutoPlay?: boolean
  styleName: string
  poster?: string
  toPlay: string|undefined
  preload?: boolean
}

export default function VideoPlayer(props:VideoPlayerProps):JSX.Element {
    return (
      <div className={props.styleName}>
        <div className="playerHolder">
        <Player
          playsInline
          autoPlay={props.noAutoPlay ? false : true}
          poster={ props.poster ? props.poster : "/images/poster.png"}
          src={props.toPlay}
          preload={ props.preload || "none"}
          >
              <BigPlayButton position="center"/>
              <ControlBar>

              <VolumeMenuButton disabled />
              </ControlBar>
          </Player>
          </div>
      </div>
    )
}
