import React from "react";
import { Table } from "semantic-ui-react";
import dateParser from "../../dateParser";
import { playerDownload } from "../../types/player";

interface PlayerDownloadInfoProps{
  data: playerDownload[] | undefined
}
 
export default function PlayerDownloadInfo(props:PlayerDownloadInfoProps) {
  if (!props.data) return null;
  const downloadCell = (header:number, info:playerDownload) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{info.videoName}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em", minWidth: "150px"}}>{dateParser(info.downloaded)}</Table.Cell>
        {/* <Table.Cell style={{ padding: ".28571429em" }}>{info.hash}</Table.Cell> */}
      </Table.Row>
    );
  };

  return (
    <div >
      <Table celled>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Filename</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Datum</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.data?.map( (download, i ) => {
           return downloadCell(i , download)
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
