import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import {Icon} from "semantic-ui-react"
import { uploadPreview } from "../../actions/videoInput";
import { weekInput } from "../../types/weekinput";
import { useAppDispatch } from "../../hooks/redux";


interface DropzoneProps{
  input: weekInput
}

function Dropzone(props:DropzoneProps) {
  const dispatch = useAppDispatch()
  
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map((file:any) => {
      dispatch(uploadPreview( file, props.input.id, props.input.week, props.input.year))
      return(null)
    });
  }, [ props.input, dispatch ]);  
  const { getRootProps, getInputProps } = useDropzone({    //was acceptedFiles here...
    onDrop
  });

  return (
    <section className="outerDropzone">
      <div {...getRootProps({ className: "dropzoneAdminPreview" })}>
        <input {...getInputProps()} name="inputUpload" />
        <Icon name="upload" />
      </div>
    </section>
  );
}

export default connect()(Dropzone);
