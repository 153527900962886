import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

export default function Oops() {
   const navigate = useNavigate()
   
   useEffect(()=> {
        setTimeout(() => {
            navigate("/login")}
        , 2000)
   },[navigate])
    return (
        <div className="Oops"> 
            <h2>Oops!</h2>
            <h1> Error 404</h1>
            <p>We konden de door jouw gevraagde pagina niet vinden, ben je ingelogd?</p>
            <p onClick={ () => navigate("/")} style={{cursor: 'pointer'}}>Naar de login page</p>
        </div>
    )
}
