import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  //@ts-ignore
  } from "recharts";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getSummaryGraph } from "../../actions/videoInput";
import { Dropdown , Input } from 'semantic-ui-react'
import { selectSummaryGraph } from "../../actions/selectors";
import { liveday } from "../../types/user";

export default function Stats() {
  const dispatch = useAppDispatch();
  const [days, set_days] = useState(14)
  const [weekday, set_weekday] = useState<liveday | undefined>("Monday")
  const data = useAppSelector(selectSummaryGraph);

  const [opacity, setOpacity] = useState({
    total: 1,
    mediaNotReadyCount: 1,
    doneCount: 1,
  });

  useEffect(() => {
    if(weekday){
      dispatch(getSummaryGraph(days, weekday));
    }
  }, [days, weekday, dispatch]);

  const CustomTooltip = ({ active, payload, label}:any)  => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Gedaan : ${payload[0]?.value}`}</p>
          <p className="label">{`Niet klaar gezet : ${payload[1]?.value}`}</p>
          <p className="label">{`Dag : ${payload[3]?.value}`}</p>
          <p className="label">{`Datum : ${label}`}</p>
          <p className="label">{`Totaal :  ${payload[2]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleMouseEnter = (o:any) => {
    const { dataKey } = o;

    setOpacity({ ...opacity, [dataKey]: 0.5 });
  };

  const handleMouseLeave = (o:any) => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 1 });
  };
  return (
    <React.Fragment>
      <h1>Shop videos</h1>
      <LineChart
        width={1100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Line
          name="Gedaan"
          type="monotone"
          dataKey="shop"
          strokeOpacity={opacity.doneCount}
          stroke="#09e393"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        <Line
          name="Niet klaargezet"
          type="monotone"
          dataKey="shopFailed"
          strokeOpacity={opacity.mediaNotReadyCount}
          stroke="#e30938"
          strokeWidth={3}
        />
            
        <Line
          name="Totaal"
          type="monotone"
          dataKey="shopTotal"
          stroke="#5dc2d7"
          strokeOpacity={opacity.total}
          strokeWidth={3}
        />
        <Line name="Dag" type="monotone" dataKey="day" stroke="#e30938" />
      </LineChart>
      <h1>Banner videos</h1>
      <LineChart
        width={1100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip banner/>} />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Line
          name="Gedaan"
          type="monotone"
          dataKey="banner"
          stroke="#09e393"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        <Line
          name="Niet klaargezet"
          type="monotone"
          dataKey="bannerFailed"
          strokeOpacity={opacity.mediaNotReadyCount}
          stroke="#e30938"
          strokeWidth={3}
        />
        <Line
          name="Totaal"
          type="monotone"
          dataKey="bannerTotal"
          stroke="#5dc2d7"
          strokeOpacity={opacity.total}
          strokeWidth={3}
        />
        <Line name="Dag" type="monotone" dataKey="day" stroke="#e30938" />
      </LineChart>
      <h1>Website videos</h1>
      <LineChart
        width={1100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Line
          name="Gedaan"
          type="monotone"
          dataKey="web"
          stroke="#09e393"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        <Line
          name="Niet klaargezet"
          type="monotone"
          dataKey="webFailed"
          strokeOpacity={opacity.mediaNotReadyCount}
          stroke="#e30938"
          strokeWidth={3}
        />
        <Line
          name="Totaal"
          type="monotone"
          dataKey="webTotal"
          stroke="#5dc2d7"
          strokeOpacity={opacity.total}
          strokeWidth={3}
        />
        <Line name="Dag" type="monotone" dataKey="day" stroke="#e30938" />
      </LineChart>
      <p>Dagen weer te geven:</p>
      <Input value={days} type='range'  min='1' max={62} onChange={e => set_days(Number(e.target.value))} />
      {/* TODO change to buttons to avoid high server load by making. Or make timeout while sliding... */}

      <Dropdown
        placeholder='Selecteer een dag'
        fluid
        search
        selection
        onChange={(e, f:any) => set_weekday(f.value) }
        options={[    
                      {key: 7, value: undefined, text: "alle dagen"},
                      {key: 0, value: "Sunday", text: "Zondag"},
                      {key: 1, value: "Monday", text: "Maandag"},
                      {key: 2, value: "Tuesday", text: "Dinsdag"},
                      {key: 3, value: "Wednesday", text: "Woensdag"},
                      {key: 4, value: "Thursday", text: "Donderdag"},
                      {key: 5, value: "Friday", text: "Vrijdag"},
                      {key: 6, value: "Saturday", text: "Zaterdag"},
                ]}
  />

    </React.Fragment>
  );
}
