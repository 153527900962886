import React from "react";
import "../../getWeek";
import { customerLogo } from "../../constants";
import { basicAccountmanager } from "../../types/acountmanager";
import { member } from "../../types/member";
import { getWeek } from "../../getWeek";

interface CompanyInfoProps {
  member: member | undefined;
  accountmanager: basicAccountmanager | undefined;
}

export default function CompanyInfo(props: CompanyInfoProps) {
  const currentWeekNumber = getWeek(new Date())
  const style = {
    backgroundImage: "url(" + customerLogo(props?.member?.directory) + ")",
  };

  if (props.accountmanager && (!props.member || !props.member.company))
    return (
      <div className="companyInfo">
        <div className="companyInfoLogoHolder" style={style}></div>
        <div className="companyInfoData">
          <div className="welcomeBox">
            <p>
              <em className="welkom">
                Accountmanager: {props.accountmanager.name}, <br />
              </em>
            </p>
          </div>
        </div>
        <div className="currentWeek">
          <div className="weekNumber">
            <p>
              {" "}
              Huidige week <em className="week">{}</em>
            </p>
          </div>
        </div>
      </div>
    );
  if (!props.member || !props.member.company)
    return <div className="ui huge header">Selecteer een member</div>;
  const { MemberAccountmanager } = props.member;
  return (
    <div className="companyInfo">
      <div className="companyInfoLogoHolder" style={style}>
        {/* <img style={style} src="empty.png" alt="customerLogo" /> */}
      </div>
      <div className="companyInfoData">
        <div className="welcomeBox">
          <p>
            <em className="welkom">
              Accountmanager:{" "}
              {MemberAccountmanager
                ? props.member.MemberAccountmanager.Accountmanager.name
                : "Accountmanager onbekend"}
              , <br />
            </em>

            <em className="welkom">
              Klant: <em className="company">{props.member.company}</em>
            </em>
          </p>
        </div>
      </div>
      <div className="currentWeek">
        <div className="weekNumber">
          <p>
            {" "}
            Huidige week <em className="week">{currentWeekNumber}</em>
          </p>
        </div>
      </div>
    </div>
  );
}
