import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Button } from "semantic-ui-react";
import { todolist } from "../../actions/selectors";
import { getTodolist, addTodo } from "../../actions/videoInput";
import { todo, todoListOwner } from "../../types/todo";
interface todoListAdminProps {
  owner: todoListOwner
}

export default function TodoListAdmin({owner}:todoListAdminProps) {
  const [newTodo, set_newTodo] = useState("")
  const dispatch = useAppDispatch()
  const list:todo[] = useAppSelector(todolist)

  useEffect(() => {
    dispatch(getTodolist())
  } , [dispatch])

  const onClickHandler = (event:FormEvent) => {
    event.preventDefault()
    if(newTodo.length > 4){
      dispatch(addTodo(newTodo, owner))
      set_newTodo("")
    }
  }

  const filterd = list?.filter(e => e.owner === owner)

  return (
    <div className="officeItem double" style={{cursor: "auto"}}>
      <p style={{ fontSize: "1em", marginTop: "0.5em", fontWeight: "bold" }}>
        {"TodoList"} 
        <em className="name">{` ${owner}`}</em>
      </p>
      <ul style={{width: "100%", overflow: "hidden"}}>

      {filterd?.map( (todoItem,i) => {
        
        return <li key={i} style={{   fontSize: "1em", 
                                      marginBottom: "0.1em", 
                                      fontWeight: "bold", 
                                      color: "white",
                                      listStyle: "disc",
                                      }}>{todoItem.todo}</li>
      })} 
      </ul>
      <form onSubmit={onClickHandler} style={{marginBottom: "20px", display: "flex", justifyContent: "center", flexDirection: "column"}}>
      <hr/>
      <p style={{textAlign: "center"}}>Graag zo veel mogelijk info toevoegen!</p>
      <p style={{textAlign: "center"}}>In geval van een storing, welke stappen moet je ondernemen om deze storing te repliceren?</p>
       <input value={newTodo} onChange={ e => set_newTodo(e.target.value) } style={{width: "90%", margin: "auto"}} />
       <br/>
       <Button  style={{width: "90%", margin: "auto"}} type="submit" >Verzenden</Button>
      </form>
    </div>
  );
}
