import React from "react";
import { useAppSelector } from "../hooks/redux";

export interface useAppSelectorProps{
  accepted: Function
  setShowWarning: Function
  message: string
}

export default function OverlayUploadWarning({accepted,setShowWarning, message}:useAppSelectorProps):JSX.Element {
  const user = useAppSelector((reduxStore) => reduxStore.user)

    const buttonHandler = () => {
      accepted(true)
      setShowWarning(false)
    }

    return (
    <div className="overlay">
      <div className="box">
          <h3 >{`${user.name},`}</h3>
          <p style={{paddingTop: "1.5em"}}>{message}</p>
          <button style={{marginTop: "1.5em",cursor: 'pointer'}} onClick={buttonHandler}>Oke! </button>
      </div>
    </div>
  );
}
