import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Card, Icon, Image } from "semantic-ui-react";
import { selectInstalaltionMedia } from "../../actions/selectors";
import { getInstallationMedia,updateInstallationMedia } from "../../actions/videoInput";
import { member } from "../../types/member";
import OverlayImage from "../OverlayImage";


interface InstallationPhotosProps{
  member: member
}

export default function InstallationPhotos({ member }:InstallationPhotosProps) {
  const dispatch = useAppDispatch();
  const [photoNumber, set_photoNumber] = useState(0);
  const [showLarge, set_showLarge] = useState(false);
  const [editModeEnabled, setEditModeEnabled]= useState(false);
  const [photoDescription, setPhotoDescription]= useState("");
  const data = useAppSelector(selectInstalaltionMedia);
  useEffect(() => {
    if (member && member.id) {
      dispatch(getInstallationMedia(member.id));
    }
  }, [member, dispatch]);

  const nextPhoto = () => {
    const newNumber = photoNumber + 1;
    set_photoNumber(photoNumber + 1 < data.length ? newNumber : 0);
  };
  const prevPhoto = () => {
    const newNumber = photoNumber - 1;
    set_photoNumber(photoNumber === 0 ? data.length - 1 : newNumber);
  };


  const editMode = () => {
    if(editModeEnabled){
      dispatch(updateInstallationMedia(data[photoNumber].id, photoDescription))
    }
    setPhotoDescription(data[photoNumber].text) 
    setEditModeEnabled(!editModeEnabled)
  }

  if (!data || !data[0]) return null;

  return (
    <React.Fragment>
      {showLarge ? (
        <OverlayImage
          src={data[photoNumber].link}
          showLarge={set_showLarge}
          nextPhoto={nextPhoto}
          prevPhoto={prevPhoto}
        />
      ) : null}
      <Card>
        <Image
          src={data[photoNumber].link}
          wrapped
          ui={false}
          onClick={() => set_showLarge(true)}
          style={{cursor: 'pointer'}}
        />
        <Card.Content>
          <Card.Header>{member.shopname}</Card.Header>
          <Card.Meta>
            <span className="date">Foto datum: {data[photoNumber].date}</span>
          </Card.Meta>
          {!editModeEnabled ? <Card.Description><Icon name="edit" size="large" onClick={editMode} />{data[photoNumber].text}</Card.Description> : null}
          {editModeEnabled ? <Card.Description><input value={photoDescription} onChange={e => setPhotoDescription(e.target.value)}style={{width: '100%'}} ></input><Icon name="check" size="large" onClick={editMode} /></Card.Description> : null}
        </Card.Content>
        <Card.Content>
          <Card.Content extra>
            <div style={{ display: "flex", justifyContent: "space-between",cursor: 'pointer' }}>
              <p onClick={prevPhoto} style={{cursor: 'pointer'}}>
                <Icon name="angle left" />
                Vorige
              </p>
              {photoNumber + 1}/{data.length}
              <p onClick={nextPhoto} style={{cursor: 'pointer'}}>
                Volgende
                <Icon name="angle right" />
              </p>
            </div>
          </Card.Content>
        </Card.Content>
      </Card>
    </React.Fragment>
  );
}
