import React, { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { useNavigate } from "react-router-dom"
import { setReplaceJwt } from "../actions/actionCreators";
import { selectUser } from "../actions/selectors";
import { firstAdminPageAfterLogin } from "../constants";

export default function Logout(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const [plan, setPlan] = useState<undefined | "logout" | "admin">(undefined);

  useEffect(() => {
    if (
      user.jwt !== null &&
      user.refreshToken &&
      plan === "admin" &&
      user.refreshToken === localStorage.getItem("heibaToken") &&
      user.role === "admin"
    ) {
      navigate(firstAdminPageAfterLogin);
    }
  }, [user.jwt, user.refreshToken, plan, user.role,navigate]);

  useEffect(() => {
    const adminJWt = localStorage.getItem("JWT");
    const heibaToken = localStorage.getItem("heibaToken");
    if (adminJWt && heibaToken) {
      setPlan("admin");
      dispatch(setReplaceJwt(adminJWt, heibaToken));
      localStorage.removeItem("JWT");
    } else {
      setPlan("logout");
      localStorage.removeItem("heibaToken");
      localStorage.removeItem("JWT");
      const timer = setTimeout(() => {
        dispatch({ type: "LOGOUT" });
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [dispatch]);

  
  if (plan === "logout") {
    return (
      <div
      style={{ display: "flex", justifyContent: "center", marginTop: "5em" }}>
        <h1>Uitloggen gelukt</h1>
      </div>
    );
  }
  
  if (plan === "admin") {
    return (
      <div
      style={{ display: "flex", justifyContent: "center", marginTop: "5em" }}>
        <h1>Terug naar admin session</h1>
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "5em" }}>
      <h1>loading...</h1>
    </div>
  );
}
