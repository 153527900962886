 import { SET_AMBANCEVIDEO} from '../actions/actionCreators'
import { ambiancevideo } from '../types/ambiance'



 type ambianceVideoActionTypes = 
      | {type: typeof SET_AMBANCEVIDEO, payload: ambiancevideo[]}
 


const reducer =  (state: ambiancevideo[] = [],  action:ambianceVideoActionTypes) => {
    switch (action.type) {
    case SET_AMBANCEVIDEO:
        return action.payload 

    default:
        return state
    }
}

export default reducer 
