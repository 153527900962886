import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectPlayerStorageInfo } from '../../actions/selectors';
import { getPlayerStorageVideoInfo } from '../../actions/videoInput';
import LoadingIndicator from '../../components/LoadingIndicator';
import VideoPlayer from '../../components/VideoPlayer';
import { backendBaseUrl } from '../../constants';
import { useAppDispatch,useAppSelector } from '../../hooks/redux';

export default function PlayerStorage() {
    const dispatch = useAppDispatch()
    const playerStorageInfo = useAppSelector(selectPlayerStorageInfo)
    const params = useParams<{id: string}>();

    useEffect(()=> {
        if(params?.id){
            dispatch(getPlayerStorageVideoInfo(params?.id))
        }
    }, [params, dispatch])

    const style = {marginTop: "0px", marginBottom: "0px"}
    if(!playerStorageInfo?.videoToken) return <LoadingIndicator loadingText="Video info laden"/>
return <div>
            <h1 style={style}>{playerStorageInfo?.filename}</h1>
            <h2 style={style}>Week: {`${playerStorageInfo?.week}`}</h2>
            <h2 style={style}>MemberId: {`${playerStorageInfo?.customerid}`}</h2>
        <VideoPlayer toPlay={`${backendBaseUrl}/storageitem/${playerStorageInfo?.videoToken}`} styleName=''/>
            <p style={style}>Deze manier van video's downloaden is anders dan de andere players in deze portal</p>
            <p style={style}>Veiliger voor Heiba, omdat de video's minder makkelijk gestolen kunnen worden door mensen van wie deze niet zijn...</p>
  </div>;
}
