import React, { Fragment } from "react";

interface rating {
  rating: number
}

interface AccountmanagerRatingProps {
  ratings: Array<rating>
}

export default function AccountmanagerRating(props:AccountmanagerRatingProps):JSX.Element {
  const tot = props.ratings.reduce((tot, curr) => {
    return tot + curr.rating;
  }, 0);
  if(tot !== 0){
    const avg = (tot / props.ratings.length).toFixed(1);
    return <Fragment>{avg} </Fragment>;
  }else{
    return <Fragment> - </Fragment>;
  }
}
