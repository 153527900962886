import React, { KeyboardEventHandler, useState } from "react";
import {updateWeekSelect} from "../../actions/videoInput"
import { weekInput } from "../../types/weekinput";
import { useAppDispatch } from "../../hooks/redux";

interface PreviewCellCommentsProps{
  input: weekInput
}
export default function PreviewCellComments(props:PreviewCellCommentsProps) {
    const dispatch = useAppDispatch()
    const [comment, set_comment] = useState(props.input.comments)
    const keyHandler:KeyboardEventHandler = (e) => {
        if(e.key === "Enter"){
              dispatch(updateWeekSelect(props.input.id, comment))
        }
    }
  return (
    <div className="ui input">
      <input
        type="text"
        value={comment}
        onKeyDown={keyHandler}
        onChange={e => set_comment(e.target.value)}
      />
    </div>
  );
}
