import React, {useEffect,useState} from 'react'
import { selectAccountmanagers } from '../../actions/selectors';
import { getAllAccountmanagers } from "../../actions/videoInput";
import {useAppDispatch, useAppSelector} from "../../hooks/redux"
import LoadingIndicator from '../LoadingIndicator';
import Graph from './Graph'

export default function AccountManagerGraph():JSX.Element {
    const dispatch = useAppDispatch();
    const accountManagers = useAppSelector(selectAccountmanagers);
    const [graphData, set_graphData ]= useState([]) 
    
    useEffect(()=> {
        const graphDate = accountManagers?.map( (a:any) => {
            return {name: a.name , value: a.MemberAccountmanagers.length}
        }) 
        //@ts-ignore
        set_graphData(graphDate)
      } , [accountManagers])
    
      useEffect(() => {
        dispatch(getAllAccountmanagers());
      }, [dispatch]);
    
    if(graphData?.length < 1) return <LoadingIndicator loadingText='AccountManagers laden'/>
    return (
        <div>
            <Graph 
                    title="Klant verdeling" 
                    data={graphData}/>
        </div>
    )
}
