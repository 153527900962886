import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { addTaskToQueue, getBaseContent } from "../../actions/videoInput";
import { selectBaseContent } from "../../actions/selectors";
import { Button, Table } from "semantic-ui-react";
// baseContentType
export default function BasecontentPage() {
  const dispatch = useAppDispatch();
  const basecontent = useAppSelector(selectBaseContent);
  useEffect(() => {
    dispatch(getBaseContent());
  }, []);

  const addToQueue = (baseContentId: number) => {
    dispatch(addTaskToQueue(baseContentId))
  }

  return (
    <div>
      <h1>Base Content available </h1>
        we moeten even fixen dat je kan zien dat een item in de queue staat, maar dat is kut zoeken in de tabel omdat het json is
      <Table celled>
        <Table.Header>
          <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>filename</Table.HeaderCell>
              <Table.HeaderCell>folder/category</Table.HeaderCell>
              <Table.HeaderCell>video's available</Table.HeaderCell>
              <Table.HeaderCell>constructionVideoId</Table.HeaderCell>
              <Table.HeaderCell>link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {basecontent?.map((content, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{content?.id}</Table.Cell>
                <Table.Cell>{content?.sourceFilename}</Table.Cell>
                <Table.Cell>{content?.sourceCategory}</Table.Cell>
                <Table.Cell>{content?.constructionVideoMeta?.length}</Table.Cell>
                { content?.constructionVideoMeta?.length === 0 ? <Table.Cell><Button onClick={() => addToQueue(content?.id)}>add to queue</Button></Table.Cell> :  
                <Table.Cell>{content?.constructionVideoMeta?.[0]?.constructionVideoId}</Table.Cell>
                }
                {content?.constructionVideoMeta?.[0]?.constructionVideoId ?
                <Table.Cell> <a href={`/libaryInfo/${content?.constructionVideoMeta?.[0]?.constructionVideoId}/video`}>constructionVideoId</a></Table.Cell>
                : <Table.Cell></Table.Cell>}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
