import React from "react";
import MenuItem from "./MenuItem";
import MenuItemAdmin from "./MenuItemAdmin";
import { useAppSelector } from "../hooks/redux";
export default function SubMenu():JSX.Element {
  const user = useAppSelector((reduxState) => reduxState.user);
  return (
    <div className="menuBar">
      <ul className="menuItems">
        <MenuItem pos="SubLeft" to="preview" name="PREVIEW" />
        <MenuItem pos="SubLeft" to="input" name="INPUT" />
        <MenuItemAdmin
          pos="SubLeft"
          to="office"
          admin={user.role === "admin"}
          name="OFFICE"
        />
      </ul>
    </div>
  );
}
