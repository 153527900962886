import React from "react";
import { useNavigate } from "react-router-dom";
import { RESET_WEEKINPUT } from "../actions/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

 export interface OverlayProps{
    show: Boolean
    to: string
 }


export default function Overlay(props:OverlayProps) {
  const user = useAppSelector((reduxStore) => reduxStore.user)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()  
  if(!props.show){
        return null
    }   

    const buttonHandler = () => {
      dispatch({type: RESET_WEEKINPUT})
      navigate(props.to)
    }

    return (
    <div className="overlay">
      <div className="box">
          <h3>Goed Bezig {`${user.name}`}</h3>
          <p>Lever direct aan voor de volgende week!</p>
          <button onClick={buttonHandler} style={{cursor: 'pointer'}}>Lever aan voor volgende week </button>
      </div>
    </div>
  );
}
