import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store'
import * as Sentry from '@sentry/browser';

const backend = localStorage.getItem("backend")
const development = localStorage.getItem("development")

if(!backend && !development){
      Sentry.init({dsn: "https://0b8957e3d46d4e72bab66f4afaaef9d3@o399664.ingest.sentry.io/5257131"})
 }

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>, document.getElementById('root'));


serviceWorker.unregister();
