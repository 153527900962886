import React from 'react'
import { useEffect } from 'react'
import { setAppErrorReset } from '../actions/actionCreators'
import { selectAppStateError } from '../actions/selectors'
import { useAppDispatch,useAppSelector } from '../hooks/redux'

export default function DisplayError() {
    const message = useAppSelector(selectAppStateError)
    const dispatch = useAppDispatch()

    useEffect(()=> {
        const timer = setTimeout(() => {
            dispatch(setAppErrorReset());
          }, 6000);
          return () => clearTimeout(timer);
    },[dispatch, message])

    if(!message) return null
    return (
        <div className="errorbox"> 
            <p> {message}</p>
        </div>
    )
}
