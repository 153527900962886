import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";

interface LoadingIndicatorProps {
  loadingText: string;
}

export default function LoadingIndicator({
  loadingText,
}: LoadingIndicatorProps): JSX.Element {
  const [displayCancel, setDisplayCancel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setDisplayCancel(true);
    }, 4000);
  }, []);
  return (
    <div>
      <Button
        content="Show"
        icon="plus"
        labelPosition="left"
        onClick={() => {}}
      />

      <Dimmer active={true} onClickOutside={() => {}} page>
        <Header as="h2" icon inverted>
          {/* <Icon name='heart' /> */}

          <Loader inverted inline content={loadingText} />
          <Header.Subheader style={{ color: "#ec7026" }}>
            Dit zou niet te lang moeten duren...
          </Header.Subheader>
          {displayCancel ? (
            <button
              onClick={() => navigate(-1)}
              style={{
                color: "black",
                zIndex: 2,
                marginTop: "1em",
                fontSize: "16px",
              }}
            >
              Annuleren??
            </button>
          ) : null}
        </Header>
      </Dimmer>
    </div>
  );
}
