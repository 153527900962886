import React, { useEffect, Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { selectConnectPlayers, selectPlayers } from "../../actions/selectors";
import { getAllPlayers, getNewPlayers, getMembers,getOldPlayers } from "../../actions/videoInput";

import PlayersTable from "../../components/admin/PlayersTable";
import PlayersTypeList from "../../components/admin/PlayerTypesList";

export default function Players() {
  const [type, set_type] = useState< "active" | "inactive"> ("active")
  const dispatch = useAppDispatch();
  const players = useAppSelector(selectPlayers);
  const connectPlayers = useAppSelector(selectConnectPlayers)
  const [search, setSearch] = useState('')
  
  useEffect(() => {
    if(type === "inactive"){
      dispatch(getOldPlayers())
    }else{
      dispatch(getAllPlayers());
      dispatch(getNewPlayers())
      dispatch(getMembers())  //this one is used for new players
    }
  }, [dispatch, type]);

  useEffect(() => {
    dispatch(getNewPlayers())
  }, [dispatch, players]);

return(
  <Fragment>
  <PlayersTypeList set_type={set_type}  type={type}/>
  <PlayersTable players={connectPlayers} connect/>
  <div className="playerSearchHolder">
    <input className="playerSearch" value={search} onChange={(e)=> setSearch(e.target.value)} placeholder="Zoek op bedrijf, id of player id"></input>
  </div>
  <hr/>
  <PlayersTable players={players} search={search}/>
  </Fragment>
  )

}