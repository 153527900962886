import React, { useState } from "react";
import DropzoneWrapperWebshop from "../../components/admin/DropzoneWrapperWebshop";
import { Table } from "semantic-ui-react";

export default function UploadWebshop() {
  // const [lock , setLock] = useState(false)
  // const [warning , setWarning] = useState(false) 
  // const [wait , setWait] = useState(false)  
  const dummyFn = () => console.log("Dummy")  //TODO
  const [errorList, set_errorList] = useState<any[]| null>(null)
  const [productList, set_productList] = useState<any[]| null>(null)
  
  
  const webshopTemplateHeader   = ["PLU","Naam","Prijs","PrijsEenheid","VerkoopVolume","VerkoopEenheid","Hoofdproduct","Categorie" ]
  // const webshopTemplateHeaderEn = ["plu", "name", "price", "priceUnit", "salesVolume", "salesUnit", "masterProduct", "categories"]
  return (
    <div>
      Upload for webshops
      <br />
      <br />
      <hr />
      <DropzoneWrapperWebshop setLock={dummyFn} setWarning={dummyFn} setWait={dummyFn} set_errorList={set_errorList} set_productList={set_productList}/>
      {productList ? <p>Geweldig, in je upload zaten {productList?.length} items. Bekijk ze in op je <a href="/webshop"><em>productenPagina</em></a></p> : null}
      
      {errorList ?  
      <Table celled>
        <Table.Header>
          <Table.Row>
            {webshopTemplateHeader?.map(header => <Table.HeaderCell>{header}</Table.HeaderCell>)}
            <Table.HeaderCell>Foutmelding</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {errorList?.map((webshopProduct, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{webshopProduct?.plu}</Table.Cell>
                <Table.Cell>{webshopProduct?.name}</Table.Cell>
                <Table.Cell>{webshopProduct?.price}</Table.Cell>
                <Table.Cell>{webshopProduct?.priceUnit}</Table.Cell>
                <Table.Cell>{webshopProduct?.salesUnit}</Table.Cell>
                <Table.Cell>{webshopProduct?.salesVolume}</Table.Cell>
                <Table.Cell>{webshopProduct?.masterProduct}</Table.Cell>
                <Table.Cell>{webshopProduct?.categories}</Table.Cell>
                <Table.Cell><a  href={`/webshopErrorExplanation`} 
                                target="_blank"
                                rel="noreferrer">
                                {webshopProduct?.error}
                            </a>
                            </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table> : null}
    </div>
  );
}
