import { SET_MEDIA_LIBARY_MEDIA} from '../actions/actionCreators'
import { constructionPhoto, constructionVideo } from '../types/constructionMedia'

export interface mediaLibaryState {
    videos: constructionVideo[],
    photos: constructionPhoto[],
}

export type mediaLibaryActionTypes = 
  | {type: typeof SET_MEDIA_LIBARY_MEDIA; payload: mediaLibaryState }

const reducer = (state:mediaLibaryState = {videos: [], photos: []},  action:mediaLibaryActionTypes) => {
    switch (action.type) {
    case SET_MEDIA_LIBARY_MEDIA:
        if(action?.payload?.photos?.length === 0){
            return {...state, videos: [...action.payload.videos]  }
        }
        return {...state, ...action.payload  }
    default:
        return state
    }
}
export default reducer
