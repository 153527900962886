import { FC } from 'react'
// import { CSSProperties, FC } from 'react'
import { useDrop } from 'react-dnd'
import { useAppSelector } from '../../hooks/redux'
import { selectConstructionVideo } from '../../actions/selectors'
import VideoCard from './VideoCard'
export const ItemTypes = {
    BOX: 'box',
  }

// const style: CSSProperties = {
//   height: '12rem',
//   width: '12rem',
//   marginRight: '1.5rem',
//   marginBottom: '1.5rem',
//   color: 'white',
//   padding: '1rem',
//   textAlign: 'center',
//   fontSize: '1rem',
//   lineHeight: 'normal',
//   float: 'left',
// }

export interface MediaPlacementLocationProps {
  step: number
}

function selectBackgroundColor(isActive: boolean, canDrop: boolean) {
  if (isActive) {
    return '#53cdde'
  }else {
    return 'white'
  }
}

export const MediaPlacementLocation: FC<MediaPlacementLocationProps> = ({ step }) => {
    const constructionVideos = useAppSelector(selectConstructionVideo);
    const filtered = constructionVideos.find(v => v.position === step)
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: () => ({
        name: `${step} Dustbin`,
        
        droppedTo: step
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )
  const isActive = canDrop && isOver && !filtered
  const backgroundColor = selectBackgroundColor(isActive, false)
  return (
    <div ref={drop} className="placementVideoCardLocation" style={{backgroundColor }}>
          { filtered ? <VideoCard data={filtered}  name="Glass"/> : null}
    </div>
  )
}
