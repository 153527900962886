import React, {useEffect,useState} from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { selectTasks } from '../../actions/selectors';
import { getTaskList } from "../../actions/videoInput";
import Graph from './Graph'

export default function TaskQueueGraph() {
    const dispatch = useAppDispatch();
    const tasks = useAppSelector(selectTasks);
    const [graphData, set_graphData ]= useState([{}]) 
    
    useEffect(()=> {
      if(tasks){
        
        const completed = tasks.filter(t => t.completed).length || 0
        const failed = tasks.filter(t => t.failed).length || 0
        const todo = tasks.filter(t => t.completed === false).length || 0
        set_graphData([{name: "todo taken", value: todo, color: "#2E2EFF"} ,{name: "Gedaane taken", value: completed, color: "#00D100"}, {name: "Mislukt", value: failed, color: "#FF4263"}])
      }
      } , [tasks])

  useEffect(() => {
    dispatch(getTaskList());
  }, [dispatch]);

    return (
        <div>
            <Graph title="ServerTaken" data={graphData}/>
        </div>
    )
}
