import React from "react";
import { Table } from "semantic-ui-react";
import { player } from "../../types/player";

interface  PlayerDetailsSDProps{
  data: player
}

export default function PlayerDetailsSD(props:PlayerDetailsSDProps) {
  if (!props.data) return null;

  const generalCell = (header:string, info:string) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" ,minWidth: "120px"}}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em", minWidth: "150px" }}>{info}</Table.Cell>
      </Table.Row>
    );
  };

  const sdNameParser = (title:string, name:string) => {

    if (name === "USDU1") {
      return generalCell(title, "High Endurance")
    }
    generalCell(title, name)
  }
  const sdManParser = (title:string, SD_card_manfid:string) => {

    if (SD_card_manfid === "0x000003") {
      return generalCell(title, "Sandisk")
    }
    if (SD_card_manfid === "0x001B") {
      return generalCell(title, "Transcend/Samsung")
    }
    if (SD_card_manfid === "0x000074") {
      return generalCell(title, "Transcend")
    }
    if (SD_card_manfid === "0x000058") {
      return generalCell(title, "Utility(Farnell)")
    } 
    if (SD_card_manfid === "0x0002") {
      return generalCell(title, "Kingston")
    } 
    if (SD_card_manfid === "0x000027") {
      return generalCell(title, "Kingston")
    } 
    if (SD_card_manfid === "0x0041") {
      return generalCell(title, "Kingston")
    } 
    if (SD_card_manfid === "0x00006f") {
      return generalCell(title, "Xoueshone PLEASE REPLACE ASAP!")
    } 
    if (SD_card_manfid === "0x0000") {
      return generalCell(title, "FAKE CARD PLEASE REPLACE ASAP!")
    } 
    if (SD_card_manfid === "0x000000 ") {
      return generalCell(title, "FAKE CARD PLEASE REPLACE ASAP!")
    }
    generalCell(title, `${SD_card_manfid}(onbekend)`)
  }


  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Item</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Info</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>

          {props.data.PIplayerStatus && props.data.PIplayerStatus.version
            ? generalCell("SD datum", props.data.PIplayerStatus.SD_card_date)
            : null}

          {props.data.PIplayerStatus && props.data.PIplayerStatus.SD_card_manfid
            ? sdManParser("Fabrikant id", props.data.PIplayerStatus.SD_card_manfid)
            : null}
          {props.data.PIplayerStatus && props.data.PIplayerStatus.SD_card_name
            ? sdNameParser("Kaartnaam", props.data.PIplayerStatus.SD_card_name)
            : null}
          {props.data.PIplayerStatus && props.data.PIplayerStatus.SD_card_oemid
            ? generalCell("oemID", props.data.PIplayerStatus.SD_card_oemid)
            : null}
          {props.data.PIplayerStatus && props.data.PIplayerStatus.SD_card_dsr
            ? generalCell("Sd dsr", props.data.PIplayerStatus.SD_card_dsr)
            : null}

        </Table.Body>
      </Table>
    </div>
  );
}
