import React from "react";

interface MenuItemProps{
  to: string,
  pos: string,
  name: string
}

export default function MenuItem({to, pos, name}:MenuItemProps) {
  const pahthName = window.location.pathname.substr(1); // todo why not use hook here?
  let active = "";
  if (pahthName === to) {
    active = "active";
  }
  if(to === "input"){
    if(pahthName === 'productfocus' ||  
       pahthName === 'season' ||  
       pahthName === 'ambiance' ||  
       pahthName === 'extraInfo' ||  
       pahthName === 'upload' ||  
       pahthName === 'summary' 
    ){
      active = "active";}
    
  }
  return (
    <li className={`menuBar${pos} ${active}`}>
      <a href={`/${to}`}> {name} </a>
    </li>
  );
}
