import React, { Fragment } from "react";
import { Icon, Dropdown, Menu } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { updateWeekSelect } from "../../actions/videoInput";
import { weekInput } from "../../types/weekinput";
import { member } from "../../types/member";
import { basicAccountmanager } from "../../types/acountmanager";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface PreviewCellStatusProps{
  input: weekInput
  member: member | undefined
  accountmanager: basicAccountmanager | undefined
}

export default function PreviewCellStatus(props:PreviewCellStatusProps) {
  const dispatch = useDispatch();
  let icon:SemanticICONS;
  let txt;
  switch (props.input.status) {
    case 0:
      icon = "edit outline";
      txt = "Klant vult gegevens in";
      break;
    case 1:
      icon = "envelope outline";
      txt = "Nieuw";
      break;
    case 2:
      icon = "file video outline";
      txt = "Wordt gemaakt";
      break;
    case 3:
      icon = "wait";
      txt = "Wacht op acceptatie";
      break;
    case 4:
      icon = "redo alternate";
      txt = "Aanpassing maken";
      break;
    case 5:
      icon = "check";
      txt = "Klaar(accepted)";
      break;
    case 6:
      icon = "check";
      txt = "Klaar(accepted)";
      break;
    default:
      icon = "exclamation circle";
      txt = "Oops";
  }
  const options = [
    {
      key: 0,
      text: (
        <Fragment>
          <Icon name="edit outline" />
          Klant vult gegevens in
        </Fragment>
      ),
      value: 0,
    },
    {
      key: 1,
      text: (
        <Fragment>
          <Icon name="envelope outline" />
          Nieuw
        </Fragment>
      ),
      value: 1,
    },
    {
      key: 2,
      text: (
        <Fragment>
          <Icon name="file video outline" />
          Wordt gemaakt
        </Fragment>
      ),
      value: 2,
    },
    {
      key: 3,
      text: (
        <Fragment>
          <Icon name="wait" />
          Wacht op acceptatie
        </Fragment>
      ),
      value: 3,
    },
    {
      key: 4,
      text: (
        <Fragment>
          <Icon name="redo alternate" />
          Aanpassing maken
        </Fragment>
      ),
      value: 4,
    },
    {
      key: 5,
      text: (
        <Fragment>
          <Icon name="check" />
          Klaar(accepted)
        </Fragment>
      ),
      value: 5,
    },
  ];

  const onChangeHandler = (e:any, f:any) => {
    if (props?.member && props?.member.id) {
      dispatch(
        updateWeekSelect(props?.input?.id, null, f.value, props?.member?.id)
        );
      }else if(props.accountmanager && props.accountmanager.id){
        dispatch(
          updateWeekSelect(props?.input?.id, null, f.value, null, props?.accountmanager.id)
          );
        }
        else{
      dispatch(
        updateWeekSelect(props.input.id, null, f.value)
      );
    }
  };

  return (
    <Fragment>
      <Menu compact>
        <Dropdown
      //@ts-ignore
          text={
            <Fragment>
              <Icon name={icon} />
              {txt}
            </Fragment>
          }
          options={options}
          simple
          item
          onChange={onChangeHandler}
        />
      </Menu>
    </Fragment>
  );
}
