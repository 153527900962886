import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { addFtpMember, getFtpMembers } from "../../actions/videoInput";
import { Table } from "semantic-ui-react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Fragment } from "react";
import { selectFtpMembers } from "../../actions/selectors";
import { ftpMembers as FtpMembertype } from "../../types/ftpMembers";

export default function FtpMembers() {
  const dispatch = useAppDispatch();
  const ftpMembers:FtpMembertype[] = useAppSelector(selectFtpMembers) 

  const [folder, set_folder] = useState("/home/heiba/data")
  const [gid, set_gid] = useState("1000")
  const [uid, set_uid] = useState("2001")
  const [name, set_name] = useState("")
  const [password, set_password] = useState("")
  const [active] = useState(true)
  const [addUser, set_addUser] = useState(false)

  useEffect(() => {
    dispatch(getFtpMembers());
  }, [dispatch]);

  const addALine = () => {
    dispatch(addFtpMember({
      name,
      uid,
      gid,
      folder,
      active,
      password
    }))
  }

  if (!ftpMembers) return <LoadingIndicator loadingText="Loading ftpMembers" />
  return (
    <div>
      <h2>
        Hints:
        </h2>


      <p> Group id moet altijd 1000 zijn.</p>
      <p> User id voor lezen/schrijven = 2000 (admins)</p>
      <p> User id voor lezen is 2001</p>
      <p> Status/active werkt niet. (wordt niet gechecked door media server)</p>
      <p> Home folder (/home/heiba) bevat logfiles, en de redirect website</p>
      <p> Data folder (/home/heiba/data ) bevat de folders toegangkelijk voor de ftp server </p>
      <p> Data folder (/home/heiba/uploads ) is de plek waar uploads van de portal terrecht komen </p>
      <p> Data folder (/home/heiba/preview ) is de plek waar gekeken wordt naar preview videos</p>

      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Gebruikersnaam</Table.HeaderCell>
            <Table.HeaderCell> User id </Table.HeaderCell>
            <Table.HeaderCell>Group id</Table.HeaderCell>
            <Table.HeaderCell>Folder</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            {addUser ? <Table.HeaderCell>Password</Table.HeaderCell> : null}

          </Table.Row>
        </Table.Header>

        <Table.Body>
          {ftpMembers.map((account, i) => {
            if (!account) return null;
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  {account.user}
                </Table.Cell>
                <Table.Cell>
                  {`${account.uid}   (${ account.uid === "2001" ? "Lezen" : "Lezen + schrijven" })` }
                </Table.Cell>
                <Table.Cell>
                  {account.gid}
                </Table.Cell>
                <Table.Cell>
                  {account.dir}
                </Table.Cell>
                <Table.Cell>
                  {account.status.toString()}
                </Table.Cell>
              </Table.Row>
            );
          })}
          {addUser ? <Fragment>
            <Table.Row>


              <Table.Cell>
                {
                  <input
                    placeholder="Vul een inlognaam in"
                    onChange={(e) => set_name(e.target.value)}
                    value={name}
                  ></input>
                }

              </Table.Cell>
              <Table.Cell>
                {
                  <input
                    placeholder="2000"
                    onChange={(e) => set_uid(e.target.value)}
                    value={uid}
                  ></input>
                }


              </Table.Cell>
              <Table.Cell>
                {
                  <input
                    placeholder="1000"
                    onChange={(e) => set_gid(e.target.value)}
                    value={gid}
                  ></input>
                }

              </Table.Cell>
              <Table.Cell>
                {
                  <input
                    placeholder="/home/heiba"
                    onChange={(e) => set_folder(e.target.value)}
                    value={folder}
                  ></input>
                }
              </Table.Cell>

              <Table.Cell>
                {active.toString()}
              </Table.Cell>
              <Table.Cell>
                {
                  <input
                    placeholder="password"
                    onChange={(e) => set_password(e.target.value)}
                    value={password}
                  ></input>
                }

              </Table.Cell>

            </Table.Row>
          </Fragment> : null
          }
        </Table.Body>
      </Table>
      { !addUser ? <button onClick={e => set_addUser(!addUser)} style={{cursor: 'pointer'}}>Gebruiker toevoegen</button> : null}
      { addUser ? <button onClick={addALine} style={{cursor: 'pointer'}}>Versturen</button> : null}
    </div>
  );
}

