import React from 'react'
import AccountManagerGraph from "../../components/admin/AccountManagerGraph";
import PlayersGraph from "../../components/admin/PlayersGraph"
import VideoInputStats from '../../components/admin/VideoInputStats';
import InputFlow from '../../components/admin/InputFlow';
import GenerateLinkForInput from '../../components/admin/GenerateLinkForInput';
import NumberBoxOfficeAdmin from '../../components/admin/NumberBoxOfficeAdmin';
import { membersCount, playersCount,  selectMalfunctionsOpenCount,AmbianceCount } from '../../actions/selectors';
import { getAllPlayers, getMalfunctions, getMembers, getDashboardCount, UpdateLibary } from '../../actions/videoInput';
import TodoListAdmin from '../../components/admin/TodoListAdmin';
import { useNavigate } from 'react-router-dom';
import NigthlyPlayersGraph from '../../components/admin/NightlyPlayersGraph';
import TaskQueueGraph from '../../components/admin/TaskQueueGraph';


export default function OfficeHome() {
const navigate = useNavigate()
const year =  new Date().getFullYear()

    return (
        <div className="officeHome">
            <PlayersGraph/>
            <NigthlyPlayersGraph/>
            <AccountManagerGraph/>
            <TaskQueueGraph/>
            <VideoInputStats/>
            <InputFlow/>
            <GenerateLinkForInput/>
            <NumberBoxOfficeAdmin title="Aantal klanten" action={getMembers} selector={membersCount} click={()=> navigate("/loginlike")} />
            <NumberBoxOfficeAdmin title="Players" action={getAllPlayers}  selector={playersCount} click={()=>navigate("/players")}/>
            <NumberBoxOfficeAdmin title="Storingen" action={getMalfunctions} actionValue={year} selector={selectMalfunctionsOpenCount} click={()=> navigate("/malfunctions")} />
            <NumberBoxOfficeAdmin title="Sfeer videos" action={getDashboardCount} actionValue="sfeer" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Vlees videos" action={getDashboardCount} actionValue="vlees" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Vis videos" action={getDashboardCount} actionValue="vis" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Algemeen videos" action={getDashboardCount} actionValue="algemeen" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Kip videos" action={getDashboardCount} actionValue="kip" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Kaas videos" action={getDashboardCount} actionValue="kaas" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Bloem videos" action={getDashboardCount} actionValue="bloem" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Brood videos" action={getDashboardCount} actionValue="brood" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Banket videos" action={getDashboardCount} actionValue="banket" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <NumberBoxOfficeAdmin title="Groente videos" action={getDashboardCount} actionValue="groente" selector={AmbianceCount} selectorFn click={UpdateLibary}/>
            <TodoListAdmin owner="jeroen"/>
            <TodoListAdmin owner="heiba"/>
        </div>
    )
}
