import reducer from './reducers'
import { loadState, saveState } from './localstorage'
import throttle from 'lodash.throttle';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer,
  preloadedState: loadState(),
})

store.subscribe(throttle(() => {
  //@ts-ignore
    saveState({
      user: store.getState().user
    });
  }, 1000));


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store