import React from 'react'
import LivedaysScheduler from '../../components/admin/LivedaysScheduler'
import { getLivedays,getGeneralLiveDay } from "../../actions/videoInput";
import { addIgnoreDay, addGeneralLiveDay } from "../../actions/videoInput";

export default function Livedays() {

  return (
    <LivedaysScheduler 
              getLivedays={getLivedays} 
              getGeneralLiveDay={getGeneralLiveDay}
              addIgnoreDay={addIgnoreDay}
              addGeneralLiveDay={addGeneralLiveDay} 
              scheduleType={"PIPlayerCopyDays"}
              ignoreType={"PIPlayerCopyDayIgnores"}
              extraType={"PIPlayerCopyDayExtras"}
              />
  )
}
