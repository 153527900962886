import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { Dropdown } from "semantic-ui-react";
import { selectMediaLibaryVideo } from "../../actions/selectors";
import { addVideoToWeek, getConstructionMedia } from "../../actions/videoInput";
import { constructionMediaType, LibaryMediaType } from "../../types/constructionMedia";

interface PlaceVideoProps{
  type?: constructionMediaType
  week: number
  year: number
  step?: number
  complete: boolean
  category: LibaryMediaType 
}


export default function PlaceVideo(props:PlaceVideoProps) {
  const dispatch = useAppDispatch();
  const mediaLibaryVideo = useAppSelector( selectMediaLibaryVideo)
  const [selectedVideo, set_selectedVideo] = useState(0);

  useEffect(() => {
    dispatch(getConstructionMedia(  props.category, props.type));
  }, [props.type, props.category,  dispatch]);

  const submitHandler = (e:FormEvent) => {
    e.preventDefault();
    if (selectedVideo !== 0) {
      dispatch(
        addVideoToWeek(
          props.week,
          props.year,
          props.category,
          selectedVideo,
          props.step,
          "video"
        )
      );
    }
  };

  const options = mediaLibaryVideo?.map((media) => { return {key: media.id , value: media.id, text: `${media.description}(${media.name})`} })
  if (props.complete) return null;
  return (
    <div>
      <form onSubmit={submitHandler}>
        <Dropdown
          placeholder={`Voeg een ${props.type === "image" ? "image" : "video"} toe aan week ${props.week}`}
          fluid
          search
          selection
          onChange={(e, f) => set_selectedVideo(Number(f.value))} 
          options={options}
        />

        <button className="adminButton" type="submit">
          voeg toe!
        </button>
      </form>
    </div>
  );
}
