import React from 'react'

interface TopicLineProps{
    white?: Boolean
}

export default function TopicLine({white}:TopicLineProps):JSX.Element {
    if(white){
        return ( <span className='hr white'/>)    
    }else{
        return ( <span className='hr'/>)
    }
}
