import React, { useState, useEffect, useCallback } from "react";
import CustomerSelect from "../../components/admin/CustomerSelect";
import PlayerSelect from "../../components/admin/PlayerSelect";
import PlayerDetailsCustomerInfo from "../../components/admin/PlayerDetailsCustomerInfo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import {
    getAllPlayers,
  getCustomerInfo,
  getFilesOnServer,
  getNewPlayers,
  getPlayerInfo,
  getPlayers,
  linkPlayer,
  uploadInstallation,
} from "../../actions/videoInput";
import AdminCellDetach from "../../components/admin/AdminCellDetach";
import { Icon } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { scrollTop } from "../../toTop";
import { selectServerVideo, selectConnectPlayers, selectPlayer, selectrMember } from "../../actions/selectors";
import AlertMessage from "../../components/AlertMessage";


export default function Installation() {
  const [customerId, set_CustomerId] = useState("Selecteer een klant");
  const [currentLocation, set_currentLocation] = useState<number | undefined>(
    undefined
  );
  const playerInfo = useAppSelector(selectPlayer);
  const connectPlayers = useAppSelector(selectConnectPlayers);
  const member = useAppSelector(selectrMember);
  const serverInfo = useAppSelector(selectServerVideo)

  const [player, set_player] = useState<undefined | string>(undefined);
  const [messageId, set_messageId] = useState(0)
  const dispatch = useAppDispatch();
  
  useEffect(()=> {
    if(messageId !== 0){
      const timer = setTimeout(() => {
        set_messageId(0)
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [messageId])



  useEffect(() => {
    if (player) {
      dispatch(getPlayerInfo(player));
    }
  }, [player, dispatch]);
  
  useEffect(() => {
    if(playerInfo?.piplayerHealth?.id){
      dispatch(getFilesOnServer(Number(playerInfo.piplayerHealth.id)))   
    }
  }, [playerInfo, dispatch]);
  
  
  useEffect(() => {
    if (currentLocation ) {
      dispatch(getFilesOnServer(currentLocation))   
      dispatch(getPlayers(currentLocation));
      dispatch(getCustomerInfo(currentLocation));
    }
    scrollTop()
  }, [currentLocation, dispatch]);

  const refreshHandler = () => {
    if (player) {
      dispatch(getPlayerInfo(player));
    }
    if (currentLocation ) {
      dispatch(getPlayers(currentLocation));
    }
    dispatch(getNewPlayers());
    scrollTop()
  };

  const uploadSucces = () => {
    set_messageId(102)
  }

  const playerUpdateSucces = () => {
    set_messageId(101)
    refreshHandler()
  };

  const saveHandler = () => {
    if (typeof customerId !== "number") return null;
    if(player){
      dispatch(linkPlayer(player, customerId, playerUpdateSucces));
    }
  };
  const exitCustomerHandler = () => {
    set_currentLocation(undefined);
    dispatch(getAllPlayers());
    dispatch(getNewPlayers());
  };
 

  const onDrop = useCallback(acceptedFiles => {
    if(!currentLocation) return
    acceptedFiles.map((file:any) => {

      return dispatch(uploadInstallation(file, currentLocation, uploadSucces))
    });
  }, [dispatch, currentLocation]);
  
  const {  getRootProps, getInputProps } = useDropzone({
    onDrop
  });


  const styleDiv = {    margin: "1em" as const, 
                        alignItems: "center" as const,
                        display: "flex" as const,
                        justifyContent: "center" as const ,
                        flexDirection: "column" as const,
                        cursor: "pointer" as const, 
                                        
}
const styleP = {textAlign: "center" as const}

  return (
    <div>
      <h1>Installatie</h1>
      <AlertMessage messageId={messageId} />
      <p>
        {" "}
        {!currentLocation
          ? `Welke player heb je? Of bij welke klant ben je?`
          : `Je bent bij: ${
              member ? member.company : null
            }(${currentLocation})`}
      </p>

      {connectPlayers?.map((newPlayer, i) => {
        return (
          <div onClick={(e) => set_player(newPlayer["MAC adres"])} key={i} style={{cursor: 'pointer'}}>
            <p style={{ color: "orange", margin: "1em", fontWeight: "bold" }}>
              {newPlayer["MAC adres"]}
            </p>{" "}
          </div>
        );
      })}
      <br />
      <br />
      <br />
      <PlayerSelect
        selectedPlayer={player}
        set_player={set_player}
        style={{ marginTop: "2em", marginBottom: "2em" }}
        complete={false}
        customerId={currentLocation}
      />

      {!player && !currentLocation ? (
        <CustomerSelect
          set_CustomerId={set_currentLocation}
        />
      ) : null}

      {player && playerInfo  ? <PlayerDetailsCustomerInfo data={playerInfo} serverVideo={serverInfo}/> : null}
      <br />
      {player &&
      playerInfo?.piplayerHealth &&
      playerInfo?.piplayerHealth?.id === "0" ? (
        <CustomerSelect set_CustomerId={set_CustomerId} />
      ) : null}
      <br />
      
      <br />
      <br />
      <div
        style={{
          display: "flex",
          marginTop: "3em",
          marginBottom: "3em",
          justifyContent: "center",
        }}
      >
{customerId !== "Selecteer een klant" && player &&
      playerInfo?.piplayerHealth &&
      playerInfo?.piplayerHealth.id === "0"? (
        <div onClick={saveHandler} style={styleDiv}>
        <Icon name="save" size="large" />
        <p style={styleP}>Koppel</p>
      </div>
      ) : null}

        {player  &&
        playerInfo?.piplayerHealth &&
        playerInfo?.piplayerHealth?.id !== "0" ? (
          <AdminCellDetach
            player={ playerInfo} //needs testing
            customer={currentLocation || 0} 
            size="large"
            text="Unlink player"
            style={styleDiv}
            cb={playerUpdateSucces}
          />
        ) : null}
        <div onClick={refreshHandler} style={styleDiv}>
          <Icon name="refresh" size="large" />
          <p style={styleP}>Refresh</p>
        </div>
        {player ? (
          <div onClick={() => set_player(undefined)} style={styleDiv}>
            <Icon name="zoom-out" size="large" />
            <p style={styleP} >Exit details</p>
          </div>
        ) : null}
        {currentLocation ? (
          <div {...getRootProps({ })}  style={styleDiv}>
            <Icon name="camera" size="large" />
            <input {...getInputProps()} name="inputUpload" />
            <p style={styleP}>Upload foto</p>
          </div>
        ) : null}
        {currentLocation ? (
          <div  onClick={exitCustomerHandler} style={styleDiv} >
            <Icon name="move" size="large" />
            <p style={styleP}>Exit klant</p>
          </div>
        ) : null}
      </div>
     </div>
  );
}
