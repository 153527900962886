import { SET_PREVIEW } from "../actions/actionCreators"
import { previewVideo } from "../types/previewVideo"


export interface previewState{
    videos: previewVideo[] | null
}

const initialState:previewState = {
    videos: null
}

type previewActionTypes = 
    | {type: typeof SET_PREVIEW; payload: previewVideo[]}


const reducer = (state = initialState, action:previewActionTypes) => {
    switch (action.type) {

    case SET_PREVIEW:
        return {...state, videos: action.payload}

    default:
        return state
    }
}


export default reducer