import React, { useState } from "react";
import { Dropdown, Icon, Table } from "semantic-ui-react";
import { addCopyday } from "../../actions/videoInput";
import { PIPlayerCopyDays, WebPBannerCopyDays, WebPlayerCopyDays } from "../../types/livedays";
import { useAppDispatch } from "../../hooks/redux";

interface  PlayerDetailsSDProps{
  playerCopyday: PIPlayerCopyDays[] | undefined  
  webplayercopyday: WebPlayerCopyDays[] | undefined  
  webBannercopyday: WebPBannerCopyDays[] | undefined 
  memberId: number
}

export default function PlayerDetailsLiveday(props:PlayerDetailsSDProps) {
  const [copydayType, set_copydayType] = useState(undefined)
  const dispatch = useAppDispatch()
  if (!props.playerCopyday) return null;
  if (!props.webplayercopyday) return null;
  if (!props.webBannercopyday) return null;


  const generalCell = (header:string, info:string) => {
    return (
      <Table.Row key={header}>
        <Table.Cell style={{ padding: ".28571429em" ,minWidth: "120px"}}>{header}</Table.Cell>
        <Table.Cell style={{ padding: ".28571429em", minWidth: "150px" }}>{info}</Table.Cell>
      </Table.Row>
    );
  };
  const addCopydayToCustomer = () => {
    if(copydayType !== undefined) {
      // @ts-ignore
      dispatch(addCopyday(copydayType, props.memberId));
    }
  }


  const addButton = () => {
    return generalCell(
      //@ts-ignore
      dropdown(),
      <div style={{ width: "60px", textAlign: "center" }}>
        <Icon name="plus circle" onClick={addCopydayToCustomer} style={{cursor: 'pointer'}} />
      </div>
    );
  };
   const options = [
     { key: 1, value: "website", text: `Website` },
     { key: 2, value: "banner ", text: `Banner` }
   ];
  const dropdown = () => {
    return (
      <div>
        <Dropdown
          placeholder="Selecteer copyday type"
          fluid
          search
          selection
          //@ts-ignore
          onChange={(e, f) => set_copydayType(f.value)}
          options={options}
        />
      </div>
    );
  };



  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Copyday Type</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em", minWidth: "150px"}}>Day</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.playerCopyday?.map(copyday =>  generalCell("PiPlayer", copyday.schedule))}
          {props.webplayercopyday?.map(copyday =>  generalCell("Website", copyday.schedule))}
          {props.webBannercopyday?.map(copyday =>  generalCell("Website Banner", copyday.schedule))}
          {addButton()}
        </Table.Body>
      </Table>
    </div>
  );
}
