import React from "react";
import "../getWeek";
import { selectUser } from "../actions/selectors";
import { useAppSelector } from "../hooks/redux"
import { useNavigate } from "react-router-dom";
import { getWeek } from "../getWeek";

export default function CompanyInfo(){

    const user = useAppSelector(selectUser)
    const navigate = useNavigate()
    let imgUrl = "heibaLogo.png";

    if(user.directory !== ""){
      imgUrl = "https://heibaservice.tv/users/"  + user.directory + "/logo.png"
    }
    
    const style = {
      backgroundImage: "url(" + imgUrl + ")"
    };
    //@ts-ignore
    const weeknumber = getWeek(new Date())

    return (
      <div className="companyInfo">
        <div style={style} className="companyInfoLogoHolder">
          {/* <img src="empty.png" alt="customerLogo" /> */}
          {/* not needed? */}
        </div>
        <div className="companyInfoData">
          <div className="welcomeBox">
            <p>
              {" "}
              <em className="welcome">
                Welkom <br />
              </em>
              <em className="username">
                {user.name}, <br />
              </em>
              <em className="companyName">
                van <em className="company">{user.company}</em>
              </em>
            </p>
          </div>
        </div>
        <div className="currentWeek">
          <div className="weekNumber">
            <p onClick={e => navigate("/input/next")} style={{cursor: 'pointer'}}>
              Huidige week nmr <em className="week">{weeknumber}</em>
            </p>
          </div>
        </div>
      </div>
    );
  
}



