import React from 'react'
import { Icon } from 'semantic-ui-react'


export default function InputFlow():JSX.Element {

    return (
        <div>
            <h2>Het aanlever proces</h2>
            <p>Een video kan zich in verschillende stadia bevinden</p>
            <ol>
                <li><div className="inputFlow"> <Icon color='blue' name="edit outline"/> <p>Klant vult gegevens in</p> </div></li>
                <li><div className="inputFlow"> <Icon color='blue' name="envelope outline" /><p>Nieuw</p> </div></li>
                <li><div className="inputFlow"> <Icon color='blue' name="file video outline"/><p>Wordt gemaakt</p> </div></li>
                <li><div className="inputFlow"> <Icon color='blue' name="wait"/> <p>Wacht op acceptatie</p> </div></li>
                <li><div className="inputFlow"> <Icon color='blue' name="redo alternate"/> <p>Aanpassing maken</p> </div></li>
                <li><div className="inputFlow"> <Icon color='blue' name="check"/> <p>Klaar(accepted) </p> </div></li>
            </ol>
        </div>
    )
}
