import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { getPlayers, getAllPlayers, getNewPlayers } from "../../actions/videoInput";
import {selectAllPlayers } from '../../actions/selectors'
import { Dropdown } from "semantic-ui-react";
import { player } from "../../types/player";

interface PlayerSelectProps{
  player?:player
  customerId: number | undefined
  complete: boolean
  style?: any
  set_player(n:string | undefined): void
  selectedPlayer: string | undefined
}

export default function PlayerSelect(props:PlayerSelectProps) {
  const players_list = useAppSelector(selectAllPlayers);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!props.player) {
      if (typeof props.customerId === "number") {
        dispatch(getPlayers(props.customerId));
      } else {
        dispatch(getAllPlayers());
        dispatch(getNewPlayers());
      }
    }
  }, [props.customerId, dispatch, props.player]);
  const options = players_list.map((player) => {
    return {
      key: player.id,
      value: player["MAC adres"],
      text: `${player["MAC adres"]}`,
    };
  });
  if (props.complete) return null;
  return (
    <div style={props.style}>
      <Dropdown
        placeholder="Selecteer een player"
        fluid
        search
        selection
        //@ts-ignore
        onChange={(e, f) => props.set_player(f.value)}
        //@ts-ignore
        value={props.selectedPlayer ? props.selectedPlayer : null}
        options={options}
      />
    </div>
  );
}
