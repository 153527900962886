import React, { useEffect, Fragment } from "react";
import CompanyInfo from "../components/CompanyInfo";
import VideoPlayer from "../components/VideoPlayer";
import Comments from "../components/Comments";
import Download from "../components/Download";
//import Overlay from "./Overlay";
import TopicLine from "../components/TopicLine";
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { getPreviews } from "../actions/videoInput";
import { useNavigate } from "react-router-dom";
import { previewLocation } from "../constants";
import { selectPreview } from "../actions/selectors";
import LoadingIndicator from "../components/LoadingIndicator";

export default function Preview() {
  const preview = useAppSelector(selectPreview);
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPreviews());
  }, [dispatch]);


  if(preview.videos === null){
    return <LoadingIndicator loadingText="Preview video's laden"/>
  } 

  return (
    <div>
      <CompanyInfo />
      <TopicLine />
      
      {preview.videos.length === 0 ? (
        <Fragment>
          <h2>Er staan helaas nog geen video's voor u klaar</h2>{" "}
          <h3>Lever snel aan zodat wij een video voor u kunnen maken.</h3>
        </Fragment>
      ) : null}
      {preview.videos.map((video) => {
        return (
          <div className="videoToAccept" key={video.id}>
            <h1 className="videoHeading">
              {" "}
              PREVIEW VAN WEEK NUMMER <em>{video.week}</em>{" "}
            </h1>
            <div className="previewComments">
              <VideoPlayer
                styleName="previewVideoPlayer"
                noAutoPlay={true}
                toPlay={previewLocation + video.filename}
              />
              <Comments data={video} />
              {/* <TopicLine /> */}
              <Download video={video} />
            </div>
            <TopicLine white/>
          </div>
        );
      })}
      {/*    
                <Overlay show={false}/> */}
      {/* this overlay is for: also send info for the next week */}

      <div className="previewNewInputHolder">
        <p className="previewNewInput">Lever nu alvast <em onClick={e => navigate("/input/next")} style={{cursor: 'pointer'}}>input</em> aan voor meer weken!</p>

      </div>
    </div>
  );
}
