import React from 'react'
import { officePhoneNumber, officePhoneNumberLink } from '../constants'


export default function Footer():JSX.Element {
    const year = new Date().getFullYear()
    return (
        <div className="footer">
          <div className="footerLeft">
              <p>Copyright ©{year} Heiba Service | <a href={officePhoneNumberLink}>{officePhoneNumber} </a>
                <a href="https://www.heibaservice.nl/disclaimer/"> {"  disclaimer"}</a> | 
                <a href="https://www.heibaservice.nl/faq/"> {"faq"} </a> |
                <a href="https://www.heibaservice.nl/algemenevoorwaarden/"> algemene voorwaarden </a>  |
                <a href="https://www.heibaservice.nl/avg/"> avg </a> </p>
          </div>
          
          <div className="socialIcons">
            <div onClick={()=> window.open("https://www.facebook.com/heibaservice/", "_blank")} className="facebook social" style={{cursor: 'pointer'}}></div>
            <div onClick={()=> window.open("https://www.instagram.com/heibaservice", "_blank")} className="instagram social" style={{cursor: 'pointer'}}></div>
            <div onClick={()=> window.open("https://nl.linkedin.com/company/heiba-service", "_blank")} className="linkedin social" style={{cursor: 'pointer'}}></div>
          </div>
           
        </div>
    )
}
