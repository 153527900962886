
import React, { MouseEventHandler } from "react";
import { Icon } from "semantic-ui-react";

interface OverlayImageProps{
  showLarge: Function,
  src: string,
  prevPhoto?: MouseEventHandler
  nextPhoto?: MouseEventHandler
}

export default function OverlayImage(props:OverlayImageProps):JSX.Element {


    const exit = () => {
        props.showLarge(false)
    }

    return (
    <div className="overlayImage" >
      <div className="boxImage" >
          <img src={props.src} onClick={exit}  alt="" style={{cursor: 'pointer'}}/>
          <div style={{ display: "flex", justifyContent: "space-between" ,marginTop: '1em' }}>
          { props.nextPhoto ? 
            <p onClick={props.prevPhoto} style={{cursor: 'pointer'}}>
              <Icon name="angle left" />
              Vorige
            </p>
             : null
            }
            { props.nextPhoto ? 
              <p onClick={props.nextPhoto} style={{cursor: 'pointer'}}>
              Volgende
              <Icon name="angle right" />
              </p> 
            : null
            }
          </div>
         
      </div>
    </div>
  );
}
