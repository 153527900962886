import React, {  useState } from "react";
import PreviewButtons from "./PreviewButtons";
import {previewComments} from '../actions/videoInput'
import { previewVideo } from "../types/previewVideo";
import { useAppDispatch } from "../hooks/redux";


interface CommentsProps{
  data: previewVideo
}

export default function  Comments(props:CommentsProps):JSX.Element {
  const [comment, set_comment] = useState(props.data.comments)
  const dispatch = useAppDispatch()
    const handleSubmit = (videoId:number ,accepted:string, rework:string) => {
      dispatch(previewComments(videoId,accepted, rework, comment))
    }
      
    if (props.data.rework) {
      return (
        <div className="comments">
          <p className="">
            We gaan deze video aanpassen naar aaleiding van de verbeterpunten:
          </p>
          <p> <br/>{props.data.comments}</p>
        </div>
      );
    }
    if (props.data.accepted) {
      return (
        <div className="comments">
          <p className="">
            Video is reeds geaccodeerd, beoordelen is niet meer mogelijk.
          </p>
        </div>
      );
    }
    return (
      <div className="comments">
        <h2 className="headerComments">Opmerkingen</h2>
        <p className="">
          Geef hier aan wat je eventueel nog zou willen aanpassen, klik op
          akkoord als deze goed is.
        </p>
        <textarea name="comment" value={comment} onChange={e => set_comment(e.target.value)}/>
        <PreviewButtons disabled={comment.length} data={props.data} handleSubmit={handleSubmit}  />
      </div>
    );
  
}
