import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import { Icon, Table } from "semantic-ui-react";
import dateParser from "../../dateParser";
import { playerSsmInfo } from "../../types/player";
import statusGreen from "./images/statusGreen.png"
import statusRed from './images/statusRed.png'

interface PlayerDetailsSsmInfoProps{
  data: playerSsmInfo| undefined
}

export default function PlayerDetailsSsmInfo({data}:PlayerDetailsSsmInfoProps) {
  const navigate = useNavigate()
  if (!data) return null;

  const generalCell = (header:string, info:string|JSX.Element|number, key?:number, link?:string) => {
    return (
      <Table.Row key={key||header}>
        <Table.Cell style={{ padding: ".28571429em" }}>{header}</Table.Cell>
        { !link ? 
          <Table.Cell style={{ padding: ".28571429em" }}>{info}</Table.Cell> :
          <Table.Cell style={{ padding: ".28571429em" }} onClick={()=> navigate(link)}>{info}</Table.Cell> }
        { link ?  <Table.Cell style={{ padding: ".28571429em" }} onClick={()=> navigate(link)} > <div style={{ padding: ".28571429em", display: "flex", justifyContent: "center" }} onClick={()=> navigate(link)}> <Icon name='film'   /></div> </Table.Cell> :  <Table.Cell style={{ padding: ".28571429em" }}></Table.Cell>  }
      </Table.Row>
    );
  };

  return (
    <div >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Item</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: ".28571429em" }}>Info</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
        {data?.AgentVersion
            ? generalCell("AgentVersion", data?.AgentVersion)
            : null}
        {data?.ComputerName
            ? generalCell("ComputerName", data?.ComputerName)
            : null}
        {data?.IPAddress
            ? generalCell("Ip", data?.IPAddress)
            : null}
        {data?.InstanceId
            ? generalCell("InstanceId", data?.InstanceId)
            : null}
        {data?.IsLatestVersion
            ? generalCell("IsLatestVersion", data?.IsLatestVersion)
            : null}
        {data?.LastPingDateTime
            ? generalCell("LastPingDateTime", dateParser(data?.LastPingDateTime))
            : null}
        {data?.Name
            ? generalCell("Computer naam", data?.Name)
            : null}
        {data?.PingStatus
            ? generalCell("PingStatus Icon",  data?.PingStatus === "ConnectionLost" ?  
              <React.Fragment><img src={statusRed} alt={""}/> {data?.PingStatus}</React.Fragment>:
              <React.Fragment><img src={statusGreen} alt={""}/> {data?.PingStatus}</React.Fragment>)
            : null}
        {data?.RegistrationDate
            ? generalCell("RegistrationDate", dateParser(data?.RegistrationDate)) //.toString())
            : null}
            
        </Table.Body>
      </Table>
    </div>
  );
}
