import React, { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { InputStats } from "../../actions/videoInput";
import  LoadingIndicator from "../LoadingIndicator";
import { selectVideoInputStats } from "../../actions/selectors";


export default function VideoInputStats() {
  const dispatch = useAppDispatch();
  const videoInputStats = useAppSelector(selectVideoInputStats);
  const videoInputStatsSorted = videoInputStats
    ? [...videoInputStats]
    : videoInputStats;

  useEffect(() => {
    dispatch(InputStats());
  }, [dispatch]);

  if (!videoInputStats) return <LoadingIndicator loadingText="Stats laden"/>
  if (videoInputStats.length <= 1) return <p>Er is geen werk te doen</p>;

  const date = new Date();
  const yearString = date.getFullYear().toString();
  const nextYearString = (date.getFullYear() + 1).toString();

  //@ts-ignore
  const thisYear = videoInputStatsSorted
    .filter((w) => w.year === yearString, [])
    //@ts-ignore TS TODO, it works, convert to number
    .sort((a, b) => b.week - a.week);
    //@ts-ignore
  const nextYear = videoInputStatsSorted
    .filter((w) => w.year === nextYearString, [])
    //@ts-ignore TS TODO, it works, convert to number
    .sort((a, b) => b.week - a.week);

  const stats = [...nextYear, ...thisYear];
  stats.length = 15;

  return (
    <div style={{ width: "400px" }}>
      <button className="adminButton" onClick={(e) => dispatch(InputStats())} style={{cursor: 'pointer'}}>
        (Re)Load data
      </button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Week</Table.HeaderCell>
            <Table.HeaderCell>
              Aantal aanleveringen nog te maken
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {stats.map((week, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell style={{ textAlign: "center" }}>
                  {" "}
                  {`${week.week}(${week.year})`}
                </Table.Cell>
                <Table.Cell style={{ textAlign: "center" }}>
                  {" "}
                  {`${week.count}`}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
