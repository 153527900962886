import React, { useState } from 'react'
import { selectUser } from '../actions/selectors'
import OverlayPassword from '../components/OverlayPassword'
import { useAppSelector } from '../hooks/redux'

export default function ProfilePage() {
    const user = useAppSelector(selectUser)
    const [passwordResetPopup , setPasswordResetPopup] = useState<Boolean>(false)
    const togglePassword = () => {
        setPasswordResetPopup(!passwordResetPopup)
    }
    let imgUrl = "heibaLogo.png";

    if(user.directory !== ""){
      imgUrl = "https://heibaservice.tv/users/"  + user.directory + "/logo.png"
    }
    
    const style = {
      backgroundImage: "url(" + imgUrl + ")"
    };


    return (
        <div>
            <div className='profileTop'>
                <span className='profilePicture' style={style}></span>
                <h1>{user?.name}</h1>
                <p>{user.company}</p>
            </div>
            <hr/>
            <div className='profileBody'>
            <p>Password: <b onClick={togglePassword}>change</b></p> 
            <p>Category {user?.category}</p>
            <p>Id {user?.id}</p>
            <p>livedag {user?.liveday}</p>
            <p>role {user?.role}</p>
            <p>map {user?.directory}</p>
            </div>
            <OverlayPassword show={passwordResetPopup} hide={setPasswordResetPopup}/>
        </div>
    )
}
