import React from "react";

interface StepListProps{
  set_step(n: number): void
}

export default function StepList(props:StepListProps) {
  return (
    <div>
      <select id="step" onChange={(e) => props.set_step(parseInt(e.target.value))}>
        <option value={1}>1. FocusProduct</option>
        <option value={2}>2. Seizoen en streek</option>
        <option value={3}>3. Authenticiteit en sfeer</option>
        <option value={4}>4. Extra info</option>
        <option value={5}>5. Facebook</option>
      </select>
    </div>
  );
}
