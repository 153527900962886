import React, {  useState } from "react";
import VideoPlayer from "./VideoPlayer";
import VideoTitle from "./VideoTitle";
import { SelectAWeek,SelectAWeekQuickSave } from "../actions/videoInput";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export default function SeasonFeed():JSX.Element {
  const constructionVideos = useAppSelector(state => state.constructionvideo);
  const videoInput = useAppSelector(state => state.videoInput);
  const [active, set_active] = useState(0);
  const [currentSrc, set_currentSrc] = useState("");
  const dispatch = useAppDispatch();

  const clickHandlerTitle = ( videoId: number) => {
    set_active(videoId);
    let filter = constructionVideos.filter( (video) => video.constructionVideo.id === videoId);
    
    if(filter.length === 0) return  null 
    set_currentSrc(filter[0].constructionVideo.link);
    dispatch(
      //@ts-ignore
      SelectAWeek(
        videoInput.weekNumber,
        videoInput.year,
        null,
        null,
        null,
        null,
        null,
        videoId
      )
    );
  };

  const onChangeHandler:React.ChangeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) =>{
    dispatch({ type: "SEASON", payload: e.target.value });
  }
 const  onKeyDownHandler:React.KeyboardEventHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if(e.key === "Enter"){
        //@ts-ignore
        dispatch( SelectAWeekQuickSave(videoInput.weekNumber, videoInput.year, null, e.target.value))
      }
    }

  const onBlurHandler: React.FocusEventHandler = () => {
    //@ts-ignore
    dispatch( SelectAWeek(videoInput.weekNumber, videoInput.year, null, videoInput.season))
  }  

  return (
    <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="card-box">
                            <h4 className="black-text">Seizoen en streek product</h4>
                            <p className="black-text">Producten die goed passen bij het seizoen en/of waar uw zaak om bekend staat in uw 
                                directe regio.</p>
                            <p className="black-text">Door weersomstandigheden kunnen producteneerder of later verkrijbaar zijn, 
                                stel uw klanten hiervan op de hoogte.</p>
                            <div className="textarea-box box-color-1">
                                <div className="textarea-box-img">
                                    <img src="img/season-img-1.png" alt=""/>
                                </div>
                                <div className="text-area textarea-center">
                                    <textarea 
                                                className="bg-lightgold" 
                                                id="" 
                                                cols={30} 
                                                rows={10} 
                                                placeholder="Vul hier het seizoen en streek artikel in:"
                                                name="comment"
                                                onChange={onChangeHandler} 
                                                onKeyDown={onKeyDownHandler} 
                                                value={videoInput.season} 
                                                onBlur={onBlurHandler}
                                                ></textarea>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-6">
                        <div className="card-box card-box-m-white">
                            <h4 className="black-text">Productievideo</h4>
                            <p className="black-text">Een bijpasssende productievideo ondersteunt daarbij uw boodschap. Kies één van de video`s, 
                                video 1 is geen video, bij die keuze wordt er geen video gebruikt.</p>
                            <div className="video-box video-color-1">
                                 <VideoPlayer
                                    styleName="videoPlayer"
                                    toPlay={currentSrc}
                                  />
                                <div className="video-list">
                                {constructionVideos.map((videoinfo, i) => {
                                  return (
                                    <VideoTitle
                                      video={videoinfo.constructionVideo}
                                      click={clickHandlerTitle}
                                      key={i}
                                      active={videoInput.cm01}
                                      className="border-0 video-list-item"
                                    />
                                  );
                                })}
                                    <p className="video-list-selected">U heeft Productie video {active} geselecteerd.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  );
}
