import React, { useEffect } from 'react'
import { Table } from 'semantic-ui-react';
import { selectTasks } from '../../actions/selectors';
import {  getTaskList} from '../../actions/videoInput';
import { taskType } from '../../types/tasks';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

export default function TaskList() {
    const webshopTemplateHeader   = ["id",
    "memberId",
    "completed",
    "failed",
    "taskType",
    "task"]
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getTaskList());
      }, [dispatch]);

  const tasks:taskType[] = useAppSelector(selectTasks) 
  return (
    <div>TaskList

      <Table celled>
        <Table.Header>
          <Table.Row>
            {webshopTemplateHeader?.map((header,i) => <Table.HeaderCell key={i}>{header}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tasks?.map((t, i) => {
            console.log("websh", t)
            return (
              <Table.Row key={i}>
                <Table.Cell>{t?.id}</Table.Cell>
                <Table.Cell>{t?.memberId}</Table.Cell>
                <Table.Cell>{t?.completed? "yes": null}</Table.Cell>
                <Table.Cell>{t?.failed ? "yes" : null}</Table.Cell>
                <Table.Cell>{t?.taskType}</Table.Cell>
                <Table.Cell>{t.task}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

