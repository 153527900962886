import React, { useState } from "react";
import VideoPlacement from "../../components/admin/VideoPlacement";
import CustomerTypesList from "../../components/admin/CustomerTypesList";
import InputPhotoSuggestion from "../../components/InputPhotoSuggestion";
import StepList from "../../components/admin/StepList";
import PlacePhoto from "../../components/admin/PlacePhoto";
import { LibaryMediaType } from "../../types/constructionMedia";
import { getWeek } from "../../getWeek";

export default function Admin() {
  const [type, set_type] = useState<LibaryMediaType>("vlees");
  const [step, set_step] = useState(1)  
  const [week, set_week] = useState( getWeek(new Date()));
  const [year, set_year] = useState(new Date().getFullYear());
  
  return (
    <div>
      <CustomerTypesList type={type} set_type={set_type} />
      <hr></hr>
      <VideoPlacement type={type} week={week} year={year} set_week={set_week} set_year={set_year}/>
      <InputPhotoSuggestion step={step} admin={true} week={week} year={year} type={type} />
      <StepList set_step={set_step} />
      <PlacePhoto
        complete={false} //todo here? was boolcomplete see VideoPlacement
        week={week}
        year={year}
        type={"image"}
        step={step}
        category={type}
      />
    </div>
  );
}
