import { liveday } from "./types/user";

export default function getDate(week: number, year: number, schedule: liveday) {
  let liveCorrection = 0; //monday
  switch (schedule) {
    case "Monday":
      liveCorrection = 0;
      break;
    case "Tuesday":
      liveCorrection = 1;
      break;
    case "Wednesday":
      liveCorrection = 2;
      break;
    case "Thursday":
      liveCorrection = 3;
      break;
    case "Friday":
      liveCorrection = 4;
      break;
    case "Saturday":
      liveCorrection = 5;
      break;
    case "Sunday":
      liveCorrection = 6;
      break;
    default:
      liveCorrection = 0;
  }
  return formatDate(weekToIsoDate(week, year, liveCorrection));
}

function weekToIsoDate(w: number, y: number, schedule: number) {
  var simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1 + schedule);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay() + schedule);
  return ISOweekStart;
}

function formatDate(date: Date) {
  const formatted_date =
    date.getFullYear() +
    "-" +
    appendLeadingZeroes(date.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(date.getDate());
  return formatted_date;
}

export function appendLeadingZeroes(n: number) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}
