import React from 'react'


interface PlayersTypeListProps{
  type: "active" | "inactive"
  set_type(a: "active" | "inactive"): void 
}

export default function PlayersTypeList(props:PlayersTypeListProps) {
    return (
        <div className="typeOfVideo">
        
        <form>
          <input
            type="radio"
            id="active"
            name="typeOfVideo"
            checked={props.type === "active"}
            onChange={()=> props.set_type("active")}
          />
          <label htmlFor="active">Active klaten</label>
          <input
            type="radio"
            id="inactive"
            name="typeOfVideo"
            checked={props.type === "inactive"}
            onChange={()=> props.set_type("inactive")}
          />
          <label htmlFor="kaas">Inactive klanten </label>
        </form>
      </div>
    )
}
