import React from "react";

interface SendButtonProps{
  fieldOne: number
  fieldTwo: number
}

export default function SendButton(props:SendButtonProps):JSX.Element {

  if (props.fieldOne >= 1 && props.fieldTwo >= 1) {
    return (
      <div className="sendbuttons">
        <button className="buttonSend"> Verstuur</button>
      </div>
    );
  } else {
    return (
      <div className="sendbuttons">
        <button className="buttonSendBlocked"> Verstuur</button>
      </div>
    );
  }
}
